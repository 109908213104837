import { getCurrencySymbol, tr } from 'mmfintech-commons'
import './ApplicationFeeInfoModal.scss'
import { CoreButton } from '@components'

interface ApplicationFeeInfoProps {
  amount: number
  currency: string
  onClose: () => void
  onSubmit: () => void
}

const ApplicationFeeInfoModal = ({ amount, currency, onClose, onSubmit }: ApplicationFeeInfoProps) => {
  return (
    <div className='onboarding-application-fee-info-modal'>
      <div className='onboarding-application-amount'>
        <div className='onboarding-application-amount-fee'>
          <span className='subtitle-label'>{tr('FRONTEND.ONBOARDING.APPLICATION_FEE_LABEL', 'Application fee')}</span>
          <span className='subtitle-content'>
            {getCurrencySymbol(currency)} {amount}
          </span>
        </div>
        <div className='onboarding-application-amount-total'>
          <span>{tr('FRONTEND.ONBOARDING.APPLICATION_FEE_TOTAL_LABEL', 'Total')}</span>
          <span>
            {getCurrencySymbol(currency)} {amount}
          </span>
        </div>
      </div>
      <div className='onboarding-application-divider' />
      <div className='buttons-container'>
        <CoreButton
          variation='primary'
          size='large'
          fullWidth
          text={tr('FRONTEND.APPLICATION_FEE.INFO.PAY.BUTTON', 'Pay')}
          data-test='button-pay'
          onClick={onSubmit}
        />
        <CoreButton
          variation='tertiary'
          size='large'
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          data-test='button-cancel'
          onClick={onClose}
        />
      </div>
    </div>
  )
}

export default ApplicationFeeInfoModal
