import { useHistory } from 'react-router-dom'

import { paths, useGetReportQuery } from 'mmfintech-backend-api'

import { AddEditExpenseButton } from '../../report/reportHeaders/AddEditExpenseButton'
import { ReportHeaderButtons, ReportTitle } from '../'

import LeftArrowIcon from '@images/icons/left-arrow-icon.svg?react'

import './reportHeader.scss'

export const ReportHeader = ({ param }) => {
  const { data } = useGetReportQuery({ id: Number(param) })
  const history = useHistory()

  if (!data) return null

  return (
    <div className='report-header-container'>
      <div className='left-wrapper'>
        <div
          onClick={e => {
            e.preventDefault()
            history.push(paths.expenseManagement.expenseManagementDashboard())
          }}
          className='back-arrow-icon'>
          <LeftArrowIcon />
        </div>
        <ReportTitle report={data} />
      </div>
      <div className='right-wrapper'>
        <AddEditExpenseButton reportId={param} />
        <ReportHeaderButtons reportId={param} />
      </div>
    </div>
  )
}
