import { useEffect } from 'react'

import { ErrorObject, paths, useGetTransactionsQuery, useTransactionColumns } from 'mmfintech-backend-api'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { tr } from 'mmfintech-commons'

import TransactionsTable from '@views/new_transactions/elements/TransactionsTable'
import { TransactionViewPortal } from 'mmfintech-commons-types'
import { CoreLoader, ViewAllButton } from '@components'

import './transactionsSection.scss'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'

type TTransactionsSectionProps = {
  title?: string
  transactionsData?: TransactionViewPortal[]
  transactionsDataError?: ErrorObject | FetchBaseQueryError | SerializedError
  transactionsDataFetching?: boolean
  recipientId?: number
}

export const TransactionsSection = ({
  title = '',
  transactionsData,
  transactionsDataError,
  transactionsDataFetching,
  recipientId
}: TTransactionsSectionProps) => {
  const columns = useTransactionColumns({
    cookieDomain: null,
    enableCustomerEmail: false,
    enableFailReason: true
  })

  useEffect(() => {
    if (columns) {
      columns.setVisible(['id', 'type', 'description', 'status'])
    }
  }, [])

  const {
    data: transactions,
    error: transactionsError,
    isFetching: transactionsFetching
  } = useGetTransactionsQuery({ size: 3 }, { skip: !!transactionsData })

  return (
    <div className='transactions-section-wrapper'>
      <div className='transactions-section-header'>
        <h2 className='transactions-section-title'>
          {title || tr('FRONTEND.DASHBOARD.TRANSACTIONS_TITLE', 'Recent transactions')}
        </h2>
        <ViewAllButton
          label={tr('FRONTEND.DASHBOARD.TRANSACTIONS.BUTTON.VIEW_ALL', 'View all')}
          redirect={paths.banking.transactions.list()}
          recipientId={recipientId}
        />
      </div>
      <ErrorDisplay error={[transactionsError, transactionsDataError]} />
      {transactionsFetching || transactionsDataFetching ? (
        <CoreLoader />
      ) : (
        <TransactionsTable transactions={transactions?.content || transactionsData || []} columns={columns} />
      )}
    </div>
  )
}
