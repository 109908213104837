import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useAppDispatch, useHasWritePermissions, useMatchMedia } from '@hooks'
import cn from 'classnames'

import {
  api,
  paths,
  selectCurrentUserRole,
  useAppSelector,
  useAuth,
  useGetLogoQuery,
  useGetMerchantQuery,
  useGetMerchantsForSwitchQuery,
  useSwitchMerchantMutation
} from 'mmfintech-backend-api'
import { GlobalContext, isValidArray, tr, useDropDownMenu } from 'mmfintech-commons'
import { CustomerRoleEnum, LoginStatusEnum } from 'mmfintech-commons-types'

import CrossIcon from '@images/icons/cross-icon.svg?react'
import ArrowIcon from '@images/icons/arrow-icon.svg?react'
import BatchPaymentIcon from '@images/icons/batch-payment-icon.svg?react'
import QRCodeIcon from '@images/icons/qr-code-icon.svg?react'
import ExchangeIcon from '@images/icons/exchange-icon.svg?react'
import BellIcon from '@images/icons/bell-icon.svg?react'
import LeftArrowIcon from '@images/icons/left-arrow-icon.svg?react'
import OnboardingArrow from '@images/icons/moving-onboarding-arrow-icon.svg?react'
import KingdomBankLogo from '@images/kingdom-bank-icon-black.svg?react'
import ChevronIcon from '@images/icons/down-arrow-icon.svg?react'
import CheckIcon from '@images/icons/check-icon.svg?react'
import SearchIcon from '@images/icons/search-icon.svg?react'
import WalletIcon from '@images/icons/wallet-icon.svg?react'

import './header.scss'
import { HamburgerMenu, ActivitiesMenu, CoreButton, CoreProfileAvatar, CoreInput } from '@components'
import { HeaderContext } from '../../context/HeaderContext'
import { breakpoints } from '@constants'
import { InvoiceActions } from '@views/new_invoices/InvoiceActions'
import { OnboardingBanner } from '@views/new_onboarding/OnboardingBanner'
import { ReportsViewHeader } from '@views/reports/components'
import ReactTooltip from 'react-tooltip'
import { useDebounce } from '@utils'
import { AddAccountModal } from '@views/new_accounts/AddAccountModal/AddAccountModal'
import { ReportHeader } from '@views/reports/report/reportHeaders'
import { CounterpartyViewHeader } from '@views/counterparty/elements/CounterpartyViewHeader/CounterpartyViewHeader'
import { RecipientDetailsHeader } from '@views/new_recipients/RecipientDetailsPage/RecipientDetailsHeader'
import { CounterpartyPageHeader } from '@views/counterparty/elements/CounterpartyPageHeader'

const NAVIGATION_ROW_HEIGHT = 56 + 10 // 10 px for every row gap

const getButtons = isOwnerOrAdministrator => {
  switch (isOwnerOrAdministrator) {
    case true as boolean:
      return [
        {
          path: paths.banking.deposit(),
          label: tr('FRONTEND.DASHBOARD.BUTTONS.DEPOSIT', 'Deposit'),
          icon: <CrossIcon />
        },
        { path: paths.banking.send(), label: tr('FRONTEND.DASHBOARD.BUTTON_SEND', 'Send'), icon: <ArrowIcon /> },
        {
          path: paths.banking.exchange(),
          label: tr('FRONTEND.DASHBOARD.BUTTONS.EXCHANGE', 'Exchange'),
          icon: <ExchangeIcon />
        },
        {
          path: paths.scanAndPay(),
          label: tr('FRONTEND.DASHBOARD.BUTTONS.SCAN_AND_PAY', 'Scan & Pay'),
          icon: <QRCodeIcon />
        },
        {
          path: paths.banking.sendBatchPayment(),
          label: tr('FRONTEND.DASHBOARD.BUTTONS.BATCH_PAYMENT', 'Batch Payment'),
          icon: <BatchPaymentIcon />
        },
        {
          func: modalShow => {
            modalShow({
              content: <AddAccountModal />,
              header: tr('FRONTEND.ACCOUNTS.CREATE.TITLE', 'Add New Account'),
              options: { closeOnClickOutside: true }
            })
          },
          label: tr('FRONTEND.DASHBOARD.BUTTONS.NEW_ACCOUNT', 'New Account'),
          icon: <WalletIcon />
        }
      ]

    default:
      return [
        {
          path: paths.banking.deposit(),
          label: tr('FRONTEND.DASHBOARD.BUTTONS.DEPOSIT', 'Deposit'),
          icon: <CrossIcon />
        },
        { path: paths.banking.send(), label: tr('FRONTEND.DASHBOARD.BUTTON_SEND', 'Send'), icon: <ArrowIcon /> },
        {
          path: paths.scanAndPay(),
          label: tr('FRONTEND.DASHBOARD.BUTTONS.SCAN_AND_PAY', 'Scan & Pay'),
          icon: <QRCodeIcon />
        }
      ]
  }
}

const pathsToHidePaymentButtons = param => [
  //TO BE ADDED
  paths.settings(),
  paths.banking.deposit(),
  paths.banking.send(),
  paths.banking.sendPix(),
  paths.banking.sendInterac(),
  paths.banking.sendPerfectMoney(),
  paths.banking.sendTransfer(),
  paths.banking.sendBankTransfer(),
  paths.banking.sendDigitalWallet(),
  paths.banking.sendIntraAccountTransfer(),
  paths.banking.sendTo(':recipientId'),
  paths.banking.sendCrypto(),
  paths.banking.sendBatchPayment(),
  paths.expenseManagement.expenseManagementDashboard(),
  paths.expenseManagement.reports(),
  paths.expenseManagement.counterParty(),
  paths.expenseManagement.singleCounterparty(param),
  paths.expenseManagement.report(param),
  paths.expenseManagement.payments(param),
  paths.invoices.create(),
  paths.invoices.upload(),
  paths.invoices.uploadEdit(param),
  paths.invoices.add(),
  paths.invoices.edit(param),
  paths.invoices.send(param),

  paths.banking.exchange(),

  paths.card(),
  paths.onboarding.questionnaireKyb(),

  paths.invoicesCustomersWrapper() + paths.invoices.list(),
  paths.invoicesCustomersWrapper() + paths.customers(),
  paths.userManagement(),
  paths.developer(),
  paths.ibanIssuing(),
  paths.ibanIssuingPreview(),
  paths.scanAndPay(),
  paths.p2pQrCode(),
  paths.forex()
]

const pathsToShowOnlyMobilePaymentButtons = [
  paths.invoicesCustomersWrapper() + paths.invoices.list(),
  paths.invoicesCustomersWrapper() + paths.customers()
]

const pathsToShowBottomBorder = param => [
  paths.ibanIssuing(),
  paths.ibanIssuingPreview(),
  paths.scanAndPay(),
  paths.p2pQrCode(),
  paths.banking.deposit(),
  paths.banking.send(),
  paths.banking.sendPix(),
  paths.banking.sendInterac(),
  paths.banking.sendPerfectMoney(),
  paths.banking.sendTransfer(),
  paths.banking.sendBankTransfer(),
  paths.banking.sendDigitalWallet(),
  paths.banking.sendIntraAccountTransfer(),
  paths.banking.sendTo(':recipientId'),
  paths.banking.sendCrypto(),
  paths.banking.sendBatchPayment(),
  paths.banking.exchange(),
  paths.invoices.create(),
  paths.invoices.upload(),
  paths.invoices.uploadEdit(param),
  paths.invoices.add(),
  paths.invoices.send(param),
  paths.invoices.edit(param)
]

const pathsToIncreaseHeight = [
  //TO BE ADDED
  paths.onboarding.questionnaireKyb()
]

export const Header = () => {
  const { sidebarRightShow } = useContext(GlobalContext)
  const { currentStep, totalSteps } = useContext(HeaderContext)
  const { data: merchant } = useGetMerchantQuery()
  const { data: merchantLogo } = useGetLogoQuery()
  const { data: subAccounts }: any = useGetMerchantsForSwitchQuery(null)
  const [switchMerchant, { isLoading: switchMerchantLoading }] = useSwitchMerchantMutation()
  const customerRole = useAppSelector(selectCurrentUserRole)
  const dispatch = useAppDispatch()
  const [filteredAccounts, setFilteredAccounts] = useState<any>(null)
  const [terms, setSearchTerm] = useState<string>('')
  const {
    visible: subAccountVisible,
    toggleDropDown,
    setRef: setSubAccountRef
  } = useDropDownMenu({ useOptionalRef: true })
  const userStatus = useAuth()
  const location = useLocation()
  const history = useHistory()

  const [opened, setOpened] = useState<boolean>(false)
  const isLarge = useMatchMedia({ breakpoint: breakpoints.xl, prefix: 'max' })
  const [visible, setVisible] = useState(false)
  const hasAnyPermissions = useHasWritePermissions()

  const { name: merchantName, merchantId } = merchant || {}

  const isLoggedIn = (): boolean => userStatus === LoginStatusEnum.LOGGED_IN
  const hideMenu = (): void => opened && setOpened(false)
  const parts = location?.pathname?.split('/') // Split the string by '/'
  const param = parts[parts?.length - 1] || '' // Get the last part
  const isSubmitterOrApprover =
    customerRole === CustomerRoleEnum.SUBMITTER || customerRole === CustomerRoleEnum.APPROVER
  const shouldHideButtons = (): boolean =>
    pathsToHidePaymentButtons(param).includes(location.pathname) || isSubmitterOrApprover
  const shouldShowMobileButtons = (): boolean =>
    pathsToShowOnlyMobilePaymentButtons.includes(location.pathname) && !isSubmitterOrApprover
  const shouldIncreaseHeight = (): boolean => pathsToIncreaseHeight.includes(location.pathname)
  const isDashboard = (): boolean => location.pathname === paths.dashboard()
  const shouldShowBottomBorder = (): boolean => pathsToShowBottomBorder(param).includes(location.pathname)

  useEffect(() => {
    const pathname = location.pathname
    // noinspection DuplicatedCode
    const state =
      pathname !== paths.login() &&
      pathname !== paths.checkEmail() &&
      pathname !== paths.passwordReset() &&
      pathname !== paths.forgotPassword() &&
      pathname !== paths.changePasswordEmail() &&
      pathname !== paths.handlePasswordReset() &&
      pathname !== paths.handlePasswordResetInvited() &&
      !paths.isAdsPath(pathname) &&
      !paths.isExpoPath(pathname) &&
      pathname.substring(0, 7) !== '/signup' &&
      pathname.substring(0, 15) !== '/transfer-funds' &&
      pathname.substring(0, 9) !== '/invoice/'

    setVisible(state)
    // eslint-disable-next-line
  }, [location])

  useEffect(() => {
    if (!isLarge) hideMenu()
  }, [isLarge])

  const handleOpenSidebarRight = (): void => {
    sidebarRightShow({
      header: tr('FRONTEND.ACTIVITIES.TITLE', 'Activity'),
      content: <ActivitiesMenu />,
      options: { size: 'extra-large', closeOnClickOutside: true, type: 'rounded' }
    })
  }

  useEffect(() => {
    const body = document.body
    if (body) {
      if (opened || subAccountVisible) {
        window && window.scrollTo(0, 0)
        body.classList.add('menu-opened')
      } else {
        body.classList.remove('menu-opened')
      }
    }
  }, [opened, subAccountVisible])

  useEffect(() => {
    if (isValidArray(subAccounts) && merchantId) {
      const filteredAccounts = subAccounts && subAccounts?.filter(account => account.merchantId !== merchantId)
      setFilteredAccounts(filteredAccounts)
    }
  }, [subAccounts, merchantId])

  const handleSearch = useDebounce(term => {
    const query = term.replace(/\s/g, '').toLowerCase()
    const filtered =
      subAccounts && subAccounts?.filter(account => account.name.toLowerCase().replace(/\s/g, '').includes(query))
    setFilteredAccounts(filtered)
  }, 500)

  const handleChange = value => {
    setSearchTerm(value)
    handleSearch(value)
  }

  const onCloseHamburgerMenu = () => {
    setOpened(!opened)
  }

  const handleSwitchMerchant = async (merchantId: number) => {
    if (!switchMerchantLoading && merchant?.merchantId !== merchantId) {
      try {
        await switchMerchant({ merchantId }).unwrap()
        dispatch(api.util.resetApiState())
      } catch (_error) {}
    }
  }

  return (
    <>
      <div
        className={cn('header-wrapper', { stepper: shouldIncreaseHeight(), 'bottom-border': shouldShowBottomBorder() })} // Increase height of the header
        data-visible={visible}>
        <div className='header-main-content'>
          {opened && <div className='overlay' onClick={hideMenu} />}
          <div
            className={cn(
              'header-container',
              { 'no-buttons': shouldHideButtons() },
              { 'increased-width': isDashboard() }
            )}>
            {!isLarge ? (
              CustomHeaderTitles(location.pathname, merchantName, currentStep, totalSteps, isLarge)
            ) : (
              <KingdomBankLogo onClick={() => history.push(paths.dashboard())} />
            )}
            <div className='header-right-container'>
              {isLarge && subAccounts?.length > 1 && (
                <div className={'header-account-mobile-container'}>
                  <div onClick={() => toggleDropDown()} className='header-account-mobile-wrapper'>
                    <div className='header-account-mobile-account'>
                      <CoreProfileAvatar name={merchantName} image={merchantLogo} />
                    </div>
                    <div className={cn('header-account-chevron', { open: subAccountVisible })}>
                      <ChevronIcon />
                    </div>
                  </div>
                  {subAccountVisible && (
                    <div ref={setSubAccountRef} className='sub-account-wrapper'>
                      {isValidArray(filteredAccounts) ? (
                        <div className='sub-account-container'>
                          {filteredAccounts.map(account => {
                            const { name, merchantId } = account || {}
                            return (
                              <div
                                key={merchantId}
                                className='sub-account-menu-item'
                                onClick={() => {
                                  handleSwitchMerchant(merchantId)
                                  toggleDropDown()
                                }}>
                                <CoreProfileAvatar name={name} />
                                <span>{name}</span>
                              </div>
                            )
                          })}
                        </div>
                      ) : (
                        <div
                          key={merchantId}
                          className='sub-account-menu-item'
                          onClick={() => {
                            handleSwitchMerchant(merchantId)
                            toggleDropDown()
                          }}>
                          <span>Result not found</span>
                        </div>
                      )}
                      <div className='mt-1'>
                        <CoreInput
                          data-test='search'
                          type='text'
                          label='search'
                          value={terms}
                          size='small'
                          LeftIcon={<SearchIcon />}
                          onChange={(_, value) => {
                            handleChange(value)
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className='header-right-wrapper'>
                <div className='header-right-side'>
                  {!shouldHideButtons() && hasAnyPermissions && (
                    <>{!isLarge ? <PaymentButtons /> : <MobilePaymentButtons isOpenDropDown={opened} />}</>
                  )}
                  {shouldShowMobileButtons() && isLarge && <MobilePaymentButtons isOpenDropDown={opened} />}
                  {!isSubmitterOrApprover && (
                    <CoreButton
                      onClick={() => handleOpenSidebarRight()}
                      CollapsedIcon={<BellIcon />}
                      collapsed
                      size='large'
                      variation='elevated'
                    />
                  )}
                  {isLarge && isLoggedIn() ? (
                    <div className={cn('header-container-inner', { 'increased-width': isDashboard() })}>
                      <div>
                        <div
                          data-for={`tooltip-hamburger-menu`}
                          data-tip={tr('FRONTEND.HEADER.TOOLTIP.MENU', 'menu')}
                          className={cn('header-hamburger', { open: opened })}>
                          <div
                            data-for={`tooltip-hamburger-menu`}
                            data-tip={'menu'}
                            className='header-hamburger-icon'
                            onClick={() => setOpened(prevState => !prevState)}>
                            <CoreButton
                              className='header-hamburger-button'
                              collapsed
                              CollapsedIcon={<div className='menu-icon' />}
                              size='large'
                              variation='elevated'
                            />
                            {/* <ReactTooltip
                              delayShow={200}
                              id='tooltip-hamburger-menu'
                              className='custom-tool-tip-component'
                              textColor='black'
                              backgroundColor='white'
                              effect='solid'
                              place='bottom'
                            /> */}
                          </div>
                        </div>
                        <HamburgerMenu opened={opened} onClose={onCloseHamburgerMenu} />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLarge && (
          <div className='header-onboarding-wrapper'>
            <OnboardingBanner />
            {CustomHeaderTitles(location.pathname, merchantName, currentStep, totalSteps, isLarge)}
          </div>
        )}
        {/* {isLarge && } */}
      </div>
    </>
  )
}

const PaymentButtons = () => {
  const { visible, toggleDropDown } = useDropDownMenu()
  const { modalShow } = useContext(GlobalContext)
  const history = useHistory()
  const customerRole = useAppSelector(selectCurrentUserRole)

  const isOwnerOrAdministrator = () =>
    customerRole === CustomerRoleEnum.OWNER || customerRole === CustomerRoleEnum.ADMINISTRATOR

  return (
    <div onClick={() => toggleDropDown()} className={cn('header-payment-buttons', { visible: visible })}>
      <span>{tr('FRONTEND.HEADER.QUICK_ACTIONS', 'Quick actions')}</span>
      <div className='payment-icon'>
        <CrossIcon />
      </div>

      <div
        className='payment-dropdown'
        style={{
          height: `${visible ? getButtons(isOwnerOrAdministrator()).length * NAVIGATION_ROW_HEIGHT : 0}px`
        }}>
        {getButtons(isOwnerOrAdministrator()).map(button => {
          return (
            <div
              key={button.label}
              onClick={() => (button?.path ? history.push(button.path) : button.func(modalShow))}
              className={cn('payment-button', { visible: visible })}>
              {button.icon}
              <p>{button.label}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const MobilePaymentButtons = ({ isOpenDropDown }) => {
  const { visible, toggleDropDown } = useDropDownMenu()
  const { modalShow } = useContext(GlobalContext)
  const history = useHistory()
  const customerRole = useAppSelector(selectCurrentUserRole)
  const isLarge = useMatchMedia({ breakpoint: breakpoints.xl, prefix: 'max' })

  const isOwnerOrAdministrator = () =>
    customerRole === CustomerRoleEnum.OWNER || customerRole === CustomerRoleEnum.ADMINISTRATOR

  return (
    <div style={{ zIndex: isOpenDropDown ? '106' : '110' }} className='header-mobile-payment-buttons-wrapper'>
      <div className={cn('header-mobile-background-shadow', { visible: visible })}></div>
      <div onClick={() => toggleDropDown()} className={cn('header-mobile-payment-buttons', { visible: visible })}>
        <div data-for={`payment-button-menu`} data-tip={'Quick actions'} className='payment-icon-wrapper'>
          <div className='payment-icon'>
            <CrossIcon />
          </div>
          {isLarge && (
            <ReactTooltip
              id={`payment-button-menu`}
              textColor='black'
              backgroundColor='white'
              effect='solid'
              className='custom-tool-tip-component'
              padding='8px 6px'
              place={'right'}>
              {'Quick Actions'}
            </ReactTooltip>
          )}
        </div>
        <div
          className='payment-dropdown'
          style={{
            height: `${visible ? getButtons(isOwnerOrAdministrator()).length * NAVIGATION_ROW_HEIGHT + 110 : 0}px`,
            top: `${visible ? `-${getButtons(isOwnerOrAdministrator()).length * 68}px` : 0} `
          }}>
          {getButtons(isOwnerOrAdministrator()).map(button => {
            return (
              <div
                key={button.label}
                onClick={() => (button?.path ? history.push(button.path) : button.func(modalShow))}
                className={cn('payment-button', { visible: visible })}>
                {button.icon}
                <p>{button.label}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const CustomHeaderTitles = (
  path: string,
  merchantName: string,
  currentStep: number,
  totalSteps: [],
  isLarge: boolean
) => {
  const history = useHistory()

  const parts = path?.split('/') // Split the string by '/'
  const param = parts[parts?.length - 1] || '' // Get the last part
  const successTransactionParam = parts[parts?.length - 2] || ''

  switch (path) {
    case '/profile/security':
    case '/profile/details':
    case '/profile/payment':
      return (
        !isLarge && (
          <div className='header-title-wrapper'>
            <span className='title'>{tr('FRONTEND.HEADER.PROFILE_SETTINGS_TITLE', 'Profile settings')}</span>
          </div>
        )
      )
    case paths.onboarding.questionnaireKyb():
      return (
        <div className='step-container'>
          <div className='step-left-side'>
            <div
              onClick={e => {
                e.preventDefault()
                window.history.back()
              }}
              className='back-arrow-icon'>
              <LeftArrowIcon />
            </div>
            <span className='title'>{tr('FRONTEND.HEADER.QUESTIONNAIRE_TITLE', 'KYB-Questionnaire')}</span>
          </div>
          {HeaderStepComponent(currentStep, totalSteps, isLarge)}
        </div>
      )
    case paths.banking.deposit():
    case paths.banking.depositPreview():
      return (
        <div className='step-container'>
          <div className='step-left-side'>
            <div
              onClick={e => {
                e.preventDefault()
                window.history.back()
              }}
              className='back-arrow-icon'>
              <LeftArrowIcon />
            </div>
            <div className='title-wrapper'>
              <span className='title'>{tr('FRONTEND.HEADER.DEPOSIT', 'Deposit')}</span>
            </div>
          </div>
          {HeaderStepComponent(currentStep, totalSteps, isLarge)}
        </div>
      )
    case paths.more.cashVoucher('buy'):
    case paths.more.cashVoucher('redeem'):
    case paths.more.cashVoucher('list'):
      return (
        <div className='header-title-wrapper'>
          <span className='title'>{tr('FRONTEND.HEADER.ACCOUNTS.KINGDOM_CASH', 'Kingdom Cash')}</span>
          {!isLarge && (
            <span className='sub-title'>
              {tr('FRONTEND.HEADER.KINGDOM_CASH.SUBTITLE', 'Buy new and redeem your vouchers.')}
            </span>
          )}
        </div>
      )
    case paths.banking.accounts.list():
      return (
        <div className='header-title-wrapper'>
          <span className='title'>{tr('FRONTEND.HEADER.ACCOUNTS.TITLE', 'Accounts')}</span>
          {!isLarge && (
            <span className='sub-title'>{tr('FRONTEND.HEADER.ACCOUNTS.SUBTITLE', 'Manage your accounts')}</span>
          )}
        </div>
      )
    case paths.recipients.list():
      return (
        <div className='header-title-wrapper'>
          <span className='title'>{tr('FRONTEND.HEADER.RECIPIENTS.TITLE', 'Recipients')}</span>
          {!isLarge && (
            <span className='sub-title'>{tr('FRONTEND.HEADER.RECIPIENTS.SUBTITLE', 'Manage your recipients')}</span>
          )}
        </div>
      )
    case paths.recipients.details(param):
      return (
        <div className='header-title-wrapper'>
          <RecipientDetailsHeader param={param} />
        </div>
      )
    case paths.more.savings():
      return (
        <div className='header-title-wrapper'>
          <span className='title'>{tr('FRONTEND.HEADER.SAVINGS_TITLE', 'Savings')}</span>
          {!isLarge && (
            <span className='sub-title'>{tr('FRONTEND.HEADER.SAVINGS_SUBTITLE', 'Deposit any free funds.')}</span>
          )}
        </div>
      )
    case paths.banking.exchange():
      return (
        <div className='step-container'>
          <div className='step-left-side'>
            <div
              onClick={e => {
                e.preventDefault()
                window.history.back()
              }}
              className='back-arrow-icon'>
              <LeftArrowIcon />
            </div>
            <div className='title-wrapper'>
              <span className='title'>{tr('FRONTEND.HEADER.EXCHANGE_TITLE', 'Exchange')}</span>
            </div>
          </div>
          {HeaderStepComponent(currentStep, totalSteps, isLarge)}
        </div>
      )
    case paths.banking.send():
    case paths.banking.sendPix():
    case paths.banking.sendInterac():
    case paths.banking.sendPerfectMoney():
    case paths.banking.sendTransfer():
    case paths.banking.sendDigitalWallet():
    case paths.banking.sendIntraAccountTransfer():
    case paths.banking.sendTo(':recipientId'):
    case paths.banking.sendBankTransfer():
    case paths.banking.sendCrypto():
      return (
        <div className='step-container'>
          <div className='step-left-side'>
            <div
              onClick={e => {
                e.preventDefault()
                history.push(paths.dashboard())
              }}
              className='back-arrow-icon'>
              <LeftArrowIcon />
            </div>
            <div className='title-wrapper'>
              <span className='title'>{tr('FRONTEND.HEADER.SEND_TITLE', 'Send')}</span>
            </div>
          </div>
          {HeaderStepComponent(currentStep, totalSteps, isLarge, 'send-steps')}
        </div>
      )

    case paths.banking.sendBatchPayment():
      return (
        <div className='step-container'>
          <div className='step-left-side'>
            <div
              onClick={e => {
                e.preventDefault()
                history.push(paths.dashboard())
              }}
              className='back-arrow-icon'>
              <LeftArrowIcon />
            </div>
            <div className='title-wrapper'>
              <span className='title'>{tr('FRONTEND.BATCH_PAYMENTS.TITLE', 'Batch Payment')}</span>
            </div>
          </div>
          {HeaderStepComponent(currentStep, totalSteps, isLarge, 'send-steps')}
        </div>
      )

    case paths.invoices.create():
    case paths.invoices.upload():
    case paths.invoices.uploadEdit(param):
    case paths.invoices.add():
      return (
        <div className='step-container'>
          <div className='step-left-side'>
            <div
              onClick={e => {
                e.preventDefault()
                window.history.back()
              }}
              className='back-arrow-icon'>
              <LeftArrowIcon />
            </div>
            <div className='title-wrapper'>
              <span className='title'>{tr('FRONTEND.INVOICES.NEW.TITLE', 'Create invoice')}</span>
            </div>
          </div>
          {HeaderStepComponent(currentStep, totalSteps, isLarge)}
        </div>
      )
    case paths.invoices.send(param):
      return (
        <div className='step-container'>
          <div className='step-left-side'>
            <div
              onClick={e => {
                e.preventDefault()
                window.history.back()
              }}
              className='back-arrow-icon'>
              <LeftArrowIcon />
            </div>
            <div className='title-wrapper'>
              <span className='title'>{tr('FRONTEND.INVOICES.DETAILS.TITLE', 'Invoice details')}</span>
            </div>
          </div>
          {HeaderStepComponent(currentStep, totalSteps, isLarge)}
        </div>
      )

    case paths.invoices.edit(param):
      return (
        <div className='step-container'>
          <div className='step-left-side'>
            <div
              onClick={e => {
                e.preventDefault()
                window.history.back()
              }}
              className='back-arrow-icon'>
              <LeftArrowIcon />
            </div>
            <div className='title-wrapper'>
              <span className='title'>{tr('FRONTEND.INVOICES.EDIT.TITLE', 'Edit invoice')}</span>
            </div>
          </div>
          {HeaderStepComponent(currentStep, totalSteps, isLarge)}
        </div>
      )
    case paths.invoicesCustomersWrapper() + paths.invoices.list():
    case paths.invoicesCustomersWrapper() + paths.customers():
      return (
        <div className='step-container invoice-customers-steps'>
          <div className='step-left-side'>
            <div className='title-wrapper'>
              <span className='title'>{tr('FRONTEND.HEADER.INVOICES', 'Invoices')}</span>
              {!isLarge && (
                <span className='sub-title'>{tr('FRONTEND.HEADER.INVOICES_SUBTITLE', 'Manage your invoices')}</span>
              )}
            </div>
          </div>
          <InvoiceActions />
        </div>
      )
    case paths.banking.transactions.list():
      return (
        <div className='header-title-wrapper'>
          <span className='title'>{tr('FRONTEND.TRANSACTIONS.TITLE', 'Transactions')}</span>
          {!isLarge && (
            <span className='sub-title'>{tr('FRONTEND.HEADER.TRANSACTIONS_SUBTITLE', 'Check all your movements')}</span>
          )}
        </div>
      )
    case paths.referralProgram():
      return (
        <div className='header-title-wrapper'>
          <span className='title'>{tr('FRONTEND.HEADER.REFERRAL', 'Invite & Earn')}</span>
          {!isLarge && (
            <span className='sub-title'>{tr('FRONTEND.HEADER.REFERRAL_SUBTITLE', 'Check on your referrals.')}</span>
          )}
        </div>
      )

    case paths.card():
      return (
        <div className='header-title-wrapper'>
          <span className='title'>{tr('FRONTEND.HEADER.CARDS', 'Cards')}</span>
          {!isLarge && (
            <span className='sub-title'>{tr('FRONTEND.HEADER.CARDS_SUBTITLE', 'Check on your cards.')}</span>
          )}
        </div>
      )

    case paths.developer():
      return (
        <div className='header-title-wrapper'>
          <span className='title'>{tr('FRONTEND.HEADER.DEVELOPER', 'Developers')}</span>
        </div>
      )

    case paths.userManagement():
      return (
        <div className='header-title-wrapper'>
          <span className='title'>{tr('FRONTEND.HEADER.USER_MANAGEMENT', 'User management')}</span>
        </div>
      )
    case paths.ibanIssuing():
    case paths.ibanIssuingPreview():
      return (
        <div className='step-container'>
          <div className='step-left-side'>
            <div
              onClick={e => {
                e.preventDefault()
                window.history.back()
              }}
              className='back-arrow-icon'>
              <LeftArrowIcon />
            </div>
            <div className='title-wrapper'>
              <span className='title'>{tr('FRONTEND.HEADER.IBAN_REQUEST', 'IBAN Issuing')}</span>
              {!isLarge && (
                <span className='sub-title'>{tr('FRONTEND.HEADER.IBAN_SUBHEADER', 'Obtain a dedicated IBAN.')}</span>
              )}
            </div>
          </div>
          {HeaderStepComponent(currentStep, totalSteps, isLarge)}
        </div>
      )

    case paths.expenseManagement.expenseManagementDashboard():
    case paths.expenseManagement.reports():
      return (
        <div className='step-container expenses-steps'>
          <div className='step-left-side'>
            <div className='title-wrapper'>
              <span className='title'>{tr('FRONTEND.EXPENSE_MANAGEMENT.EXPENSE_REPORTS', 'Expense Reports')}</span>
              {!isLarge && (
                <span className='sub-title'>
                  {tr('FRONTEND.HEADER.EXPENSE_REPORTS_SUBTITLE', 'Control what you spend.')}
                </span>
              )}
            </div>
          </div>
          <ReportsViewHeader />
        </div>
      )

    case paths.expenseManagement.report(param):
      return (
        <div className='step-container expenses-steps'>
          <ReportHeader param={param} />
        </div>
      )

    case paths.expenseManagement.payments(param):
      return (
        <div className='step-container'>
          <ReportHeader param={param} />
        </div>
      )

    case paths.expenseManagement.counterParty():
      return (
        <div className='step-container expenses-steps'>
          <div className='step-left-side'>
            <span className='title'>{tr('FRONTEND.EXPENSE_MANAGEMENT.TITLE.COUNTERPARTIES', 'Counterparties')}</span>
          </div>
          <CounterpartyViewHeader />
        </div>
      )

    case paths.expenseManagement.singleCounterparty(param):
      return (
        <div className='step-container'>
          <CounterpartyPageHeader counterpartyId={param} />
        </div>
      )

    case paths.scanAndPay():
    case paths.p2pQrCode():
      return (
        <div className='step-container'>
          <div className='step-left-side'>
            <div
              onClick={e => {
                e.preventDefault()
                history.push(paths.dashboard())
              }}
              className='back-arrow-icon'>
              <LeftArrowIcon />
            </div>
            <div className='title-wrapper'>
              <span className='title'>{tr('FRONTEND.HEADER.SCAN_AND_PAY', 'Scan & Pay')}</span>
            </div>
          </div>
        </div>
      )
    case paths.banking.finalStatus.success(successTransactionParam):
    case paths.banking.finalStatus.fail(successTransactionParam):
      return <div />

    case paths.forex():
    case paths.webTerminal():
      return (
        <div className='step-container'>
          <div className='step-left-side'>
            <div className='title-wrapper'>
              <span className='title'>{tr('FRONTEND.FOREX.PAGE.TITLE', 'Forex')}</span>
            </div>
          </div>
        </div>
      )

    default:
      return (
        !isLarge && (
          <div className='header-title-wrapper'>
            {merchantName && <span className='title'>{`Hello, ${merchantName}!`}</span>}
            <span className='sub-title'>
              {tr('FRONTEND.HEADER.OVERVIEW_SUBHEADER', 'Check out your recent financial activity')}
            </span>
          </div>
        )
      )
  }
}

const HeaderStepComponent = (currentStep, totalSteps, isLarge, className = '') => {
  return isLarge ? (
    <div className='header-mobile-step-wrapper'>
      <span>
        {currentStep > totalSteps.length ? totalSteps.length : currentStep}/{totalSteps.length}
      </span>
    </div>
  ) : (
    <div className={`header-stepper ${className}`}>
      {isValidArray(totalSteps) &&
        totalSteps.map(({ context, step }) => {
          return (
            <div key={step} className={cn('header-stepper-wrapper', { active: currentStep == step })}>
              <div className='header-stepper-step'>{currentStep > step ? <CheckIcon /> : <span>{step}</span>}</div>
              <div className='header-stepper-context'>
                <span>{context}</span>
              </div>
              {totalSteps.length > step && (
                <div className='stepper-arrow-icon'>
                  <OnboardingArrow />
                </div>
              )}
            </div>
          )
        })}
    </div>
  )
}
