import styled from 'styled-components'

export const BackButtonContainer = styled.div`
  background: none;
  height: auto;
  top: calc(50% - 19px);
  position: absolute;
  background: none;
  left: -10rem;

  @media (max-width: 960px) {
    position: inherit;
  }

  @media (max-width: 768px) {
    left: 0rem;
  }

  .back-button {
    color: #85a0ad;
    background: inherit !important;
    box-shadow: none;
    font-size: 1.4rem;
    font-weight: 400;

    svg {
      width: 12.5px;
      height: 9.5px;
    }

    &:hover {
      color: #85a0ad;

      svg {
        path {
          stroke: #85a0ad;
        }
      }
    }
  }
`
