import { useHistory } from 'react-router-dom'
import toast from 'react-hot-toast'

import { paths, useDeleteReportMutation } from 'mmfintech-backend-api'
import { ExpenseManagementReport } from 'mmfintech-commons-types'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { tr } from 'mmfintech-commons'

import { CoreButton, CoreModalDialogFooter } from '@components'

import TrashIcon from '@images/icons/trash-modal.svg?react'

import './deleteReportModal.scss'

interface IDeleteReport {
  report: ExpenseManagementReport
  onClose: (e?: any) => void
}

export const DeleteReportModal = ({ report, onClose }: IDeleteReport) => {
  const [deleteReport, { error, isLoading }] = useDeleteReportMutation()
  const history = useHistory()

  const onDeleteReport = async (e: React.MouseEvent) => {
    e.stopPropagation()

    try {
      await deleteReport({ id: report.id }).unwrap()
      toast.success(tr('FRONTEND.REPORTS.ACTIONS.DELETE_REPORT_SUCCESS', 'Report deleted successfully'))

      history.push(paths.expenseManagement.expenseManagementDashboard())

      onClose()
    } catch (error) {}
  }

  const onClickClose = (e: React.MouseEvent) => {
    e.stopPropagation()
    onClose(e)
  }

  return (
    <div className='delete-report-modal' onClick={e => e.stopPropagation()} onMouseDown={e => e.stopPropagation()}>
      <div className='icon'>
        <TrashIcon />
      </div>
      <div className='confirmation'>
        {tr('FRONTEND.REPORTS.ACTIONS.DELETE_REPORT_DESCRIPTION', 'Are you sure you want to delete report')}:{' '}
        <span>{report.name}</span>
      </div>
      <ErrorDisplay error={error} />

      <CoreModalDialogFooter>
        <div className='buttons-container'>
          <CoreButton
            text={tr('FRONTEND.BUTTONS.DELETE', 'Delete')}
            onClick={onDeleteReport}
            data-test='delete-report-button'
            isLoading={isLoading}
            fullWidth
            size='large'
          />
          <CoreButton
            variation='tertiary'
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            onClick={onClickClose}
            data-test='close-delete-report-button'
            isLoading={isLoading}
            size='large'
          />
        </div>
      </CoreModalDialogFooter>
    </div>
  )
}
