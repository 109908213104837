import ArrowUpRight from '@images/icons/arrow-up-right-from-square-solid.svg?react'

type HashLinkProps = {
  hash?: string
  link?: string
}

export const HashLink = ({ hash, link }: HashLinkProps) => {
  if (hash) {
    if (link) {
      return (
        <p>
          <a href={link} target='_blank' rel='noreferrer' className='hash-display'>
            {hash}
            <ArrowUpRight className='hash-icon' width={16} height={16} />
          </a>
        </p>
      )
    }
    return <p>{hash}</p>
  }

  return null
}
