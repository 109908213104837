// import { CoreModalDialog } from 'components/CoreModalDialog'
// import { tr } from 'mmfintech-commons'
// import { useState } from 'react'

import { configuration } from 'mmfintech-backend-api'
import { CoreButton } from '../CoreButton'
import settings from '@settings'
import './FinalyRejectedModal.scss'
import { tr } from 'mmfintech-commons'

interface FinallyRejectedProps {
  setCookie: any
  cookieConsent?: any
}

export const FinallyRejectedModal = ({ setCookie }: FinallyRejectedProps) => {
  return (
    <div className='finally-rejected-modal-wrapper'>
      <span>Please contact Customer Service to get support on your account verification.</span>
      <CoreButton
        text={tr('FRONTEND.BUTTONS.CONFIRM', 'Confirm')}
        fullWidth
        size='large'
        onClick={() => {
          const aYearFromNow = new Date()
          aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)

          setCookie(
            'cookie.modals',
            JSON.stringify({
              finallyRejectedShown: true
            }),
            {
              expires: aYearFromNow,
              maxAge: 31536000,
              domain: configuration.isLocal() ? null : settings.cookieDomain
            }
          )
        }}
      />
    </div>
  )
}
