import { useHistory } from 'react-router-dom'

import './viewAllButton.scss'

import ArrowIcon from '../../images/icons/red-right-arrow.svg?react'

interface ViewAllButtonProps {
  label?: string
  redirect?: string | (() => void)
  accountId?: number
  recipientId?: number
}

export const ViewAllButton = ({ label, redirect, accountId, recipientId }: ViewAllButtonProps) => {
  const history = useHistory()

  return (
    <div
      className='view-all-header-button'
      onClick={() =>
        typeof redirect == 'string'
          ? history.push(redirect, { accountId: accountId, recipientId: recipientId })
          : redirect()
      }
      data-test='view-all-button'>
      {label}
      <ArrowIcon />
    </div>
  )
}
