import './sendMoneyAmountRow.scss'

export const SendMoneyAmountRow = ({
  label,
  value,
  dataTest
}: {
  label: string
  value: string | number | JSX.Element
  dataTest?: string
}) => {
  if (!value) return null

  return (
    <div className='send-money-amount-row' data-test={dataTest}>
      <span className='amount-label'>{label}:</span>
      <span className='amount-value'>{value}</span>
    </div>
  )
}
