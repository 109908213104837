import React, { ReactNode } from 'react'
import cn from 'classnames'
import './coreCheckbox.scss'

type CheckboxType = 'check' | 'dash' | 'circle' | 'checked-circle'
interface checkboxProps {
  type: CheckboxType
  value: string
  label?: string
  checked?: boolean
  onClick?: (checked: boolean) => void
  fontStyle?: React.CSSProperties
  disabled?: boolean
  style?: React.CSSProperties
  className?: string
  optional?: any
  icon?: ReactNode
  dataTest?: string
}

const Checkbox = ({
  type = 'check',
  value,
  label,
  onClick,
  checked = false,
  fontStyle,
  disabled,
  style,
  className,
  optional,
  dataTest,
  icon,
  ...props
}: checkboxProps) => {
  const handleClick = event => {
    event.stopPropagation()
    onClick && onClick(!checked)
  }

  return (
    <div
      data-test={dataTest || `check-button-${value}`}
      style={{ ...style }}
      className={cn(`${className}`, {
        'check-checkbox': type === 'check',
        'dash-checkbox': type === 'dash',
        'circle-checkbox': type === 'circle',
        'checked-circle-checkbox': type === 'checked-circle'
      })}>
      <input
        onChange={handleClick}
        disabled={disabled}
        checked={checked}
        value={value}
        id={value || ''}
        type='checkbox'
        {...props}
      />
      {label ? (
        <label htmlFor={value}>
          <span className='check-box-component'></span>
          {icon && <span className={'icon'}>{icon}</span>}
          <p data-test={`checkbox-label-${label}`} style={{ ...fontStyle }}>
            {label}
          </p>
        </label>
      ) : (
        <label htmlFor={value}>
          <span className='check-box-component'></span>
        </label>
      )}
      {checked ? optional : null}
    </div>
  )
}

export const CoreCheckbox = React.memo(Checkbox)
