import './CoreToastMessage.scss'

interface ICoreToastMessage {
  title?: string
  subtitle?: string
}

export const CoreToastMessage = ({ title, subtitle }: ICoreToastMessage) => {
  return (
    <div className='toast-message-wrapper'>
      <span className='toast-title'>{title}</span>
      <span className='toast-sub-title'>{subtitle}</span>
    </div>
  )
}
