import { useHistory } from 'react-router-dom'

import { paths, useGetMerchantQuery } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'

import { useHasWritePermissions, useMatchMedia } from '@hooks'
import { CoreButton, SpendingLimitBarUser } from '@components'
import { TransactionsSection } from '../TransactionsSection'
import { AccountsOverview } from './AccountsOverview'
import { breakpoints } from '@constants'

import QRCodeIcon from '@images/icons/qr-code-icon.svg?react'
import CrossIcon from '@images/icons/cross-icon.svg?react'
import ArrowIcon from '@images/icons/arrow-icon.svg?react'

import './staffDashboard.scss'

export const StaffDashboard = () => {
  const isCollapsed = useMatchMedia({ breakpoint: breakpoints.MENU_CLOSE_WIDTH, prefix: 'max' })
  const { data: merchant } = useGetMerchantQuery(null)
  const hasAnyPermissions = useHasWritePermissions()
  const history = useHistory()

  const { capabilities } = merchant || {}
  const { enablePortalWalletCheckout } = capabilities || {}

  return (
    <div className='staff-dashboard-wrapper' data-test='staff-dashboard'>
      {hasAnyPermissions && (
        <div className='staff-dashboard-container'>
          <SpendingLimitBarUser />

          <div className='staff-dashboard-buttons'>
            {hasAnyPermissions && (
              <>
                <CoreButton
                  variation='elevated'
                  size='large'
                  text={tr('FRONTEND.DASHBOARD.BUTTONS.DEPOSIT', 'Deposit')}
                  LeftIcon={<CrossIcon />}
                  collapsed={isCollapsed}
                  CollapsedIcon={<CrossIcon />}
                  onClick={() => {
                    history.push(paths.banking.deposit())
                  }}
                  data-test='deposit-button'
                />
                <CoreButton
                  variation='elevated'
                  size='large'
                  text={tr('FRONTEND.DASHBOARD.BUTTON_SEND', 'Send')}
                  LeftIcon={<ArrowIcon />}
                  collapsed={isCollapsed}
                  CollapsedIcon={<ArrowIcon />}
                  onClick={() => {
                    history.push(paths.banking.send())
                  }}
                  data-test='send-money-button'
                />

                {enablePortalWalletCheckout && (
                  <CoreButton
                    variation='elevated'
                    LeftIcon={<QRCodeIcon />}
                    text={tr('FRONTEND.DASHBOARD.BUTTONS.SCAN_AND_PAY', 'Scan & Pay')}
                    onClick={() => history.push(paths.scanAndPay())}
                    data-test='scan-and-pay-button'
                    size='large'
                    collapsed={isCollapsed}
                    CollapsedIcon={<QRCodeIcon />}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}

      <AccountsOverview />
      <TransactionsSection />
    </div>
  )
}
