import cn from 'classnames'

import { IPasswordTermsProps } from './PasswordTerms.types'

import { tr, checkPassword } from 'mmfintech-commons'

import './passwordTerms.scss'

export const PasswordTerms = ({ password = '', differ = false, differLogic = false }: IPasswordTermsProps) => {
  const { digit, letter, special, minLength } = checkPassword(password?.trim() || '')?.terms

  return (
    <div className={`password-terms-wrapper password-terms ${!password && 'not-started'}`}>
      <div className={cn('circle-wrapper', { 'not-done': !digit })}>
        <div className='circle' />

        {tr('VALIDATION.PASSWORD.TERMS.DIGIT', 'Оne digit')}
      </div>

      <div className={cn('circle-wrapper', { 'not-done': !letter })}>
        <div className='circle' />
        {tr('VALIDATION.PASSWORD.TERMS.LETTER', 'Оne letter')}
      </div>

      <div className={cn('circle-wrapper', { 'not-done': !special })}>
        <div className='circle' />
        {tr('VALIDATION.PASSWORD.TERMS.SPECIAL_CHAR', 'Оne special character')}
      </div>

      <div className={cn('circle-wrapper', { 'not-done': !minLength })}>
        <div className='circle' />
        {tr('VALIDATION.PASSWORD.TERMS.MIN_LENGTH', 'Minimum 8 (eight) characters')}
      </div>

      {differ ? (
        <div className={cn('circle-wrapper', { 'not-done': !minLength && !differLogic })}>
          <span className='icon'>
            <div className='circle' />
          </span>
          <span>{tr('VALIDATION.PASSWORD.TERMS.DIFFER', 'New password should be different from the old one')}</span>
        </div>
      ) : null}
    </div>
  )
}
