import { ErrorDisplay } from 'mmfintech-portal-commons'

import { isValidArray, tr } from 'mmfintech-commons'

import { useBatchPaymentPreviewQry } from 'mmfintech-backend-api'
import { CoreButton } from '@components'
import { BatchWithdrawalRowDTO } from 'mmfintech-commons-types'
import { UploadErrorRow } from './UploadErrorRow'

import UploadFailedIcon from '@images/icons/batch-failed.svg?react'

import './batchPaymentErrors.scss'

interface BatchPaymentErrorsProps {
  onReload: () => void
}

export const BatchPaymentErrors = ({ onReload }: BatchPaymentErrorsProps) => {
  const { previewData: batchPaymentsUpload, previewError: batchPaymentsUploadError } = useBatchPaymentPreviewQry()

  let count = 0

  return (
    <div className='send-money-container batch-errors-container'>
      <div className='failed-title'>
        <UploadFailedIcon />
        <span>
          {batchPaymentsUploadError ? (
            <ErrorDisplay error={batchPaymentsUploadError} hideIcon />
          ) : (
            tr('FRONTEND.BATCH_PAYMENTS.UPLOAD_ERROR.TITLE', 'File Errors')
          )}
        </span>
      </div>

      <div className='failed-subtitle'>
        <span>
          {tr(
            'FRONTEND.BATCH_PAYMENTS.UPLOAD_ERROR.MESSAGE',
            'The rows and columns in the uploaded file contain the following errors. Please correct the data and reload the file.'
          )}
        </span>
      </div>

      {isValidArray(batchPaymentsUpload) && (
        <div className='mb-3'>
          {batchPaymentsUpload?.map((row: BatchWithdrawalRowDTO, index: number) => {
            if ((!row.rowValid || !row.previewValid) && count < 10) {
              count++
              return <UploadErrorRow key={`row-${index}`} row={row} rowNum={index + 2} />
            }
            return null
          })}
        </div>
      )}

      <div className='reload-batch-button'>
        <CoreButton
          size='large'
          fullWidth
          text={tr('FRONTEND.BATCH_PAYMENTS.UPLOAD_ERROR.RELOAD_BUTTON', 'Reload Batch File')}
          data-test='execute-button'
          onClick={onReload}
        />
      </div>
    </div>
  )
}
