import styled from 'styled-components'

import BackgroundImage from '@images/kingdom-bank-lion.png'

export const BasePageWrapper = styled.div`
  grid-area: main-content;
  //padding: 3rem 2rem 2rem;

  display: flex;
  flex-flow: column nowrap;

  @media (min-width: 1175px) {
    //padding: 5rem 2rem 2rem;
  }
`

export const CommonSignupWrapper = styled.div`
  grid-column: left-column / right-column;
  grid-row: 1 / 4;

  min-height: 100vh;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  background-color: #000000;

  @media (min-width: 640px) {
    padding-bottom: 10rem;
  }

  @media (min-width: 768px) {
    //prettier-ignore
    background-image: url("${BackgroundImage}");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: auto 100%;
  }
`

export const CommonSignupContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  height: 100%;

  .err-msg-wrapper {
    display: flex;
    justify-content: center;
  }

  @media (min-width: 640px) {
    justify-content: space-between;
  }
`

export const CommonSignupContent = styled.div`
  width: 92vw;
  max-width: 480px;

  background: #f8f8f8;
  border-radius: 1rem;
  padding: 3rem;
  margin-bottom: 3rem;

  @media (min-width: 480px) {
    padding: 4rem;
  }

  .heading {
    color: #ff3131;
    font-family: inherit;
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 102.52%;
    letter-spacing: -0.025em;
    margin-bottom: 3rem;

    .subtitle {
      color: #000000;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 102.52%;
      letter-spacing: -0.025em;
      margin-top: 1rem;
    }
  }

  .input-wrapper {
    margin-bottom: 2rem;
    .show-password,
    input {
      background: #f8f8f8;
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 3rem #f8f8f8 inset !important;
        box-shadow: 0 0 3rem 3rem #f8f8f8 inset !important;
        background: #f8f8f8 !important;
      }
      &::placeholder {
        color: #85a0ad;
      }
    }
  }

  .button {
    font-family: inherit;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.9rem;
  }
`

export const CommonMessageContainer = styled.div`
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  width: 92vw;
  max-width: 50rem;
  padding: 5rem 2rem;

  color: #454c56;
  font-family: inherit;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.9rem;
  text-align: center;
  letter-spacing: -0.025em;

  .icon {
    margin-bottom: 2.5rem;
  }

  .heading {
    color: #000000;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 2.9rem;
    text-align: center;
    letter-spacing: normal;

    margin-bottom: 1.2rem;
  }

  .button {
    //max-width: 20rem;
    margin-top: 2.5rem;
  }

  @media (min-width: 480px) {
    padding: 4rem 5rem;
  }
`

export const CommonPageTitle = styled.div`
  color: #000000;
  font-family: inherit;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.9rem;

  margin-bottom: 2.5rem;

  .subtitle {
    color: #85a0ad;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin-top: 5px;
  }
`

export const CommonRoundShadowedContainer = styled.div`
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);
  color: #000000;
`

export const signupSelectInputStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '100%'
  }),
  control: (provided: any) => ({
    ...provided,
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    height: '4.5rem'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    padding: 0
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    paddingRight: 0,
    color: '#85a0ad'
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#85a0ad',
    fontSize: '1.4rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.7rem'
  }),
  option: (provided: any, { isFocused }) => ({
    ...provided,
    color: isFocused ? '#f8f8f8 !important' : '#000000 !important',
    cursor: 'pointer',
    fontSize: '1.4rem',
    lineHeight: 'normal',
    margin: '0',
    background: isFocused ? '#1a1a1a !important' : '#ffffff !important'
  })
}

export const signupPhoneInputStyles = {
  containerStyle: {
    background: 'transparent'
  },
  inputStyle: {
    background: '#f8f8f8',
    border: 'none',
    paddingLeft: '5rem',
    paddingRight: '16px',
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    //height: '3.75rem',
    width: '100%'
  },
  buttonClass: 'area-code-button',
  buttonStyle: {
    background: 'transparent',
    border: 'none',
    width: '5rem'
  }
}
