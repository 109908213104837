import { useOnboarding } from '@hooks'
import { CoreButton } from '@components'
import { tr } from 'mmfintech-commons'
import './CoreApplicationFeeAnnounce.scss'
import AnnounceIcon from '@images/icons/announce-icon.svg?react'
import ButtonArrowIcon from '@images/icons/arrow-down-icon.svg?react'

export const CoreApplicationFeeAnnounce = () => {
  const { payAccountSetupFee } = useOnboarding()

  return (
    <div className='application-fee-banner'>
      <div className='application-fee-heading'>
        <div className='application-fee-title'>
          <div className='application-fee-icon'>
            <AnnounceIcon />
          </div>
          {tr('FRONTEND.BANKING.COMMON.WARNING_TITLE', 'Please note')}
        </div>
        <div className='application-fee-actions'>
          <CoreButton
            size='normal'
            text={tr(`FRONTEND.BANKING.ONBOARDING.BUTTON.PAY`, 'Pay now')}
            fitContent
            RightIcon={<ButtonArrowIcon />}
            className='core-onboarding-announce-button'
            onClick={() => payAccountSetupFee()}
          />
        </div>
      </div>
      <div className='application-fee-subtitle'>
        {tr(
          'FRONTEND.BANKING.COMMON.ACCOUNT_PAYMENT',
          'You need to pay your application fee to enable your primary account.'
        )}
      </div>
    </div>
  )
}
