import './coreProfileAvatar.scss'

export const CoreProfileAvatar = ({ name, image, length = 2 }: { name?: string; image?: string; length?: number }) => {
  const getProfileInitials = (name: string): string => {
    if (!name) return ''
    const initials = name
      .split(' ')
      .map(chunk => chunk.substring(0, 1))
      .filter(char => Number.isNaN(Number(char)))
      .slice(0, length)
      .join('')
      .toUpperCase()

    if (!initials) return name.substring(0, 1)

    return initials
  }

  return (
    <div data-test='profile-avatar' className='profile-avatar'>
      {image ? <img src={image} className='avatar-image' /> : getProfileInitials(name)}
    </div>
  )
}
