import { Dispatch, KeyboardEvent, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import PhoneInput from 'react-phone-input-2'

import cn from 'classnames'

import './coreInput.scss'

import ClearIcon from '@images/icons/clear.svg?react'
import ClosedEyeIcon from '@images/icons/eye-closed.svg?react'
import OpenedEyeIcon from '@images/icons/eye-opened.svg?react'

interface InputProps {
  className?: string
  error?: any
  LeftIcon?: JSX.Element
  RightIcon?: JSX.Element
  label?: string | ReactNode
  name?: string
  onChange?: any
  hint?: string
  value?: string | number
  required?: boolean
  type?: 'text' | 'password' | 'phone' | 'date' | 'email' | 'number'
  clearable?: boolean
  setFocus?: Dispatch<SetStateAction<boolean>>
  focused?: boolean
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
  disabled?: boolean
  onClick?: () => void
  focusedInput?: (name) => void
  passwordLock?: (boolean) => void
  validation?: (string) => string
  rangeDates?: boolean
  startDate?: any
  endDate?: any
  minDate?: any
  maxDate?: any
  selectsStart?: boolean
  selectsEnd?: boolean
  placeholder?: string
  maxLength?: number
  autoComplete?: 'off' | 'on'
  size?: 'small' | 'normal' | 'large'
  onBlur?: (event) => void
  readOnly?: boolean
  dataPickerForwardYears?: number
  blockBlur?: boolean
  enableAutofill?: boolean
}

const isIphoneDevice = () => {
  const userAgent = navigator.userAgent
  return /iPhone|iPad|iPod/i.test(userAgent)
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const prepareYears = date => {
  return Array(date.getUTCFullYear() - (date.getUTCFullYear() - 110))
    .fill('')
    .map((_, idx) => date.getUTCFullYear() - idx)
}

const maxYear = new Date(2100, 11, 31)

export const CoreInput = ({
  className = '',
  error,
  LeftIcon,
  RightIcon,
  label,
  name,
  onChange,
  hint,
  value,
  required,
  type = 'text',
  clearable,
  setFocus,
  focused,
  onKeyDown,
  disabled,
  minDate,
  rangeDates,
  startDate,
  endDate,
  selectsStart,
  selectsEnd,
  maxDate,
  placeholder = '',
  focusedInput,
  passwordLock,
  validation,
  autoComplete = 'off',
  size = 'normal',
  onBlur,
  blockBlur = false,
  dataPickerForwardYears = 0,
  enableAutofill = true,
  ...rest
}: InputProps) => {
  const inputRef = useRef(null)

  const [inputValue, setInputValue] = useState(value)
  const [isFocused, setIsFocused] = useState(false)
  const [inputType, setInputType] = useState(type)
  const [tempDisable, setTempDisable] = useState(isIphoneDevice())

  const date = new Date()
  date.setFullYear(new Date().getFullYear() + dataPickerForwardYears)

  const years = prepareYears(date)

  const getYear = data => {
    return new Date(data).getUTCFullYear()
  }
  const getMonth = data => {
    return new Date(data).getMonth()
  }

  if (selectsEnd) {
  }
  useEffect(() => {
    if (!value) {
      setInputValue(null)
    }
    if (value) {
      setInputValue(validation ? validation(value) : value)
    }
  }, [value])

  useEffect(() => {
    // moves caret to the end of the input as the default focus sets it at the start
    if (inputRef.current && inputType !== 'email' && inputType !== 'number') {
      inputRef.current.selectionStart = inputRef.current.value.length
      inputRef.current.selectionEnd = inputRef.current.value.length
    }
  }, [inputType])

  let timeoutId = null

  useEffect(() => {
    if (focused) {
      setIsFocused(true)
      inputRef.current && inputRef.current?.focus()
    } else {
      setIsFocused(false)
      focusedInput && focusedInput(undefined)
      inputRef.current && inputRef.current?.blur()
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [focused, blockBlur])

  useEffect(() => {
    if (passwordLock) {
      inputType === 'password' ? passwordLock(true) : passwordLock(false)
    }
  }, [inputType])

  // useEffect(() => {
  //   if (type === 'date' && value !== undefined && value) {
  //     setYears(prepareYears(new Date(value)))
  //   }
  // }, [value])

  //this prevent IOS (Iphone) to focus and blur input fields on render
  useEffect(() => {
    if (isIphoneDevice())
      setTimeout(() => {
        setTempDisable(false)
      }, 200)
    else {
      setTempDisable(false)
    }
  }, [isIphoneDevice()])

  return (
    <div
      onClick={() => {
        setIsFocused(true)
        setFocus && setFocus(true)
        inputRef.current && inputRef.current?.focus()
      }}
      className={cn(className, 'core-input-wrapper', { 'disabled-input': tempDisable || disabled })}>
      <div className={cn('core-input-section', { focused: isFocused, errored: !!error }, size)}>
        {LeftIcon && <div className='core-input-left-icon'>{LeftIcon}</div>}
        <div
          className={cn('core-input-inner-section', {
            'inner-section-phone': type == 'phone',
            'inner-section-final-state': isFocused || !!inputValue
          })}>
          {type == 'phone' ? (
            <PhoneInput
              data-test='phone-input'
              placeholder=''
              onFocus={() => {
                setIsFocused(true)
                focusedInput && focusedInput(name)
                setFocus && setFocus(true)
              }}
              onBlur={e => {
                timeoutId = setTimeout(() => {
                  setIsFocused(false)
                  setFocus && !blockBlur && setFocus(false)
                  onBlur && onBlur(e)
                }, 200)
              }}
              onKeyDown={e => {
                if ((isNaN(Number(e.key)) && e.key !== 'Backspace' && e.key !== 'Tab') || e.key == ' ') {
                  return e.preventDefault()
                }
              }}
              onChange={(value, data: any) => {
                const dialCode = data?.dialCode || ''
                const currentPhoneCode = value.slice(0, dialCode.length)
                if (isFocused) {
                  if (dialCode !== currentPhoneCode) {
                    setInputValue(dialCode)
                    onChange && onChange(name, dialCode)
                  } else {
                    setInputValue(value)
                    onChange && onChange(name, value)
                  }
                } else {
                  setInputValue('')
                }
              }}
              value={inputValue + '' || ''}
              disabled={tempDisable || disabled}
              enableSearch
              disableSearchIcon
              searchPlaceholder=''
              inputProps={{ id: name, name, required }}
            />
          ) : type == 'date' ? (
            <div className='core-input-date-picker-wrapper'>
              {!rangeDates ? (
                <DatePicker
                  data-test='date-pick-input'
                  selected={value ? new Date(value) : null}
                  renderCustomHeader={({ date, changeYear, changeMonth }) => (
                    <div className='calendar-header-wrapper'>
                      <select
                        className='calendar-select-input'
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(value as any)}>
                        {years.map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <select
                        className='calendar-select-input'
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                        {months.map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  calendarStartDay={1}
                  onChange={date => onChange && date && onChange(name, date)}
                  calendarClassName='calendar-wrapper-classname'
                  minDate={minDate}
                  onBlur={e => {
                    timeoutId = setTimeout(() => {
                      if (document.activeElement !== inputRef.current) {
                        setIsFocused(false)
                        setFocus && !blockBlur && setFocus(false)
                        onBlur && onBlur(e)
                      }
                    }, 200)
                  }}
                  {...rest}
                  customInput={
                    <input
                      className={cn('core-input-field', { errored: !!error })}
                      onChange={e => {
                        setInputValue(e.target.value)
                        onChange && onChange(name, e.target.value, e)
                      }}
                      value={inputValue || ''}
                      onFocus={() => {
                        if (document.activeElement === inputRef.current) {
                          setIsFocused(true)
                          focusedInput && focusedInput(name)
                          setFocus && setFocus(true)
                        }
                      }}
                      type={'text'}
                      disabled={tempDisable || disabled}
                      autoComplete='off'
                      ref={inputRef}
                      required={required}
                      name={name}
                      onKeyDown={onKeyDown}
                    />
                  }
                />
              ) : (
                <DatePicker
                  renderCustomHeader={({ date, changeYear, changeMonth }) => (
                    <div className='calendar-header-wrapper'>
                      <select
                        className='calendar-select-input'
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(value as any)}>
                        {years.map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        className='calendar-select-input'
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) => {
                          changeMonth(months.indexOf(value))
                        }}>
                        {months.map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  data-test='date-pick-input'
                  selected={inputValue ? new Date(inputValue) : null}
                  onChange={date => {
                    setInputValue(date[0])
                    onChange && date && onChange(name, date)
                  }}
                  startDate={startDate ? new Date(startDate) : null}
                  endDate={endDate ? new Date(endDate) : null}
                  selectsStart={selectsStart}
                  minDate={minDate}
                  maxDate={maxDate || maxYear}
                  selectsEnd={selectsEnd}
                  calendarClassName={'calendar-wrapper-classname'}
                  onBlur={e => {
                    timeoutId = setTimeout(() => {
                      if (document.activeElement !== inputRef.current) {
                        setIsFocused(false)
                        setFocus && !blockBlur && setFocus(false)
                        onBlur && onBlur(e)
                      }
                    }, 200)
                  }}
                  {...rest}
                  customInput={
                    <input
                      className={cn('core-input-field', { errored: !!error })}
                      onChange={e => {
                        setInputValue(e.target.value)
                        onChange && onChange(name, e.target.value, e)
                      }}
                      value={inputValue as string}
                      onFocus={() => {
                        if (document.activeElement === inputRef.current) {
                          setIsFocused(true)
                          focusedInput && focusedInput(name)
                          setFocus && setFocus(true)
                        }
                      }}
                      type={'text'}
                      disabled={tempDisable || disabled}
                      ref={inputRef}
                      required={required}
                      name={name}
                      onKeyDown={onKeyDown}
                      autoComplete='off'
                      {...rest}
                    />
                  }
                />
              )}
            </div>
          ) : (
            <input
              data-test='base-input'
              className={cn('core-input-field', { errored: !!error })}
              onChange={e => {
                if (isFocused || enableAutofill) {
                  setInputValue(e.target.value)
                  onChange && onChange(name, e.target.value, e)
                }
              }}
              value={inputValue || ''}
              onFocus={() => {
                if (document.activeElement === inputRef.current) {
                  setIsFocused(true)
                  focusedInput && focusedInput(name)
                  setFocus && setFocus(true)
                }
              }}
              onBlur={e => {
                timeoutId = setTimeout(() => {
                  if (document.activeElement !== inputRef.current) {
                    setIsFocused(false)
                    !blockBlur && setFocus && setFocus(false)
                    onBlur && onBlur(e)
                  }
                }, 200)
              }}
              type={inputType}
              disabled={tempDisable || disabled}
              ref={inputRef}
              required={required}
              name={name}
              onKeyDown={onKeyDown}
              placeholder={isFocused ? placeholder : ''}
              autoComplete={autoComplete}
              {...rest}
            />
          )}
          <span
            className={cn('core-input-moving-label', `${!isFocused && !inputValue ? 'initial-state' : 'final-state'}`)}>
            {label}
          </span>
        </div>
        <div className='core-input-icons-section'>
          {clearable && isFocused && inputValue && (
            <ClearIcon
              onClick={() => {
                setInputValue('')
                inputRef.current && inputRef.current.focus()
                onChange && onChange(name, '')
              }}
            />
          )}
          {RightIcon ||
            (type == 'password' ? (
              inputType == 'password' ? (
                <OpenedEyeIcon
                  onClick={() => {
                    setInputType('text')
                    inputRef && inputRef.current.focus()
                  }}
                />
              ) : (
                <ClosedEyeIcon
                  onClick={() => {
                    setInputType('password')
                    inputRef && inputRef.current.focus()
                  }}
                />
              )
            ) : (
              <></>
            ))}
        </div>
      </div>
      {error && (
        <p data-test='core-input-error' className='core-input-error caption mt-1 pl-2'>
          {error}
        </p>
      )}
      {hint && !error && (
        <p data-test='core-input-hint' className='caption-medium core-input-hint mt-1 pl-2'>
          {hint}
        </p>
      )}
    </div>
  )
}
