import { FC, useEffect } from 'react'
import toast from 'react-hot-toast'

import { ErrorDisplay } from 'mmfintech-portal-commons'
import { useProcessReportMutation } from 'mmfintech-backend-api'
import { ReportActionEnum } from 'mmfintech-commons-types'
import { tr, useFormValues } from 'mmfintech-commons'

import { ReportActionModalProps } from '../@types'

import { CoreButton, CoreModalDialogFooter } from '@components'

import './rejectModal.scss'

export const RejectModal: FC<ReportActionModalProps> = ({ handleSubmit }) => {
  const [_, { error, isLoading, reset }] = useProcessReportMutation()
  const { areValid, prepare, setValue, getValue, getError } = useFormValues({
    action: { value: ReportActionEnum.REJECT },
    comment: { required: true, validation: 'safe-string' }
  })

  const onConfirm = async () => {
    if (areValid()) {
      try {
        await handleSubmit(prepare())
        toast.success(
          tr('FRONTEND.REPORTS.ACTIONS.REJECT_REPORT_SUCCESS', 'The report has been rejected successfully'),
          { position: 'top-center', style: { marginTop: '-50px' } }
        )
      } catch (error) {}
    }
  }

  useEffect(() => {
    return () => {
      reset()
    }
  }, [])

  return (
    <div className='return-reject-modal-wrapper'>
      <div className='return-reject-modal-inner'>
        <div className='confirmation'>
          {tr('FRONTEND.REPORTS.ACTIONS.REJECT_REPORT_DESCRIPTION', 'Please specify a reason for reject.')}
        </div>
        <textarea
          maxLength={255}
          placeholder={tr('FRONTEND.REPORTS.ACTIONS.REJECT_REPORT_LABEL_DESCRIPTION', 'Description.')}
          rows={4}
          onChange={e => setValue('comment', e.target.value)}
          value={getValue('comment')}
        />
      </div>
      <ErrorDisplay error={[error || getError('comment')]} />

      <CoreModalDialogFooter>
        <CoreButton
          text={tr('FRONTEND.BUTTONS.CONFIRM', 'Confirm')}
          onClick={onConfirm}
          data-test='reject-report-button'
          isLoading={isLoading}
          size='large'
          fullWidth
        />
      </CoreModalDialogFooter>
    </div>
  )
}
