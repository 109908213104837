import { useEffect, useState } from 'react'

import cn from 'classnames'
import { isValidArray, tr } from 'mmfintech-commons'

import { BatchWithdrawalRowDTO, ValidationCause } from 'mmfintech-commons-types'

import ArrowDownIcon from '@images/icons/arrow-down.svg?react'
import WarningIcon from '@images/icons/warning-batch.svg?react'

import './uploadErrorRow.scss'

interface UploadErrorRowProps {
  row: BatchWithdrawalRowDTO
  rowNum: number
}

export const UploadErrorRow = ({ row, rowNum }: UploadErrorRowProps) => {
  const [errors, setErrors] = useState<ValidationCause[]>()
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    const { rowErrors, previewErrors } = row
    setErrors([...(isValidArray(rowErrors) ? rowErrors : []), ...(isValidArray(previewErrors) ? previewErrors : [])])
  }, [row])

  return (
    <div className='upload-error-wrapper'>
      <div className='vertically-centered'>
        <WarningIcon />
      </div>
      <div className='upload-error-content'>
        <div className='rows'>{tr('FRONTEND.BATCH_PAYMENTS.UPLOAD_ERRORS.ROW', 'Row {{ROW}}', { ROW: rowNum })}</div>
        <div className='columns-wrapper'>
          <div className='columns'>
            {errors
              ?.filter((_, index) => expanded || index < 3)
              .map((error: ValidationCause) => {
                return (
                  <div key={`err-${rowNum}-${error.field}`}>
                    <b>{error.field}</b> {error.cause}
                  </div>
                )
              })}
          </div>
          {errors?.length > 3 && (
            <ArrowDownIcon
              className={cn({ expanded })}
              onClick={() => {
                setExpanded(prevState => !prevState)
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
