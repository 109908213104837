import { useContext, useEffect, useState } from 'react'

import { endpoints, request, useMarkNewsAsSeenMutation } from 'mmfintech-backend-api'

import { GlobalContext } from 'mmfintech-commons'
import { ErrorDisplay, Spinner } from 'mmfintech-portal-commons'
import { NewsImage, NewsModalWrapper } from './NewsModal.styled'
import { NewsButtonsContainer, NewsContentSection } from './elements'

import { PortalNewsResponse } from 'mmfintech-commons-types'

import NewsFallbackIcon from '@images/icons/news-fallback-icon.svg?react'

export const NewsModal = ({ news }) => {
  const { modalHide } = useContext(GlobalContext)

  const [seen, setSeen] = useState(new Set<number>())
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const [loading, setLoading] = useState(true)
  const [currentImage, setCurrentImage] = useState('')

  const [markAsSeen, { error }] = useMarkNewsAsSeenMutation()

  const markNewsAsSeen = async (newsId: number) => {
    if (!seen.has(newsId)) {
      try {
        await markAsSeen({ newsId }).unwrap()
        setSeen(prev => new Set(prev).add(newsId))
      } catch (_err) {
        // nothing
      }
    }
  }

  const handleTransition = (index: number): void => {
    if (index >= 0 && index < news.length) {
      void markNewsAsSeen(news[index].id)
      setCurrentIndex(index)
    } else if (index === news.length) {
      modalHide()
    }
  }

  useEffect(() => {
    // Mark the first news as seen when the component is mounted
    if (news.length > 0) {
      void markNewsAsSeen(news[0].id)
    }
    // Mark the current news as seen when unmounting
    return () => {
      void markNewsAsSeen(news[currentIndex].id)
    }
  }, [news, currentIndex])

  useEffect(() => {
    const data: PortalNewsResponse = news?.[currentIndex]
    const { id: newsId } = data || {}

    let didCancel = false

    const createObjectURL = async () => {
      try {
        if (!newsId) return
        const response = await request({
          endpoint: endpoints.news.downloadImage(newsId),
          method: 'GET',
          responseType: 'blob'
        })
        if (response.data) {
          const imageUrl = URL.createObjectURL(response.data)
          if (!didCancel) {
            setCurrentImage(imageUrl)
            onImageLoad()
          }
        }
      } catch (error) {
        console.error('Error creating object URL:', error)
      }
    }

    void createObjectURL()

    return () => {
      didCancel = true
      if (currentImage) {
        URL.revokeObjectURL(currentImage)
      }
    }
  }, [currentIndex])

  const onImageLoad = () => {
    setLoading(false)
  }

  return (
    <NewsModalWrapper>
      {news?.[currentIndex].imagePath ? (
        loading ? (
          <Spinner />
        ) : (
          <NewsImage image={currentImage} />
        )
      ) : (
        <NewsFallbackIcon className='fallback-icon' />
      )}

      <NewsContentSection
        title={news?.[currentIndex].title}
        content={news?.[currentIndex].content}
        availableNews={news}
        activeNewsIndex={currentIndex}
        handleTransition={handleTransition}
      />

      <ErrorDisplay error={error} />

      <NewsButtonsContainer
        activeNewsIndex={currentIndex}
        availableNewsLength={news?.length}
        handleTransition={handleTransition}
      />
    </NewsModalWrapper>
  )
}
