import { GlobalContext, tr } from 'mmfintech-commons'

import { CoreButton } from '@components'

import './CookiePolicy.scss'

import CookieIcon from '@images/icons/cookies-icon.svg?react'
import settings from '@settings'
import { configuration } from 'mmfintech-backend-api'
import { useContext } from 'react'
import { ManageCookies } from '../ManageCookies'

export const CookiePolicy = ({ setCookie }) => {
  const { modalHide, modalShow } = useContext(GlobalContext)

  const handleManageCookieConsent = () => {
    modalShow({
      options: { size: 'medium', hideCloseIcon: true, closeOnClickOutside: false },
      content: <ManageCookies setCookie={setCookie} />
    })
  }

  return (
    <div className='cookies-policy-wrapper'>
      <div className='cookies-policy-content'>
        <div className='cookies-policy-icon'>
          <CookieIcon />
        </div>
        <div className='cookies-policy-text'>
          <h5>{tr('FRONTEND.COOKIE_POLICY.TITLE', 'Cookies Policy')}</h5>
          <p>
            {tr(
              'FRONTEND.COOKIE_POLICY.DESCRIPTION',
              'We use cookies to personalize your experience on Kingdom Bank portal. They are all about to personalize content and ads, provide social media features and to analyze our traffic.'
            )}
          </p>
        </div>
      </div>
      <div className='cookies-policy-actions'>
        <CoreButton
          fullWidth
          size='large'
          variation='primary'
          text={tr('FRONTEND.COOKIE_POLICY.ACCEPT', 'Accept all')}
          onClick={() => {
            setCookie(
              'cookie.consent',
              JSON.stringify({
                necessaryCookies: true,
                optionalCookies: true
              }),
              {
                days: 365,
                maxAge: 31536000,
                domain: configuration.isLocal() ? null : settings.cookieDomain
              }
            )

            modalHide()
          }}
        />
        <CoreButton
          size='large'
          variation='tertiary'
          text={tr('FRONTEND.COOKIE_POLICY.MANAGE', 'Manage')}
          onClick={handleManageCookieConsent}
        />
      </div>
    </div>
  )
}
