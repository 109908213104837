import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { ErrorDisplay } from 'mmfintech-portal-commons'
import { AccessDenied, CoreButton, CoreSelect } from '@components'

import { tr, isValidObject, getCurrencySymbol } from 'mmfintech-commons'
import {
  isOwnerOrAdministrator,
  paths,
  selectCurrentUserRole,
  useAppSelector,
  useBankAccountFees,
  useGetMerchantQuery,
  useIbanRequestPreview
} from 'mmfintech-backend-api'
import './IbanIssuingPreview.scss'

export const IbanRequestFeePayment = ({ feeDetails, onSuccess }: { feeDetails: any; onSuccess: () => void }) => {
  const { data: merchant } = useGetMerchantQuery(null)
  const customerRole = useAppSelector(selectCurrentUserRole)

  const { capabilities } = merchant || {}
  const { ibanEligible } = capabilities || {}
  const { currency, scheme } = feeDetails || {}

  if (!isOwnerOrAdministrator(customerRole) || !ibanEligible) {
    return <AccessDenied />
  }

  const history = useHistory()

  const {
    accounts,
    accountsError,
    accountsFetching,
    selectedAccount,
    setSelectedAccount,
    previewResponse,
    handleInitiate,
    initiateError,
    initiateFetching,
    // initiateResponse,
    resetMutations
  } = useIbanRequestPreview({
    payload: feeDetails,
    onSuccess: () => {
      onSuccess()
      // history.push(paths.banking.accounts.list())
      // toast.remove()
      // toast.success(
      //   'Your IBAN issuing request has been accepted. ' +
      //     'You will receive confirmation once IBAN has been approved and issued.'
      // )
    }
  })

  const { fees } = useBankAccountFees(currency, scheme)

  const { feeCurrency } = previewResponse || {}

  if (!feeDetails.scheme || !feeDetails.currency || !feeCurrency) {
    history.push(paths.banking.accounts.list())
    return null
  }

  useEffect(() => {
    return () => {
      resetMutations()
    }
  }, [])

  return (
    <div className='iban-issuing-preview-request-wrapper'>
      <div className='iban-issuing-preview-request'>
        <span className='iban-preview-title' data-test='iban-preview-title'>
          {tr('FRONTEND.IBAN_REQUEST.PREVIEW_FEE.TITLE', 'Payment details')}
        </span>
        <div className='iban-preview-amount-wrapper'>
          <div className='iban-preview-amount'>
            {fees.map(fee => {
              const { feeType, feeCurrency, value } = fee || {}
              return (
                <div className='iban-preview-container'>
                  <span className='iban-preview-amount-title'>
                    {tr(
                      `FRONTEND.IBAN_ISSUING.${feeType}`,
                      feeType === 'IBAN_ISSUING_FEE' ? 'Issuing fee:' : 'Monthly maintenance fee:'
                    )}
                  </span>
                  <span className='iban-preview-amount-content'>{`${getCurrencySymbol(feeCurrency)} ${value}`}</span>
                </div>
              )
            })}
          </div>
          <div className='iban-preview-divider'></div>
          <div className='iban-preview-amount-total'>
            <span className='iban-preview-total-amount-title'>
              {tr('FRONTEND.IBAN_ISSUING.TOTAL_AMOUNT', 'Total: ')}
            </span>
            <span className='iban-preview-total-amount-content'>{`${getCurrencySymbol(feeCurrency)} ${fees.reduce(
              (acc, curr) => acc + curr.value,
              0
            )}`}</span>
          </div>
        </div>
        <span className='iban-preview-title'>{tr('FRONTEND.IBAN.PREVIEW.DETAILS.TITLE', 'Deduct fee from:')}</span>

        <CoreSelect
          type='account'
          label={tr('FRONTEND.SEND_OWN_ACCOUNTS.TO_ACCOUNT', 'Account')}
          options={accounts}
          isAccountsLoading={accountsFetching}
          allAccounts
          onChange={(_, v) => {
            setSelectedAccount(v)
          }}
          value={selectedAccount}
        />

        <ErrorDisplay error={[accountsError, initiateError]} />
        <div className='mt-3 divider' />

        <div className='iban-issuing-button-wrapper'>
          <CoreButton
            size='large'
            variation='primary'
            fullWidth
            text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
            isLoading={initiateFetching}
            onClick={handleInitiate}
            className='mb-1'
            data-test='button-deposit'
            disabled={!isValidObject(previewResponse)}
          />
          <CoreButton
            size='large'
            variation='tertiary'
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            disabled={initiateFetching}
            onClick={() => history.goBack()}
            data-test='button-cancel'
          />
        </div>
      </div>
    </div>
  )
}
