import styled from 'styled-components'

export const ModalWrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(96, 96, 96, 0.5);

  outline: 0;
  overflow: hidden;
  z-index: 5000;

  display: flex;
  align-items: center;
  justify-content: center;

  .modal-footer {
    .button {
      max-width: 15rem;
      &.primary {
        margin-left: 2rem;
      }
    }
  }

  .title {
    color: #000000;
  }
`

export const ModalContainer = styled.div<{ custom?: number }>`
  margin: 0;
  padding: 0;

  height: auto;
  max-height: 95vh;
  /* overflow-y: auto; */

  max-width: 95vw;
  width: 60rem;

  position: relative;

  &.size-custom {
    width: ${({ custom }) => (custom ? `${custom}rem` : 'auto')};
  }

  &.size-extra-large {
    width: 120rem;
  }
  &.size-large {
    width: 90rem;
  }
  &.size-small {
    width: 30rem;
  }
  &.size-auto {
    width: auto;
  }

  &.horizontal {
    display: flex;
    justify-content: center;
  }
  &.centered {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    box-shadow: none;
    //overflow-y: hidden;

    .scrollable {
      height: 100%;
      overflow-y: auto;
    }
  }

  color: #000000;
  font-family: inherit;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: normal;
  line-height: normal;

  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  &.overflow-none {
    overflow-y: hidden;
    overflow-x: hidden;
  }
  &.overflow-visible {
    overflow: visible;
  }

  &.transparent {
    background: transparent;
    border-radius: 0;
    box-shadow: none;
  }

  .close-icon {
    position: absolute;
    cursor: pointer;
    top: 2rem;
    right: 2rem;
    z-index: 5;
  }
`
