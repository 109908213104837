import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  actions,
  selectCurrentUserId,
  selectCurrentUserRole,
  useAppSelector,
  useGetHintsQuery,
  useInviteUserMutation,
  useLazyGetMerchantUserAccessQuery,
  useLazyGetMyPermissionsQuery,
  useUpdateUserMutation
} from 'mmfintech-backend-api'

import { fixFloat, useFormValues } from 'mmfintech-commons'
import { CustomerRoleEnum } from 'mmfintech-commons-types'

// const READ_PERMISSION = 'READER'
const WRITE_PERMISSION = 'WRITER'

export const useAddUser = ({ handleReload }: { handleReload: (userId?: number) => void }) => {
  const [inviteCustomer, { error: inviteUserError }] = useInviteUserMutation()
  const [updateCustomer, { error: updateUserError }] = useUpdateUserMutation()
  const formValues = useFormValues({
    firstName: { required: true, validation: 'name' },
    lastName: { required: true, validation: 'name' },
    email: { required: true, validation: 'email' },
    customerRole: { required: true }
  })

  const handleCreate = () => {
    inviteCustomer({ ...formValues.prepare(), monthlyVolume: fixFloat(formValues.getValue('monthlyVolume')) })
      .unwrap()
      .then(() => handleReload())
  }

  const handleEdit = (userId: number) => {
    updateCustomer({ userId, ...formValues.prepare(), monthlyVolume: fixFloat(formValues.getValue('monthlyVolume')) })
      .unwrap()
      .then(() => handleReload(userId))
  }

  return {
    formValues,
    handleCreate,
    handleEdit,
    error: inviteUserError || updateUserError
  }
}

export const useUserPermissions = (props?: { customerId?: number }) => {
  const userId = useAppSelector(selectCurrentUserId)
  const { customerId } = props || {}
  const [
    getMerchantUserAccess,
    {
      isFetching: permissionsInvitedCustomersFetching,
      error: permissionsInvitedCustomersError,
      data: permissionsInvitedCustomer
    }
  ] = useLazyGetMerchantUserAccessQuery()

  const [getMyPermissions, { isFetching: permissionsIsFetching, error: permissionsError, data: myPermissions }] =
    useLazyGetMyPermissionsQuery()

  useEffect(() => {
    if (!permissionsInvitedCustomersFetching) {
      if (customerId) {
        getMerchantUserAccess({ userId: customerId }).unwrap()
      } else if (userId) {
        // getMyPermissions
        getMyPermissions(null).unwrap()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, userId])

  return {
    isLoading: customerId ? permissionsInvitedCustomersFetching : permissionsIsFetching,
    error: customerId ? permissionsInvitedCustomersError : permissionsError,
    permissions: customerId ? permissionsInvitedCustomer : myPermissions
  }
}

export const useGetHints = () => {
  const dispatch = useDispatch()
  const { isFetching: getHintsFetching, data: hints, error: getHintsError } = useGetHintsQuery(null)

  if (!hints && !getHintsFetching && !getHintsError) {
    dispatch(actions.merchant.getUserHints())
  }

  return {
    isLoading: getHintsFetching,
    error: getHintsError,
    hints
  }
}

export const useHasWritePermissions = (accountId?: string) => {
  const { isLoading, error, permissions } = useUserPermissions()
  const customerRole = useAppSelector(selectCurrentUserRole)

  if (customerRole !== CustomerRoleEnum.STAFF) return true
  if (isLoading || error) return false

  if (accountId) {
    const permission = permissions?.find(
      (perm: any) => perm.accountId === accountId && perm.accessLevel === WRITE_PERMISSION
    )
    return !!permission
  }

  const permission = permissions?.find((perm: any) => perm.accessLevel === WRITE_PERMISSION)
  return !!permission
}
