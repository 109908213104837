import { useEffect, useState } from 'react'
import cn from 'classnames'

import { WithdrawalSubFlowEnum } from 'mmfintech-commons-types'
import { tr } from 'mmfintech-commons'

import { PaymentMethods } from '@components'
import { useMatchMedia } from '@hooks'

import ArrowIcon from '@images/icons/arrow-down.svg?react'
import DownloadFile from '@images/icons/downloadBatchInstructions.svg?react'

export const SelectType = ({ type, setType, isProspect, loading, currentStep }) => {
  const [paymentOptions, setPaymentOptions] = useState([])
  const paymentOptionsCollapseEnable = useMatchMedia({ breakpoint: 1280, prefix: 'max' })
  const [collapsePaymentOption, setCollapsePaymentOption] = useState<boolean>(false)
  const [instructionsOpened, setInstructionsOpened] = useState<boolean>(true)

  useEffect(() => {
    if (type && paymentOptionsCollapseEnable) {
      return setCollapsePaymentOption(false)
    }
    if (!type && paymentOptionsCollapseEnable) {
      setCollapsePaymentOption(true)
    }
  }, [type, paymentOptionsCollapseEnable])

  useEffect(() => {
    setPaymentOptions([
      {
        paymentMethod: WithdrawalSubFlowEnum.BANKWIRE,
        title: 'Bank Transfer',
        isDisabled: isProspect
      },
      {
        paymentMethod: WithdrawalSubFlowEnum.CRYPTO,
        title: 'Crypto'
      },
      {
        paymentMethod: WithdrawalSubFlowEnum.INTERNAL_TRANSFER,
        title: 'Internal Transfer',
        isDisabled: isProspect
      }
    ])
  }, [isProspect])

  return (
    <>
      {currentStep === 1 && (
        <div className='select-type-wrapper'>
          <div className='send-navigation-wrapper'>
            <div className={cn('methods-container', { collapsed: collapsePaymentOption })}>
              <span className='navigation-title'>
                {tr('FRONTEND.BANKING.SEND_MONEY.PAYMENT_METHODS_LABEL_SELECT', 'Select payment method')}
              </span>
              {paymentOptionsCollapseEnable && (
                <span className='select-method-arrow' onClick={() => setCollapsePaymentOption(val => !val)}>
                  <ArrowIcon />
                </span>
              )}
            </div>
            <div className='send-navigation-container'>
              <PaymentMethods
                paymentOptions={paymentOptions}
                setSelectedPaymentOption={setType}
                selectedPaymentOption={{ paymentMethod: type }}
                loading={loading}
                disabled={loading}
                collapse={collapsePaymentOption}
                setCollapse={setCollapsePaymentOption}
                ignoreStatus={true}
              />
            </div>
          </div>
          <div className={cn('instruction-accordion', { 'instructions-opened': instructionsOpened })}>
            <div className='instruction-accordion-header'>
              <div className='instructions-title'>
                {tr('FRONTEND.BATCH_PAYMENTS.INSTRUCTIONS.TITLE', 'Instructions')}
              </div>
              <div className='instructions-chevron' onClick={() => setInstructionsOpened(val => !val)}>
                <ArrowIcon />
              </div>
            </div>
            <div className='instruction-accordion-content'>
              <ul>
                <li>
                  {tr(
                    'FRONTEND.BATCH_PAYMENTS.INSTRUCTIONS.POINT_1',
                    'For Crypto or SEPA/SWIFT batch payments download, fill in, and upload the respective CSV template'
                  )}
                </li>
                <li>
                  {tr(
                    'FRONTEND.BATCH_PAYMENTS.INSTRUCTIONS.POINT_2',
                    'For all transactions the fields amount, currency and transfer type are mandatory'
                  )}
                </li>
              </ul>
              <div className='instruction-accordion-inner-title'>
                {tr('FRONTEND.BATCH_PAYMENTS.INSTRUCTIONS.INNER_TITLE', 'Additional mandatory fields:')}
              </div>

              <ul>
                <li>{tr('FRONTEND.BATCH_PAYMENTS.INSTRUCTIONS.POINT_3', 'For Crypto the field wallet address')}</li>
                <li>
                  {tr(
                    'FRONTEND.BATCH_PAYMENTS.INSTRUCTIONS.POINT_4',
                    'For SEPA/SWIFT transfers the fields IBAN or account number, bank code, and country.'
                  )}
                </li>
                <li>
                  {tr(
                    'FRONTEND.BATCH_PAYMENTS.INSTRUCTIONS.POINT_5',
                    'If there are missing or wrong fields, they will be highlighted in the preview section.'
                  )}
                </li>
              </ul>
            </div>

            <a className='download-batch-payment-instructions' href='/files/TKB-Batch-Payment-Fields.xls'>
              <div>
                <DownloadFile />
                <span>{tr('FRONTEND.BATCH_PAYMENTS.BATCH_FILE_INSTRUCTIONS', 'Batch File Instructions')}</span>
              </div>
            </a>
          </div>
        </div>
      )}
    </>
  )
}
