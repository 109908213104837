import { useHistory } from 'react-router-dom'

import { paths } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'

import { CoreButton } from '@components'

import KingdomBankBlackLogo from '@images/kingdom-bank-icon-black.svg?react'

import './error404.scss'

export const Error404 = () => {
  const history = useHistory()

  return (
    <div className='error-page-wrapper'>
      <div className='error-page-container'>
        <div className='error-page-left-content'>
          <KingdomBankBlackLogo className='logo' />

          <div className='info-text-container'>
            <div className='info-text'>
              <div className='error-code'>404</div>
              <div className='title'>{tr('PORTAL.ERROR404.MESSAGE', 'Page not found')}</div>
            </div>

            <CoreButton
              type='button'
              onClick={() => history.push(paths.dashboard())}
              text={tr('PORTAL.ERROR404.BACK_BUTTON_2', 'Go back to home')}
              data-test='back-button'
              size='large'
            />
          </div>
        </div>
        <div className='error-page-right-content'>
          <div className='error-page-right-image' />
        </div>
      </div>
    </div>
  )
}
