import React from 'react'
import cn from 'classnames'

import './coreRadioButton.scss'

interface IButtonProps {
  fontStyle?: React.CSSProperties
  label: string
  value: string | boolean
  disabled?: boolean
  dataTest?: string
}

interface ICustomRadioButton {
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  gap?: number | string
  type?: 'circle' | 'checkbox'
  radioGroupe: string
  buttons: IButtonProps[]
  defaultValue?: string
  value?: string | boolean
  className?: string
  onClick: (checked: string | boolean) => void
  checked?: any
}

interface singleRadioButton extends IButtonProps {
  radioGroupe?: string
  defaultChecked?: boolean
  onClick: (checked: string | boolean) => void
  type?: 'circle' | 'checkbox'
  checked?: any
}

const SingeRadioButton = ({
  onClick,
  radioGroupe,
  label,
  value,
  fontStyle,
  disabled,
  defaultChecked,
  type,
  checked,
  ...props
}: singleRadioButton) => {
  const handleClick = event => {
    event.stopPropagation()
    switch (event.target.value) {
      case 'true':
        onClick && onClick(true)
        return
      case 'false':
        onClick && onClick(false)
        return
      default:
        onClick && onClick(event.target.value)
        return
    }
  }
  return (
    <div>
      <input
        {...props}
        data-test={`radio-button-${label}`}
        disabled={disabled}
        value={value.toString()}
        onChange={handleClick}
        type='radio'
        name={radioGroupe}
        checked={checked}
        defaultChecked={defaultChecked}
        id={`${label}-${radioGroupe}`}
      />
      <label htmlFor={`${label}-${radioGroupe}`}>
        <span data-test={`radio-button-clickable-${label}`} className={cn('radio-button', {})}></span>
        <p data-test={`radio-button-label-${label}`} style={{ ...fontStyle }}>
          {label}
        </p>
      </label>
    </div>
  )
}
const RadioButton = ({
  flexDirection = 'column',
  gap = 0,
  onClick,
  radioGroupe,
  buttons,
  defaultValue,
  className,
  type = 'circle',
  checked,
  ...props
}: ICustomRadioButton) => {
  return (
    <div
      data-test={`radio-group-${radioGroupe}`}
      className={cn(`${className} custom-radio-button`, {
        'checkbox-radio': type === 'checkbox',
        'circle-radio': type === 'circle'
      })}
      style={{ flexDirection: flexDirection, gap: gap, width: '100%' }}>
      {buttons.map((button: IButtonProps) => {
        return (
          <SingeRadioButton
            value={button.value !== undefined ? button.value : button.label}
            defaultChecked={button.value === defaultValue || button.label === defaultValue}
            fontStyle={button.fontStyle}
            radioGroupe={radioGroupe}
            disabled={button.disabled}
            label={button.label}
            checked={button.value === checked || button.label === checked}
            key={button.label}
            onClick={onClick}
            type={type}
            {...props}
          />
        )
      })}
    </div>
  )
}

export const CoreRadioButton = React.memo(RadioButton)
