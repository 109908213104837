import { useHistory, useLocation } from 'react-router-dom'
import cn from 'classnames'

import ArrowIcon from '@images/icons/breadcrumb-arrow.svg?react'

import './breadcrumb.scss'

type BreadcrumbProps = {
  breadcrumbItems: { label: string; path: (id?: string) => string }[]
}

export const Breadcrumb = ({ breadcrumbItems }: BreadcrumbProps) => {
  const history = useHistory()
  const location = useLocation()

  const parts = location?.pathname?.split('/') // Split the string by '/'
  const param = parts[parts?.length - 1] || ''

  const handleClick = (path, param) => {
    if (path(param) === 'back') {
      history.goBack()
    } else {
      history.push(path(param))
    }
  }

  return (
    <div className='breadcrumb'>
      {breadcrumbItems.map(({ label, path }) => {
        return (
          <div className='breadcrumb-item'>
            <span
              className={`label ${cn({ active: path(param) === location.pathname })}`}
              onClick={() => handleClick(path, param)}>
              {label}
            </span>
            <div className='icon'>
              <ArrowIcon />
            </div>
          </div>
        )
      })}
    </div>
  )
}
