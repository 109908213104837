import { useEffect, useRef, useState } from 'react'

import cn from 'classnames'

import './coreTabs.scss'

interface ITab {
  label: string
  value: string
}

interface ICoreTabs {
  onChangeTab: (value: any) => void
  tabs: ITab[]
  tabState?: string
  disabled?: boolean
  initial?: string
}

export const CoreTabs = ({ tabs, onChangeTab, tabState, disabled = false, initial }: ICoreTabs) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(null)
  const [backgroundLeftSide, setUnderlineLeft] = useState(0)
  const [backgroundWidth, setBackgroundWidth] = useState(0)
  const [isHidden, setIsHidden] = useState(true)
  const selectedRef = useRef<HTMLInputElement>(null)
  const initialRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setSelectedTabIndex(() => {
      setIsHidden(false)
      return tabs.indexOf(tabs.find((tab: ITab) => tab.value === tabState))
    })
    if (selectedRef.current) {
      setBackgroundWidth(selectedRef.current.offsetWidth)
      setUnderlineLeft(selectedRef.current.offsetLeft + Math.round(selectedRef.current.offsetWidth / 2))
      setIsHidden(false)
    }
  }, [tabState, selectedTabIndex])

  useEffect(() => {
    if (initialRef.current) {
      setBackgroundWidth(initialRef.current.offsetWidth)
      setUnderlineLeft(initialRef.current.offsetLeft + Math.round(initialRef.current.offsetWidth / 2))
      setIsHidden(false)
    }
  }, [initial])

  const handleTabClick = e => {
    setUnderlineLeft(e.target.offsetLeft + Math.round(e.target.offsetWidth / 2))
    setBackgroundWidth(e.target.offsetWidth)
  }

  return (
    <div data-test='core-tabs-wrapper' className={cn('core-tabs-wrapper', { disabled: disabled })}>
      {tabs.map((tab, index) => {
        return (
          <div
            key={index}
            data-test={`tab-item-${index}-test`}
            className={`tab-item ${selectedTabIndex === index ? 'selected' : ''}`}
            ref={tab.value === initial ? initialRef : tab.value === tabState ? selectedRef : null}
            onClick={e => {
              if (!disabled) {
                handleTabClick(e)
                setSelectedTabIndex(index)
                onChangeTab(tab.value)
              }
            }}>
            <p data-test={`tab-item-label-${tab.label}-test`}>{tab.label}</p>
          </div>
        )
      })}
      <div
        data-test={`animated-background-test`}
        className='animated-background'
        style={{
          display: isHidden ? 'none' : 'flex',
          width: `${backgroundWidth}px`,
          left: `${backgroundLeftSide}px`
        }}
      />
    </div>
  )
}
