import { useHistory } from 'react-router-dom'

import { tr } from 'mmfintech-commons'
import { paths } from 'mmfintech-backend-api'

import { CoreButton } from '@components'

import './accessDenied.scss'

import AccessDeniedIcon from '@images/icons/access-denied.svg?react'

export const AccessDenied = () => {
  const history = useHistory()

  return (
    <div className='access-denied-container'>
      <div className='text-container'>
        <AccessDeniedIcon />
        <h1 className='title'>{tr('FRONTEND.ACCESS_DENIED.TITLE', 'Access denied')}</h1>
        <h2 className='headline'>
          {tr(
            'FRONTEND.ACCESS_DENIED.DESCRIPTION',
            'Sorry, but it seems that you cannot access this area because your account does not have the right to view this page.'
          )}
        </h2>
      </div>

      <div className='footer'>
        <CoreButton
          type='button'
          className='button-back'
          text={tr('FRONTEND.ACCESS_DENIED.BACK_BUTTON', 'Back to overview')}
          onClick={() => history.push(paths.dashboard())}
          data-test='button-back'
          size='large'
          fullWidth
        />
      </div>
    </div>
  )
}
