import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { paths, selectCurrentUserRole, useAppSelector } from 'mmfintech-backend-api'
import { CustomerRoleEnum } from 'mmfintech-commons-types'
import { tr } from 'mmfintech-commons'

import { CoreTabs } from '@components'

const SETTINGS_NAVIGATION = (customerRole: CustomerRoleEnum) => {
  switch (customerRole) {
    case CustomerRoleEnum.STAFF:
      return [
        {
          label: tr('FRONTEND.SECURITY.TITLE', 'Security'),
          value: 'SECURITY',
          path: paths.profile() + '/security'
        }
      ]
    case CustomerRoleEnum.SUBMITTER:
    case CustomerRoleEnum.APPROVER:
      return [
        {
          label: tr('FRONTEND.SECURITY.TITLE', 'Security'),
          value: 'SECURITY',
          path: paths.profile() + '/security'
        },
        {
          label: tr('FRONTEND.PROFILE.PROFILE_PAYMENT_TITLE', 'Payment'),
          value: 'PAYMENT',
          path: paths.profile() + '/payment'
        }
      ]

    case CustomerRoleEnum.ADMINISTRATOR:
    case CustomerRoleEnum.OWNER:
      return [
        {
          label: tr('FRONTEND.PROFILE.PROFILE_DETAILS_TITLE', 'Profile details'),
          value: 'DETAILS',
          path: paths.profile() + '/details'
        },
        {
          label: tr('FRONTEND.SECURITY.TITLE', 'Security'),
          value: 'SECURITY',
          path: paths.profile() + '/security'
        },
        {
          label: tr('FRONTEND.PROFILE.PROFILE_PAYMENT_TITLE', 'Payment'),
          value: 'PAYMENT',
          path: paths.profile() + '/payment'
        }
      ]

    default:
      break
  }
}

export const ProfileNavigation = () => {
  const history = useHistory()
  const { pathname, state } = history?.location
  const endPath = pathname.split('/')[2]
  const [activeTab, setActiveTab] = useState(endPath)
  const customerRole = useAppSelector(selectCurrentUserRole)

  useEffect(() => {
    if (endPath) {
      setActiveTab(endPath.toUpperCase())
    }
  }, [endPath])

  const onChangeTab = value => {
    setActiveTab(value)
    history.push(SETTINGS_NAVIGATION(customerRole).find(x => x.value === value).path, endPath.toUpperCase())
  }

  return (
    <div className='navigation-tabs'>
      <CoreTabs
        tabs={SETTINGS_NAVIGATION(customerRole)}
        onChangeTab={onChangeTab}
        tabState={activeTab}
        initial={state as string}
      />
    </div>
  )
}
