import { useEffect, useState } from 'react'
import { isValidArray, tr } from 'mmfintech-commons'
import { paths, useGetTransactionsQuery } from 'mmfintech-backend-api'
import { ErrorDisplay, Spinner } from 'mmfintech-portal-commons'
import { TransactionHistoryFilterRequest } from 'mmfintech-commons-types'

import RecentTransaction from './RecentTransaction'
import { NoTransactionsMessage, ViewAllButton } from '@components'
import NoTransactionsImage from '@images/icons/transactions-icon.png'

import './recentTransactions.scss'

interface IRecentTransactionsProps {
  filter?: TransactionHistoryFilterRequest
  title?: string
  showDepositMessage?: boolean
  merchantId?: number
}

export const RecentTransactions = ({ filter, title, showDepositMessage = true }: IRecentTransactionsProps) => {
  const [showButton, setShowButton] = useState<boolean>(false)
  const { accountId, counterpartyPaymentInstrumentId } = filter || {}

  const {
    data: transactionsResponse,
    error: transactionsError,
    isFetching: transactionsFetching
  } = useGetTransactionsQuery({ size: 3, sort: 'reqTime-desc,id-desc', ...filter })

  useEffect(() => {
    if (isValidArray(transactionsResponse?.content)) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }, [transactionsResponse])

  return (
    <div className='transaction-section-wrapper'>
      <div className='transaction-section-header'>
        <h3 data-test='transaction-section-title'>
          {title || tr('FRONTEND.DASHBOARD.TRANSACTIONS_TITLE', 'Recent transactions')}
        </h3>
        {showButton && (
          <ViewAllButton
            label={tr('FRONTEND.DASHBOARD.TRANSACTIONS.BUTTON.VIEW_ALL', 'View all')}
            redirect={paths.banking.transactions.list()}
            accountId={accountId}
            recipientId={counterpartyPaymentInstrumentId}
          />
        )}
      </div>
      <>
        {transactionsFetching ? (
          <Spinner />
        ) : transactionsError ? (
          <ErrorDisplay error={transactionsError} />
        ) : (
          <>
            {isValidArray(transactionsResponse?.content) ? (
              <div className='transaction-section-content'>
                {transactionsResponse.content.map(data => {
                  const {
                    id,
                    reqTime,
                    amount,
                    currency,
                    direction,
                    status,
                    trnTypeLocalizationKey,
                    transactionCategory
                  } = data

                  return (
                    <RecentTransaction
                      data-test={`recent-transaction-${id}`}
                      key={id}
                      amount={amount}
                      currency={currency}
                      direction={direction}
                      reqTime={reqTime}
                      status={status}
                      trnTypeLocalizationKey={trnTypeLocalizationKey}
                      transactionCategory={transactionCategory}
                    />
                  )
                })}
              </div>
            ) : (
              <NoTransactionsMessage
                iconLink={NoTransactionsImage}
                showDepositMessage={showDepositMessage}
                accountId={accountId}
              />
            )}
          </>
        )}
      </>
    </div>
  )
}
