import { FC } from 'react'
import toast from 'react-hot-toast'

import { ExpenseManagementReport } from 'mmfintech-commons-types'
import { useSubmitReportMutation } from 'mmfintech-backend-api'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { tr } from 'mmfintech-commons'

import { CoreButton, CoreModalDialogFooter } from '@components'

import SubmitIcon from '@images/expenses/submit-report-modal.svg?react'

import './submitReportModal.scss'

interface ISubmitReport {
  report: ExpenseManagementReport
  onClose: () => void
}

export const SubmitReportModal: FC<ISubmitReport> = ({ report, onClose }) => {
  const [submitReportRequest, { error, isLoading }] = useSubmitReportMutation()

  const onConfirm = async () => {
    try {
      await submitReportRequest({
        reportId: Number(report?.id)
      }).unwrap()
      toast.success(tr('FRONTEND.REPORTS.ACTIONS.SUBMIT_REPORT_SUCCESS', 'Report was submitted successfully'))
      onClose()
    } catch {}
  }

  return (
    <div className='submit-modal-wrapper'>
      <div className='confirmation'>
        <div className='icon'>
          <SubmitIcon />
        </div>
        <div className='description'>
          {tr('FRONTEND.REPORTS.ACTIONS.SUBMIT_REPORT_DESCRIPTION', 'Are you sure you want to submit report')}:{' '}
          <span className='report-name'>{report.name}</span>
        </div>
      </div>

      <div className='error'>
        <ErrorDisplay error={error} />
      </div>

      <CoreModalDialogFooter>
        <div className='buttons-container'>
          <CoreButton
            text={tr('FRONTEND.BUTTONS.SUBMIT', 'Submit')}
            onClick={onConfirm}
            data-test='submit-report-button'
            isLoading={isLoading}
            size='large'
            fullWidth
          />
          <CoreButton
            variation='tertiary'
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            onClick={onClose}
            data-test='close-delete-report-button'
            isLoading={isLoading}
            size='large'
          />
        </div>
      </CoreModalDialogFooter>
    </div>
  )
}
