import { tr } from 'mmfintech-commons'
import SingleVouchers from '@images/icons/single_voucher_icon.svg?react'
import MultiplyVouchers from '@images/icons/multiply_vouchers_icon.svg?react'

export const BULK_AMOUNTS = [5, 10, 15, 20, 30, 50, 75, 100, 150, 200]

export const PREPARED_AMOUNTS = [100, 250, 500, 1000]

export const VOUCHER_TYPES = [
  {
    label: tr('FRONTEND.SECURITY.VOUCHER_TYPE_SINGLE_VOUCHER', 'Single Voucher'),
    value: 'single',
    Icon: SingleVouchers
  },
  {
    label: tr('FRONTEND.SECURITY.VOUCHER_TYPE_MULTIPLE_VOUCHERS', 'Multiple Vouchers'),
    value: 'bulk',
    Icon: MultiplyVouchers
  }
]
