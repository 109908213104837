import styled from 'styled-components'

import { FiltersContainer } from '@views/transactions/TransactionsFilter/TransactionsFilter.styled'

const ReportFiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-areas: 'inputs buttons';
  align-items: center;
  margin: 32px 0px 24px 0px;

  &.expense-filter {
    .input-wrapper {
      /* max-width: 13rem !important; */
    }
  }
`

const Filters = styled(FiltersContainer)`
  grid-area: inputs;
  align-items: center;

  .currency {
    background: white;

    & > div {
      background: white;
    }

    div {
      color: black;
    }

    input {
      &::placeholder {
        color: red;
      }
    }

    svg {
      fill: #85a0ad;
    }
  }

  [data-test='text-input-search'],
  [data-test='text-input-name'],
  [data-test='text-input-invoiceNumber'] {
    max-width: 23rem;
  }
  [data-test='select-input-status'] {
    max-width: 20rem;
  }

  [data-test='date-input-from'],
  [data-test='date-input-to'],
  [data-test='date-input-dateOfPayment'],
  [data-test='date-input-dueDate'] {
    max-width: 13rem;

    & > div {
      padding-left: 1.5rem;
      cursor: pointer;
    }

    .react-datepicker-popper {
      z-index: 2;
    }
  }

  [data-test='text-input-toAmount'],
  [data-test='text-input-fromAmount'] {
    max-width: 10rem;
  }
`
const FilterControlsStyle = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: center;
  gap: 2rem;
  min-height: 60px;

  button {
    .applied-filters {
      width: 1.6rem;
      height: 1.6rem;

      border-radius: 50%;
      background-color: #fff;

      display: flex;
      justify-content: center;
      align-items: center;

      color: #000;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    &.filters-button-container {
      height: 36px;
      width: 130px;

      .filters-button {
        display: flex;
        align-items: center;
        color: #ffffff;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        gap: 0.625rem;
      }
    }

    &.toggle-filter {
      background: #85a0ad;

      &:hover,
      &:focus {
        background: #85a0ad;
        opacity: 0.9;
      }
    }
  }
`

export { ReportFiltersWrapper, Filters, FilterControlsStyle }
