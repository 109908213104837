import { FC, FormEvent, useEffect } from 'react'

import toast from 'react-hot-toast'

import { useCreateCounterpartyMutation, useUpdateCounterpartyMutation } from 'mmfintech-backend-api'
import { isValidObject, tr, useFormValues } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { Counterparty } from 'mmfintech-commons-types'

import { CoreButton, CoreInput, CoreModalDialogFooter } from '@components'

import './counterpartyCreateModal.scss'

interface CounterpartyCreateModalProps {
  onClose: () => void
  counterparty?: Counterparty
}

export const CounterpartyCreateModal: FC<CounterpartyCreateModalProps> = ({ onClose, counterparty }) => {
  const { registerInput, areValid, prepare, handleErrors, getValue } = useFormValues({
    name: { required: true, validation: 'safe-string', value: counterparty?.name, minLength: 4, maxLength: 100 }
  })
  const [createCounterpartyRequest, { isLoading: loadingCreate, reset: resetCreate, error: createError }] =
    useCreateCounterpartyMutation()
  const [updateCounterpartyRequest, { isLoading: loadingUpdate, reset: resetUpdate, error: updateError }] =
    useUpdateCounterpartyMutation()

  const isLoading = loadingCreate || loadingUpdate

  const handleReset = () => {
    resetCreate()
    resetUpdate()
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (areValid()) {
      try {
        const res = counterparty
          ? await updateCounterpartyRequest({ data: prepare(), id: Number(counterparty?.id) }).unwrap()
          : await createCounterpartyRequest(prepare()).unwrap()
        if (res) {
          toast.success(tr('FRONTEND.REPORTS.ACTIONS.COUNTERPARTY_CREATED', 'Counterparty successfully created'))

          onClose()
        }
      } catch (error) {
        handleErrors(error)
      }
    }
  }

  useEffect(() => {
    handleReset()
  }, [getValue('name')])

  useEffect(() => {
    return () => {
      handleReset()
    }
  }, [])

  return (
    <div className='counterparty-modal-wrapper'>
      <form onSubmit={handleSubmit}>
        <CoreInput type='text' label={tr('FRONTEND.COUNTERPARTY.COLUMN_NAME', 'Name')} {...registerInput('name')} />
        <ErrorDisplay error={updateError?.error || createError?.error} />
        <CoreModalDialogFooter>
          <CoreButton
            type='submit'
            data-test='create-update-counterpary'
            text={
              isValidObject(counterparty)
                ? tr('FRONTEND.COUNTERPARTY.ACTION.UPDATE', 'Update')
                : tr('FRONTEND.COUNTERPARTY.ACTION.CREATE', 'Create')
            }
            isLoading={isLoading}
            size='large'
            fullWidth
          />
        </CoreModalDialogFooter>
      </form>
    </div>
  )
}
