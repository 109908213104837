import { ReactNode } from 'react'

type TCoreModalDialogFooterProps = {
  children?: ReactNode
  className?: string
}
export const CoreModalDialogFooter = ({ children, className }: TCoreModalDialogFooterProps) => {
  return (
    <div className={className}>
      <div className='modal-footer-border' />
      {children}
    </div>
  )
}
