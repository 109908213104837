import './coreSwitch.scss'
import cn from 'classnames'

interface ICoreSwitchProps {
  isActive: boolean
  handleSwitch: (e?: React.MouseEvent) => void
}

export const CoreSwitch = ({ isActive, handleSwitch }: ICoreSwitchProps) => {
  return (
    <div onClick={e => handleSwitch(e)} className={cn('switch-wrapper', { active: isActive })}>
      <div className={cn('switch-ball', { active: isActive })}></div>
    </div>
  )
}
