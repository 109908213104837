import { selectCurrentUserRole, useAppSelector, useGetReportQuery } from 'mmfintech-backend-api'
import { CustomerRoleEnum, ReportStateEnum } from 'mmfintech-commons-types'
import { tr } from 'mmfintech-commons'

import ApprovedIcon from '@images/icons/success-toaster-icon.svg?react'
import ReturnedIcon from '@images/expenses/returned-report.svg?react'
import RejectedIcon from '@images/icons/error-toaster-icon.svg?react'

import './reportActionBadge.scss'

function formatSnakeCaseToTitleCase(snakeCaseString: string): string {
  return snakeCaseString
    .toLowerCase() // Convert the entire string to lowercase
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/\b\w/g, char => char.toUpperCase()) // Capitalize the first letter of each word
}

export const ReportActionBadge = ({ reportId }) => {
  const { data, isSuccess } = useGetReportQuery({ id: Number(reportId) })
  const { state } = isSuccess && data
  const customerRole = useAppSelector(selectCurrentUserRole)

  let message
  let Icon

  switch (state) {
    case ReportStateEnum.WAITING_FINANCE_APPROVAL:
    case ReportStateEnum.APPROVED:
      message = tr('FRONTEND.REPORTS.STATE.APPROVED', 'Report was approved successfully')
      Icon = ApprovedIcon
      break
    case ReportStateEnum.REJECTED:
      message = tr('FRONTEND.REPORTS.STATE.REJECTED', 'Report rejected')
      Icon = RejectedIcon
      break
    case ReportStateEnum.EDIT:
      message = tr('FRONTEND.REPORTS.STATE.RETURNED', 'Report returned')
      Icon = ReturnedIcon
      break
    case ReportStateEnum.WAITING_APPROVAL:
      if (customerRole === CustomerRoleEnum.SUBMITTER) {
        message = tr('FRONTEND.REPORTS.STATE.SUBMITTED', 'Report was submitted successfully')
        Icon = ApprovedIcon
      }
      break
    default: // Default message for unknown status
      message = formatSnakeCaseToTitleCase(state || '')
      Icon = null // Or a default icon
  }

  return (
    <div className='report-action-badge-container'>
      {Icon && <Icon />}
      <div>{message}</div>
    </div>
  )
}
