import { useEffect, useState } from 'react'

import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { isValidArray } from 'mmfintech-commons'

interface IListQueryResponse<T = any> {
  content: T[]
  totalPages: number
  number: number
  size: number
  totalElements: number
}

export const useInfiniteScroll = (useGetDataListQuery: UseLazyQuery<any>, { ...queryParameters }) => {
  const [localPage, setLocalPage] = useState(0)
  const [localSize, setLocalSize] = useState(null)
  const [combinedData, setCombinedData] = useState([])
  const [shouldScroll, setShouldScroll] = useState(null)

  const [getNext, { data: queryResponse, isFetching, isLoading, error }] = useGetDataListQuery({
    ...queryParameters
  })
  const {
    content: fetchData,
    number: remotePage,
    totalPages,
    totalElements
  } = (queryResponse as IListQueryResponse) || {}

  useEffect(() => {
    if (isValidArray(fetchData)) {
      if (localPage === 0) {
        setCombinedData(fetchData)
      } else {
        setCombinedData(previousData => [...previousData, ...fetchData])
      }
    } else {
      setCombinedData([])
    }
  }, [fetchData])

  const readMore = async (params: any = { size: 5, filter: {}, page: null }) => {
    let selectedPage = localPage + 1
    if (params.page || params.page == 0) {
      selectedPage = params.page
    }
    setLocalPage(selectedPage)
    setLocalSize(params.size)
    try {
      await getNext({
        page: selectedPage,
        size: params.size || localSize,
        ...params.filter,
        ...queryParameters.filter
      }).unwrap()
    } catch (error) {
      console.error(error?.message)
    }
  }

  const scrollMore = () => {
    setShouldScroll(val => !val)
  }

  const resetData = () => {
    setLocalPage(0)
    setCombinedData([])
    readMore({ ...queryParameters, page: 0 })
  }

  useEffect(() => {
    if (combinedData.length >= totalElements || shouldScroll == null) return
    readMore({ ...queryParameters })
  }, [shouldScroll])

  return {
    combinedData,
    localPage,
    readMore,
    isLoading: isLoading,
    isFetching: isFetching,
    error,
    totalElements,
    totalPages,
    currentElementsNum: fetchData?.length * (remotePage + 1),
    scrollMore,
    setLocalPage,
    resetData
  }
}
