import { useHistory } from 'react-router-dom'

import {
  paths,
  useDepositInitiateMutation,
  useDepositPreviewMutation,
  useGetMerchantQuery
} from 'mmfintech-backend-api'
import { getCurrencySymbol, GlobalContext, redirectToExternalUrl, tr } from 'mmfintech-commons'

import parse from 'html-react-parser'

import CheckIcon from '@images/icons/check-icon.svg?react'
import BankIcon from '@images/icons/bank_check_icon.svg?react'
import TermConditionsIcon from '@images/icons/terms_conditions_icon.svg?react'
import PhoneIcon from '@images/icons/phone_icon.svg?react'
import AnnounceIcon from '@images/icons/announce-icon.svg?react'
import SwiftIcon from '@images/icons/swift_logo.svg?react'
import SepaIcon from '@images/icons/sepa_logo.svg?react'
import EthereumIcon from '@images/icons/ethereum_icon.svg?react'
import MovingArrow from '@images/icons/moving-arrow.svg?react'

import './ApplicationFeeDetailsModal.scss'
import { MerchantEntityTypeEnum, PaymentMethod, PaymentMethodEnum } from 'mmfintech-commons-types'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { useContext } from 'react'

interface ApplicationFeeDetailsProps {
  amount: number
  currency: string
}

const paymentSteps = [
  {
    Icon: CheckIcon,
    text: tr(
      'FRONTEND.APPLICATION_FEE.DETAILS.STEP_1',
      'Choose the payment network of your preference - <b>SWIFT</b>, <b>SEPA</b> or <b>Crypto</b>'
    )
  },
  {
    Icon: BankIcon,
    text: tr('FRONTEND.APPLICATION_FEE.DETAILS.STEP_2', 'Our bank/crypto transfer details will appear on the screen')
  },
  {
    Icon: PhoneIcon,
    text: tr(
      'FRONTEND.APPLICATION_FEE.DETAILS.STEP_3',
      'Log into your crypto wallet or bank’s web service (you can also go to one of your bank’s branches) to transfer the funds'
    )
  },
  {
    Icon: TermConditionsIcon,
    text: tr(
      'FRONTEND.APPLICATION_FEE.DETAILS.STEP_4',
      'Don’t forget to add the <b>reference</b>. It will help us process your deposit faster'
    )
  }
]

const BUTTON_CONSTANT = [
  {
    Icon: SwiftIcon,
    text: tr('FRONTEND.APPLICATION_FEE.DETAILS.SWIFT_LABEL', 'SWIFT'),
    paymentMethod: PaymentMethodEnum.SWIFT_BANKWIRE
  },
  {
    Icon: SepaIcon,
    text: tr('FRONTEND.APPLICATION_FEE.DETAILS.SEPA_LABEL', 'SEPA'),
    paymentMethod: PaymentMethodEnum.BANKWIRE
  },
  {
    Icon: EthereumIcon,
    text: tr('FRONTEND.APPLICATION_FEE.DETAILS.CRYPTO_LABEL', 'CRYPTO'),
    paymentMethod: PaymentMethodEnum.CRYPTO
  }
]

const ApplicationFeeDetailsModal = ({ amount, currency }: ApplicationFeeDetailsProps) => {
  const { modalHide } = useContext(GlobalContext)

  const { data: merchant } = useGetMerchantQuery(null)
  const [previewDeposit, { error: previewError, isLoading: loadingPreview }] = useDepositPreviewMutation({
    fixedCacheKey: 'deposit-preview'
  })

  const [depositInitiate, { error: initiateError, isLoading: loadingInitiate }] = useDepositInitiateMutation({
    fixedCacheKey: 'deposit-initiate'
  })

  const history = useHistory()
  const { entityType, capabilities } = merchant || {}
  const { prospectCryptoExchangeEnabled } = capabilities || {}

  // useEffect(() => {
  //   console.log('invalidate tags')
  //   dispatch(api.util.invalidateTags(['deposit-preview']))
  //   dispatch(api.util.invalidateTags(['deposit-initiate']))
  // }, [])

  const displayCryptoInstructions = (paymentMethod: PaymentMethod, data: any) => {
    const { result, resultType } = data || {}

    if (resultType === 'REDIRECT_URL') {
      if (typeof result === 'string') {
        redirectToExternalUrl(result)
      } else {
        const { redirectUrl } = result || {}
        redirectToExternalUrl(redirectUrl)
      }
    } else {
      modalHide()
      paymentMethod === PaymentMethodEnum.CRYPTO
        ? history.push(paths.banking.deposit(), { paymentMethod, locationStep: 1 })
        : history.push(paths.banking.deposit(), { paymentMethod, locationStep: 3 })
    }
  }

  const handleDepositPreview = async (paymentMethod: PaymentMethod) => {
    await previewDeposit({
      amount,
      currency,
      paymentMethod
    })
      .unwrap()
      .then(async () => {
        await depositInitiate({ amount, currency, paymentMethod })
          .unwrap()
          .then(response => {
            displayCryptoInstructions(paymentMethod, response)
          })
      })
  }

  const handleSelectPaymentOption = (method: PaymentMethod) => {
    if (!loadingPreview && !loadingInitiate) {
      if (merchant && currency && entityType !== MerchantEntityTypeEnum.INDIVIDUAL) {
        if (method === PaymentMethodEnum.CRYPTO) {
          displayCryptoInstructions(method, null)
        } else {
          void handleDepositPreview(method)
        }
      }
    }
  }

  return (
    <div className='onboarding-application-fee-modal-wrapper'>
      <div className='onboarding-application-amount'>
        <div className='onboarding-application-amount-fee'>
          <span className='subtitle-label'>{tr('FRONTEND.ONBOARDING.APPLICATION_FEE_LABEL', 'Application fee')}</span>
          <span className='subtitle-content'>
            {getCurrencySymbol(currency)} {amount}
          </span>
        </div>
        <div className='onboarding-application-amount-total'>
          <span>{tr('FRONTEND.ONBOARDING.APPLICATION_FEE_TOTAL_LABEL', 'Total')}</span>
          <span>
            {getCurrencySymbol(currency)} {amount}
          </span>
        </div>
      </div>
      <div className='onboarding-application-content'>
        <div className='onboarding-application-content-announce'>
          <div className='onboarding-application-content-announce-icon'>
            <AnnounceIcon />
          </div>
          <span>
            {tr(
              'FRONTEND.ONBOARDING.APPLICATION_FEE_ANNOUNCE_TEXT',
              'The Kingdom Bank won’t charge your bank automatically. You’ll need to do this bit yourself to pay by bank transfer or crypto'
            )}
          </span>
        </div>
        <div className='onboarding-application-info'>
          {paymentSteps.map(({ Icon, text }, index) => {
            return (
              <div className='onboarding-application-info-row' key={`info-${index}`}>
                <div className='onboarding-application-info-icon'>
                  <Icon />
                </div>
                <span className='onboarding-application-info-text'>{parse(text)}</span>
              </div>
            )
          })}
        </div>
      </div>
      <div className='onboarding-application-divider' />
      <div className='onboarding-application-button-wrapper'>
        {BUTTON_CONSTANT.filter(
          node =>
            (prospectCryptoExchangeEnabled && node.paymentMethod === PaymentMethodEnum.CRYPTO) ||
            node.paymentMethod !== PaymentMethodEnum.CRYPTO
        ).map(({ Icon, text, paymentMethod }) => {
          return (
            <div
              key={paymentMethod}
              onClick={() => handleSelectPaymentOption(paymentMethod)}
              className='onboarding-application-button'>
              <div className='onboarding-application-button-information'>
                <div className={`onboarding-application-icon ${text}`}>
                  <Icon />
                </div>
                <span>{text}</span>
              </div>
              <div className='onboarding-application-button-arrow'>
                <MovingArrow />
              </div>
            </div>
          )
        })}
      </div>
      <ErrorDisplay
        error={
          previewError || initiateError
            ? {
                ...previewError,
                ...initiateError,
                error:
                  'Contact your account manager for more information on how to complete the application fee payment.',
                errorKey: 'MERCHANTS.ERROR.PAYMENT_METHOD_UNAVAILABLE'
              }
            : null
        }
      />
    </div>
  )
}

export default ApplicationFeeDetailsModal
