import { useContext, useMemo, useState } from 'react'
import toast from 'react-hot-toast'

import {
  useArchiveCounterpartyMutation,
  useRestoreCounterpartyMutation,
  useGetCounterpartyQuery,
  paths
} from 'mmfintech-backend-api'
import { CounterpartyStatusEnum } from 'mmfintech-commons-types'
import { GlobalContext, tr } from 'mmfintech-commons'

import { Breadcrumb, ConfirmationModal, CoreButton, CoreModalDialog } from '@components'
import { CounterpartyCreateModal } from '../CounterpartyCreateModal'

import EditReportNameIcon from '@images/expenses/edit-report-name.svg?react'
import LeftArrowIcon from '@images/icons/left-arrow-icon.svg?react'
import ArchiveIcon from '@images/icons/archive-icon.svg?react'

import './counterpartyPageHeader.scss'

interface CounterpartyPageHeaderProps {
  counterpartyId: string
}

const BREADCRUMB_ITEMS: { label: string; path: (id?: string) => string }[] = [
  {
    label: 'Reports',
    path: () => paths.expenseManagement.expenseManagementDashboard()
  },
  {
    label: 'Counterparties',
    path: () => paths.expenseManagement.counterParty()
  },
  {
    label: 'Counterparties Detail',
    path: (id: string) => paths.expenseManagement.singleCounterparty(id)
  }
]

export const CounterpartyPageHeader = ({ counterpartyId }: CounterpartyPageHeaderProps) => {
  const { data: counterparty } = useGetCounterpartyQuery({ counterpartyId: Number(counterpartyId) })
  const { modalHide, modalShow } = useContext(GlobalContext)

  const [showEditModal, setShowEditModal] = useState(false)
  const [archive, { error: archiveError }] = useArchiveCounterpartyMutation()
  const [restore, { error: restoreError }] = useRestoreCounterpartyMutation()

  const isArchived = useMemo(() => counterparty?.state === CounterpartyStatusEnum.ARCHIVED, [counterparty])
  const text = isArchived
    ? tr('FRONTEND.COUNTERPARTY.RESTORE', 'Restore')
    : tr('FRONTEND.COUNTERPARTY.ARCHIVE', 'Archive')

  const question = isArchived
    ? tr('FRONTEND.COUNTERPARTY.QUESTION.RESTORE', 'Are you sure you want to restore this counterparty?')
    : tr('FRONTEND.COUNTERPARTY.QUESTION.ARCHIVE', 'Are you sure you want to archive this counterparty?')

  if (!counterparty?.state) {
    return null
  }

  const handleArchiveRestore = async () => {
    try {
      isArchived
        ? await restore({ counterpartyId: counterparty.id }).unwrap()
        : await archive({ counterpartyId: counterparty.id }).unwrap()

      toast.success(`Counterparty ${isArchived ? 'restored' : 'archived'} successfully`)
      modalHide()
    } catch (error) {
      toast.error(error.error)
    }
  }

  const handleEditModalOpen = () => setShowEditModal(true)
  const handleEditModalClose = () => setShowEditModal(false)

  const handleConfirmArchiveModalOpen = () => {
    modalShow({
      header: text,
      options: {},
      content: (
        <ConfirmationModal
          question={question}
          title={text}
          onConfirm={handleArchiveRestore}
          error={[archiveError, restoreError]}
        />
      )
    })
  }

  return (
    <div className='counterparty-header-wrapper'>
      <div className='counterparty-header-container'>
        <div className='header-left-side'>
          <div
            onClick={e => {
              e.preventDefault()
              window.history.back()
            }}
            className='back-arrow-icon'>
            <LeftArrowIcon />
          </div>
          <h1 className='label'>{counterparty?.name}</h1>
        </div>
        <div className='actions-counterparty'>
          <CoreButton
            data-test='open-edit-counterparty-modal'
            onClick={handleEditModalOpen}
            CollapsedIcon={<EditReportNameIcon />}
            collapsed
            variation='elevated'
            size='large'
          />

          <CoreButton
            variation='elevated'
            text={text}
            LeftIcon={<ArchiveIcon />}
            data-test='archive-btn'
            onClick={handleConfirmArchiveModalOpen}
            size='large'
          />
        </div>

        <CoreModalDialog
          header={`${tr('FRONTEND.COUNTERPARTY.ACTION.NEW', 'New')} ${tr(
            'FRONTEND.EXPENSE_MANAGEMENT.TITLE.COUNTERPARTY',
            'Counterparty'
          )}`}
          options={{ closeOnEscape: false, closeOnClickOutside: false }}
          visible={showEditModal}
          content={<CounterpartyCreateModal onClose={handleEditModalClose} counterparty={counterparty} />}
          onClose={handleEditModalClose}
        />
      </div>
      <Breadcrumb breadcrumbItems={BREADCRUMB_ITEMS} />
    </div>
  )
}
