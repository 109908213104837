import cn from 'classnames'
import './CoreCurrencyIcon.scss'
import LoadingIcon from '@images/loader-icon.svg?react'
import { configuration } from 'mmfintech-backend-api'
import { getCurrencyImageUrl } from 'mmfintech-commons'

interface CurrencyIconProps {
  currency: string | undefined
  size?: string
  circle?: boolean
  className?: string
  isLoading?: boolean
}

export const CoreCurrencyIcon = (props: CurrencyIconProps) => {
  const { currency, size = '3rem', circle, className, isLoading } = props

  return (
    <>
      {isLoading ? (
        <div className='currency-icon-loading' style={{ width: size, height: size }}>
          <LoadingIcon />
        </div>
      ) : (
        <div style={{ width: size, height: size }} className={cn('currency-icon', className, { circle })}>
          <img src={findCurrencyIconSrc(currency)} alt='' />
        </div>
      )}
    </>
  )
}

export const findCurrencyIconSrc = (currency: string) =>
  `${configuration.getBaseUrl()}${getCurrencyImageUrl(currency, 'svg')}`
