import { DragAndDrop, FileInput } from 'mmfintech-portal-commons'
import { tr } from 'mmfintech-commons'

import { CoreButton } from '@components'

import UploadedIcon from '@images/icons/batch-uploaded.svg?react'
import DeleteIcon from '@images/icons/batch-delete.svg?react'
import UploadIcon from '@images/icons/upload-document-icon.svg?react'

import './fileUploadPane.scss'

interface FileUploadPaneProps {
  disabled?: boolean
  acceptTypes?: string[]
  file?: File
  onDrop: (files: any) => void
  onError: (errors: any) => void
  onRemove: () => void
  dragAndDropHint?: string
  dragAndDropMessage?: string
  tooltipId?: string
}

export const FileUploadPane = ({
  disabled,
  acceptTypes,
  file,
  onDrop,
  onError,
  onRemove,
  dragAndDropMessage
}: FileUploadPaneProps) => {
  if (disabled) {
    return <FileUploadView types={acceptTypes} message={dragAndDropMessage} disabled={disabled} />
  }

  return (
    <DragAndDrop onDrop={onDrop} onError={onError} acceptType={acceptTypes}>
      <FileInput maxNumber={1} acceptType={acceptTypes} onChange={onDrop} onError={onError}>
        {({ onFileUpload, onRemoveAll }) =>
          file ? (
            <FileUploadedPane file={file} onRemove={onRemove} />
          ) : (
            <FileUploadView
              types={acceptTypes}
              message={dragAndDropMessage}
              onClick={() => {
                onRemoveAll()
                onFileUpload()
              }}
            />
          )
        }
      </FileInput>
    </DragAndDrop>
  )
}

export const FileUploadedPane = ({ file, onRemove }) => (
  <div className='uploaded-file-wrapper' style={{ marginTop: 0 }}>
    <div className='delete-icon-wrapper' onClick={onRemove}>
      <DeleteIcon />
    </div>
    <div className='uploaded-content'>
      <UploadedIcon />
      <div className='uploaded-content-right'>
        <div className='uploaded-text'>{tr('FRONTEND.FILE_UPLOAD.FILE_LABEL', 'File uploaded')}</div>
        <span className='file-name'>{file.name}</span>
      </div>
    </div>
  </div>
)

export const FileUploadView = ({ onClick = () => {}, types, message, disabled = false }) => (
  <div className='upload-wrapper'>
    <div className='upload-container' data-test='upload-box' onClick={() => onClick()}>
      <span>{message}</span>
      <span className='or-text'>{tr('FRONTEND.FILE_UPLOAD.OR', 'or')}</span>
      <CoreButton
        size='large'
        LeftIcon={<UploadIcon />}
        text={tr('FRONTEND.FILE_UPLOAD.FORMAT_FILE', 'Select file')}
        disabled={disabled}
      />
    </div>
    <span className='types'>
      *{tr('FRONTEND.FILE_UPLOAD.FORMAT_FILE', 'File format')}: *.
      {types?.join(', .*')}
    </span>
  </div>
)
