import './styled/transactionTypeWithIcon.scss'

import ArrowIcon from '@images/icons/transaction-type-arrow-icon.svg?react'
import CashVoucherIcon from '@images/icons/transaction-type-cash-voucher-icon.svg?react'
import ExchangeIcon from '@images/icons/transaction-type-exchange-icon.svg?react'
import PlusIcon from '@images/icons/transaction-type-plus-icon.svg?react'

interface ITransactionTypeWithIconProps {
  transactionType: string
  category: string
  description?: string | JSX.Element
}
interface ITransactionTitleWithDate {
  title?: string
  date?: string | JSX.Element
}

interface ICoreIconProps {
  category: string
}
export const CoreTransactionIcon = ({ category }: ICoreIconProps) => {
  switch (category) {
    case 'DEPOSIT':
      return PlusIcon
    case 'WITHDRAW':
    case 'TRANSACTION_FEE':
      return ArrowIcon
    case 'EXCHANGE':
      return ExchangeIcon
    default:
      return CashVoucherIcon
  }
}

const TransactionTypeWithIcon = ({ transactionType, category, description }: ITransactionTypeWithIconProps) => {
  const formattedText = transactionType.toLowerCase().replace(/_/g, ' ')
  const CoreIcon = CoreTransactionIcon({ category })
  return (
    <div className='transaction-type-wrapper'>
      <div data-test='core-transaction-icon' className='transaction-type-icon-wrapper'>
        <CoreIcon />
      </div>
      <div className='transaction-text' style={{ fontWeight: 700 }}>
        <span data-test='core-transaction-title'>{formattedText}</span>
        {description && (
          <span data-test='core-transaction-description' className='transaction-type-description'>
            {description}
          </span>
        )}
      </div>
    </div>
  )
}

export const TransactionTitleWithDate = ({ title, date }: ITransactionTitleWithDate) => {
  return (
    <div className='transaction-type-wrapper'>
      <div className='transaction-text'>
        <span data-test='core-transaction-title'>{title}</span>
        {date && (
          <span data-test='core-transaction-description' className='transaction-type-description'>
            {date}
          </span>
        )}
      </div>
    </div>
  )
}

export default TransactionTypeWithIcon
