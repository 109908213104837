import './transactionTypeWithIcon.scss'

import WithdrawIcon from '@images/icons/withdraw_icon.svg?react'
import InvoicesIcon from '@images/icons/invoices_icon.svg?react'
import ExchangeIcon from '@images/icons/exchange_icon.svg?react'
import DepositIcon from '@images/icons/deposit_icon.svg?react'

interface ITransactionTypeWithIconProps {
  transactionType: string
  category: string
  description?: string | JSX.Element
}
interface ITransactionTitleWithDate {
  title?: string
  date?: string | JSX.Element
}

interface ICoreIconProps {
  category: string
}
export const CoreTransactionIcon = ({ category }: ICoreIconProps) => {
  switch (category) {
    case 'DEPOSIT':
      return DepositIcon
    case 'WITHDRAW':
    case 'TRANSACTION_FEE':
      return WithdrawIcon
    case 'EXCHANGE':
      return ExchangeIcon
    default:
      return InvoicesIcon
  }
}

const TransactionTypeWithIcon = ({ transactionType, category, description }: ITransactionTypeWithIconProps) => {
  const formattedText = transactionType.toLowerCase().split('_').join(' ')
  const CoreIcon = CoreTransactionIcon({ category })
  return (
    <div className='transaction-type-wrapper'>
      <div data-test='core-transaction-icon' className='transaction-type-icon-wrapper'>
        <CoreIcon />
      </div>
      <div className='transaction-text' style={{ fontWeight: 700 }}>
        <span data-test='core-transaction-title'>{formattedText}</span>
        {description && (
          <span data-test='core-transaction-description' className='transaction-type-description'>
            {description}
          </span>
        )}
      </div>
    </div>
  )
}

export const TransactionTitleWithDate = ({ title, date }: ITransactionTitleWithDate) => {
  return (
    <div className='transaction-type-wrapper'>
      <div className='transaction-text'>
        <span data-test='core-transaction-title'>{title}</span>
        {date && (
          <span data-test='core-transaction-description' className='transaction-type-description'>
            {date}
          </span>
        )}
      </div>
    </div>
  )
}

export default TransactionTypeWithIcon
