import { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { tr, OtpContext } from 'mmfintech-commons'
import {
  actions,
  responseDoesNotContainsChallenge,
  useGetMerchantQuery,
  useInternalTransferQry
} from 'mmfintech-backend-api'
import { MerchantAccountTypeEnum, OnboardingStatusEnum, PaymentOptionStatusEnum } from 'mmfintech-commons-types'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import {
  CoreApplicationFeeAnnounce,
  CoreButton,
  CoreInput,
  CoreOnboardingAnnounce,
  CorePaymentInput,
  SuccessPane
} from '@components'
import { InternalTransferPreview } from './InternalTransferPreview'
import { HeaderContext } from '../../../context/HeaderContext'

import ReferenceIcon from '@images/icons/note.svg?react'

export const InternalTransfer = () => {
  const { setOtpOnSuccess } = useContext(OtpContext)
  const { data: merchant } = useGetMerchantQuery(null)
  const { setCurrentStep, currentStep } = useContext(HeaderContext)

  const [result, setResult] = useState(null)

  const history = useHistory()

  const { accountType, onboardingStatus } = merchant || {}

  const {
    formValues,
    accounts,
    accountsError,
    accountsFetching,
    selectedOption,
    selectedAccount,
    setSelectedAccount,
    handlePreview,
    handleInitiate,
    previewError,
    previewLoading,
    resetMutations
  } = useInternalTransferQry({
    validation: 'float',
    onPreviewSuccess: () => setCurrentStep(2),
    onInitiateSuccess: (response: any) => handleInitiateSuccess(response)
  })

  const transferSuccess = (data: any) => {
    setResult(data)
    setCurrentStep(3)
  }

  const handleInitiateSuccess = (data: any): void => {
    if (responseDoesNotContainsChallenge(data)) {
      transferSuccess(data)
    }
  }

  const handleAdvancedSubmit = () => {
    setOtpOnSuccess(() => transferSuccess)
    handleInitiate()
  }

  useEffect(() => {
    return () => {
      setCurrentStep(1)
      setResult(null)
      resetMutations()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {currentStep === 1 && (
        <div className='send-money-container' data-test='internal-transfer-container'>
          <div className='from-container'>
            <div className='label'>
              <span>From</span>
            </div>
            <CorePaymentInput
              selectedOption={selectedAccount}
              setSelectedAccount={setSelectedAccount}
              dataTest={'deposit-payment-input-test'}
              options={accounts}
              enableMaxAmountButton
              paymentOption={selectedOption}
              onChange={value => formValues.setValue('amount', value)}
              value={formValues.getValue('amount')}
              error={formValues.getError('amount')}
              isLoading={accountsFetching}
            />
          </div>

          <div className='to-container'>
            <div className='label'>
              <span>To</span>
            </div>
            <CoreInput
              type='email'
              name='recipientEmail'
              data-test='recipient-email'
              maxLength={50}
              label={tr('FRONTEND.TRANSFER.EMAIL_LABEL', 'E-mail')}
              {...formValues.registerInput('recipientEmail')}
              // autoComplete='off'
            />
          </div>

          <CoreInput
            type='text'
            name='reference'
            data-test='reference'
            maxLength={50}
            label={tr('FRONTEND.TRANSFER.REFERENCE_LABEL', 'Reference')}
            {...formValues.registerInput('reference')}
            autoComplete='off'
            LeftIcon={<ReferenceIcon />}
          />

          <div className='mb-4' />

          {selectedOption?.status === PaymentOptionStatusEnum.INCOMPLETE_ONBOARDING ||
          (accountType === MerchantAccountTypeEnum.PROSPECT &&
            selectedOption?.status !== PaymentOptionStatusEnum.AVAILABLE) ? (
            <div className='warning-banner'>
              <CoreOnboardingAnnounce />
            </div>
          ) : null}

          {accountType === MerchantAccountTypeEnum.PROSPECT && onboardingStatus === OnboardingStatusEnum.APPROVED && (
            <div className='warning-banner'>
              <CoreApplicationFeeAnnounce />
            </div>
          )}

          <ErrorDisplay error={[accountsError, previewError]} />

          <CoreButton
            type='button'
            size='large'
            text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
            disabled={
              accountsFetching ||
              selectedOption?.status !== PaymentOptionStatusEnum.AVAILABLE ||
              formValues.getFloat('amount') <= 0
            }
            isLoading={previewLoading}
            onClick={handlePreview}
            data-test='button-continue'
            fullWidth
          />
        </div>
      )}

      {currentStep === 2 && (
        <InternalTransferPreview
          selectedAccount={selectedAccount}
          formValues={formValues}
          onSubmit={handleAdvancedSubmit}
          onCancel={() => setCurrentStep(1)}
        />
      )}

      {currentStep === 3 && (
        <SuccessPane response={result} onClick={() => actions.routing.returnFromWithdraw(history)} />
      )}
    </>
  )
}
