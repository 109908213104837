import { CountrySupportedPurposeEnum, InstrumentFieldEnum, RecipientOut } from 'mmfintech-commons-types'
import '../styles/bankRecipientEdit.scss'
import { recipientKeyToCode, splitBankRecipientData, useEditBankAccountQry } from '@hooks'
import {
  getExternalBankAccountFieldLabel,
  useCountries,
  useEligibleAccountsForRecipientQuery
} from 'mmfintech-backend-api'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { CoreButton, CoreInput, CoreSelect, CoreSteps } from '@components'
import { isValidArray, isValidObject, tr } from 'mmfintech-commons'
import { useMemo, useState } from 'react'

const obsoleteFields = ['foreignId', 'currency', 'ownAccount']

export const BankRecipientEdit = ({ recipient, onSuccess }: { recipient?: RecipientOut; onSuccess?: () => void }) => {
  const recipientId = recipient?.paymentInstrumentId || 0

  const { countryOptions } = useCountries(CountrySupportedPurposeEnum.WITHDRAWAL)

  const { data: eligibleAccounts } = useEligibleAccountsForRecipientQuery(recipientId)
  const [step, setStep] = useState(1)

  const {
    formValues,
    countryCode,
    accountTypeOptions,
    documentTypesOptions,
    handleSubmit,
    saveRecipientError,
    saveRecipientLoading
  } = useEditBankAccountQry({
    accountId: isValidArray(eligibleAccounts) ? eligibleAccounts[0].id : null,
    recipientId,
    onSuccess,
    disableBankMetadata: true
  })

  const bankRecipientFormDetails = useMemo(() => {
    return splitBankRecipientData(formValues)
  }, [formValues])

  return (
    <div className='recipient-edit-wrapper'>
      <form className='styled-form' noValidate>
        <div className='recipient-edit-steps'>
          <CoreSteps
            maxStep={2}
            step={step}
            title={
              step === 1
                ? tr('FRONTEND.RECIPIENTS.EDIT.INFO.PAYMENT', 'Payment information')
                : tr('FRONTEND.RECIPIENTS.EDIT.INFO.PERSONAL', 'Personal information')
            }
          />
        </div>
        <div className='recipient-form-title'>
          {step === 1
            ? tr('FRONTEND.RECIPIENTS.EDIT.INFO.PAYMENT', 'Payment information')
            : tr('FRONTEND.RECIPIENTS.EDIT.INFO.PERSONAL', 'Personal information')}
        </div>
        <div className='form-elements-container'>
          {isValidObject(formValues) &&
            Object.entries(
              step === 1 ? bankRecipientFormDetails.paymentInformation : bankRecipientFormDetails.personalInformation
            ).map(meta => {
              const [key] = meta

              if (obsoleteFields.includes(key)) return null

              switch (key) {
                case InstrumentFieldEnum.COUNTRY_CODE:
                case InstrumentFieldEnum.HOLDER_NAME:
                  return null

                case 'holderDateOfBirth':
                  return (
                    <CoreInput
                      key={key}
                      type='date'
                      name={key}
                      data-test={key}
                      label={getExternalBankAccountFieldLabel(countryCode, recipientKeyToCode(key))}
                      tooltip={tr(formValues.getTooltip(key), '')}
                      {...formValues.registerInput(key)}
                    />
                  )

                case 'holderPhone':
                  return (
                    <CoreInput
                      key={key}
                      type='phone'
                      name={key}
                      data-test={key}
                      label={getExternalBankAccountFieldLabel(countryCode, recipientKeyToCode(key))}
                      tooltip={tr(formValues.getTooltip(key), '')}
                      {...formValues.registerInput(key)}
                    />
                  )

                case 'holderAddress.countryCode':
                case 'bankAddress.countryCode':
                  return (
                    <CoreSelect
                      key={key}
                      type='country'
                      data-test={key}
                      options={isValidArray(countryOptions) ? countryOptions : []}
                      label={getExternalBankAccountFieldLabel(countryCode, recipientKeyToCode(key))}
                      tooltip={tr(formValues.getTooltip(key), '')}
                      {...formValues.registerInput(key)}
                    />
                  )

                case 'entityType':
                  return (
                    <CoreSelect
                      key={key}
                      type='default'
                      data-test={key}
                      isSearchable={false}
                      value={{
                        value: 'COMPANY',
                        label: tr('FRONTEND.SIGNUP.ACCOUNT_TYPES.COMPANY', 'Corporate Account')
                      }}
                      options={[
                        { value: 'COMPANY', label: tr('FRONTEND.SIGNUP.ACCOUNT_TYPES.COMPANY', 'Corporate Account') }
                      ]}
                      label={getExternalBankAccountFieldLabel(countryCode, recipientKeyToCode(key))}
                      tooltip={tr(formValues.getTooltip(key), '')}
                      {...formValues.registerInput(key)}
                    />
                  )

                case 'documentType':
                  return (
                    <CoreSelect
                      key={key}
                      type='default'
                      data-test={key}
                      options={documentTypesOptions}
                      label={getExternalBankAccountFieldLabel(countryCode, recipientKeyToCode(key))}
                      tooltip={tr(formValues.getTooltip(key), '')}
                      {...formValues.registerInput(key)}
                    />
                  )

                case 'accountType':
                  return (
                    <CoreSelect
                      key={key}
                      type='default'
                      data-test={key}
                      options={accountTypeOptions()}
                      label={getExternalBankAccountFieldLabel(countryCode, recipientKeyToCode(key))}
                      tooltip={tr(formValues.getTooltip(key), '')}
                      {...formValues.registerInput(key)}
                    />
                  )

                default:
                  return (
                    <CoreInput
                      key={key}
                      type='text'
                      data-test={key}
                      label={getExternalBankAccountFieldLabel(countryCode, recipientKeyToCode(key))}
                      tooltip={tr(formValues.getTooltip(key), '')}
                      {...formValues.registerInput(key)}
                    />
                  )
              }
            })}
        </div>

        <ErrorDisplay error={[saveRecipientError]} />

        <div className='recipient-edit-actions'>
          {step === 2 && (
            <CoreButton
              data-test='save-button'
              type='button'
              variation='tertiary'
              size='large'
              text={tr('FRONTEND.RECIPIENTS.BUTTON.BACK', 'Back')}
              className='save-button'
              onClick={() => {
                setStep(1)
              }}
              disabled={saveRecipientLoading}
            />
          )}
          <CoreButton
            data-test='save-button'
            type='button'
            variation='primary'
            size='large'
            fullWidth
            text={tr('FRONTEND.RECIPIENTS.BUTTON.SAVE', 'Save')}
            className='save-button'
            onClick={async e => {
              e.preventDefault()
              await handleSubmit()
            }}
            isLoading={saveRecipientLoading}
          />
          {step === 1 && (
            <CoreButton
              data-test='save-button'
              type='button'
              variation='tertiary'
              size='large'
              text={tr('FRONTEND.RECIPIENTS.BUTTON.NEXT', 'Next')}
              className='save-button'
              onClick={() => {
                setStep(2)
              }}
              disabled={saveRecipientLoading}
            />
          )}
        </div>
      </form>
    </div>
  )
}
