import { useEffect, useState } from 'react'

interface IUseMatchMediaProps {
  breakpoint: string | number
  prefix: 'min' | 'max'
}

export const useMatchMedia = ({ breakpoint, prefix }: IUseMatchMediaProps): boolean => {
  const [visible, setVisible] = useState<boolean>(window.matchMedia(`(${prefix}-width: ${breakpoint}px)`).matches)

  useEffect(() => {
    const query = window.matchMedia(`(${prefix}-width: ${breakpoint}px)`)

    const handleQuery = e => {
      setVisible(e.matches)
    }
    query.addEventListener(`change`, handleQuery)

    return () => {
      query.removeEventListener(`change`, handleQuery)
    }
  }, [])

  return visible
}
