import { ExternalWalletTypeEnum, RecipientOut } from 'mmfintech-commons-types'

import { CryptoRecipientEdit } from './CryptoRecipientEdit'
import { BankRecipientEdit } from './BankRecipientEdit'
import { InteracRecipientEdit } from './InteracRecipientEdit'
import { PerfectMoneyRecipientEdit } from './PerfectMoneyRecipientEdit'
import { WalletRecipientEdit } from './WalletRecipientEdit'
import { PixRecipientEdit } from './PixRecipientEdit'

import '../styles/editRecipientModal.scss'

interface EditRecipientModalProps {
  onClose?: () => void
  onSuccess?: (response?: any) => void
  recipient?: RecipientOut
}
export const EditRecipientModal = ({ onClose, recipient, onSuccess }: EditRecipientModalProps) => {
  const { type, paymentInstrumentId, currency, walletType } = recipient

  const recipientEditModalContent = (type: string, walletType: string): JSX.Element => {
    switch (type) {
      case 'EXTERNAL_CRYPTO_ADDRESS':
      case 'RECIPIENT_CRYPTO_ADDRESS':
        return (
          <CryptoRecipientEdit
            recipientId={paymentInstrumentId}
            currencyCode={currency}
            onClose={onClose}
            onSuccess={(response) => {
              onClose()
              onSuccess(response)
            }}
          />
        )
      case 'RECIPIENT_WALLET_ACCOUNT':
        switch (walletType) {
          case ExternalWalletTypeEnum.INTERAC:
            return (
              <InteracRecipientEdit
                recipientId={paymentInstrumentId}
                currencyCode={currency}
                onClose={onClose}
                onSuccess={(response) => {
                  onClose()
                  onSuccess(response)
                }}
              />
            )

          case ExternalWalletTypeEnum.JETON_WALLET:
            return (
              <WalletRecipientEdit
                currencyCode={currency}
                onClose={onClose}
                recipientId={paymentInstrumentId}
                onSuccess={(response) => {
                  onClose()
                  onSuccess(response)
                }}
              />
            )

          case ExternalWalletTypeEnum.PERFECT_MONEY:
            return (
              <PerfectMoneyRecipientEdit
                recipientId={paymentInstrumentId}
                currencyCode={currency}
                onClose={onClose}
                onSuccess={(response) => {
                  onClose()
                  onSuccess(response)
                }}
              />
            )

          case ExternalWalletTypeEnum.PIX:
            return (
              <PixRecipientEdit
                recipientId={paymentInstrumentId}
                currencyCode={currency}
                onClose={onClose}
                onSuccess={(response) => {
                  onClose()
                  onSuccess(response)
                }}
              />
            )

          default:
          // nothing
        }
        break

      default:
        return (
          <BankRecipientEdit
            recipient={recipient}
            onSuccess={() => {
              onClose && onClose()
              onSuccess && onSuccess()
            }}
          />
        )
    }
  }

  return recipientEditModalContent(type, walletType)
}
