import styled from 'styled-components'

import InfoImage from '@images/icons/info.svg'

export const InstructionsButtonStyled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  color: #85a0ad;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7rem;

  .info {
    //prettier-ignore
    background: url("${InfoImage}") center center no-repeat;
    background-size: cover;

    display: inline-flex;
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
`
