import { useEffect } from 'react'

import { tr } from 'mmfintech-commons'
import { useEditExternalWalletQry } from 'mmfintech-backend-api'
import { ExternalWalletTypeEnum } from 'mmfintech-commons-types'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { CoreButton, CoreInput } from '@components'

export const InteracRecipientEdit = ({ recipientId, currencyCode, onClose, onSuccess }) => {
  const { formValues, handleSubmit, error, fetching, resetMutations } = useEditExternalWalletQry({
    type: ExternalWalletTypeEnum.INTERAC,
    recipientId,
    currencyCode: currencyCode || 'CAD',
    onClose,
    onSuccess
  })

  const handleSubmitInternal = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSubmit()
  }

  useEffect(() => {
    return () => {
      resetMutations()
    }
  }, [])
  return (
    <div className='recipient-edit-wrapper' data-test='interac-recipient-edit-modal'>
      <div className='recipient-edit-title'>
        {tr('FRONTEND.RECIPIENTS.LABELS.INTERAC_INFORMATION', 'Payment information')}
      </div>
      <form className='recipient-edit-form' noValidate onSubmit={handleSubmitInternal}>
        <CoreInput
          type='text'
          data-test='holder-name'
          label={tr('FRONTEND.RECIPIENTS.INTERAC.HOLDER_NAME', 'Holder name')}
          maxLength={100}
          {...formValues.registerInput('holderName')}
          autoComplete='off'
        />

        <CoreInput
          type='text'
          data-test='identifier'
          label={tr('FRONTEND.RECIPIENTS.INTERAC.IDENTIFIER', 'Identifier')}
          {...formValues.registerInput('identifier')}
          autoComplete='off'
        />

        <CoreInput
          type='email'
          data-test='email'
          label={tr('FRONTEND.RECIPIENTS.INTERAC.EMAIL', 'Email')}
          {...formValues.registerInput('email')}
          autoComplete='off'
        />

        <CoreInput
          type='phone'
          data-test='phone'
          label={tr('FRONTEND.RECIPIENTS.INTERAC.PHONE', 'Phone')}
          maxLength={100}
          {...formValues.registerInput('phone')}
          autoComplete='off'
        />

        <CoreInput
          type='text'
          data-test='address'
          label={tr('FRONTEND.RECIPIENTS.INTERAC.ADDRESS', 'Address')}
          {...formValues.registerInput('address')}
          autoComplete='off'
        />

        <CoreInput
          type='text'
          data-test='state'
          label={tr('FRONTEND.RECIPIENTS.INTERAC.STATE', 'State')}
          {...formValues.registerInput('state')}
          autoComplete='off'
        />

        <CoreInput
          type='text'
          data-test='city'
          label={tr('FRONTEND.RECIPIENTS.INTERAC.CITY', 'City')}
          {...formValues.registerInput('city')}
          autoComplete='off'
        />

        <CoreInput
          type='text'
          data-test='postalCode'
          label={tr('FRONTEND.RECIPIENTS.INTERAC.POSTAL_CODE', 'Postal code')}
          {...formValues.registerInput('postalCode')}
          autoComplete='off'
        />

        <CoreInput
          type='text'
          data-test='countryCode'
          label={tr('FRONTEND.RECIPIENTS.INTERAC.COUNTRY', 'Country')}
          value={'Canada'}
          autoComplete='off'
          disabled
        />

        <CoreInput
          type='text'
          data-test='description'
          label={tr('FRONTEND.RECIPIENTS.INTERAC.DESCRIPTION', 'Description')}
          {...formValues.registerInput('description')}
          autoComplete='off'
        />
        <ErrorDisplay error={error} />

        <div className='recipient-edit-actions'>
          <CoreButton
            data-test='save-button'
            variation='primary'
            size='large'
            text={tr('FRONTEND.RECIPIENTS.BUTTON.SAVE', 'Save')}
            className='save-button'
            onClick={handleSubmitInternal}
            isLoading={fetching}
            disabled={fetching}
            fullWidth
          />
        </div>
      </form>
    </div>
  )
}
