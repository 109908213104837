import './dashboardBanner.scss'

import { ViewAllButton } from '../../components/ViewAllButton'

interface IBannerProps {
  heading?: string
  title?: string
  description?: string
  redirect?: string | (() => void)
  image?: string
  buttonLabel?: string
}

export const DashboardBanner = ({ heading, title, description, redirect, image, buttonLabel }: IBannerProps) => {
  return (
    <div className='dashboard-banner-wrapper'>
      <div className='dashboard-banner-heading'>
        <div className='dashboard-banner-header'>{heading}</div>
        <ViewAllButton label={buttonLabel} redirect={redirect} />
      </div>
      <div className='dashboard-banner-main'>
        <img src={image} alt={heading + '-banner-image'} className='dashboard-banner-imageФ' />
        <div className='dashboard-banner-content'>
          <div className='dashboard-banner-title'>{title}</div>
          <div className='dashboard-banner-description'>{description}</div>
        </div>
      </div>
    </div>
  )
}
