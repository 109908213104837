import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import { isValidArray, tr } from 'mmfintech-commons'

import './ibanRequestMethods.scss'

import { useMatchMedia } from '@hooks'

import SwiftIcon from '@images/methods/swift-icon.svg?react'
import SepaIcon from '@images/icons/sepa-icon.svg?react'
import IbanIssuingLocalIcon from '@images/icons/iban-issuing-local-icon.svg?react'

interface IbanRequestMethods {
  options: string[]
  setSelectedOption?: Dispatch<SetStateAction<string>>
  selectedOption?: string
  collapse?: boolean
  loading?: boolean
  disabled?: boolean
  setCollapse?: Dispatch<SetStateAction<boolean>>
  dataTest?: string
}

export const IbanRequestMethods = ({
  options,
  setSelectedOption,
  selectedOption,
  collapse,
  // loading,
  disabled = false,
  dataTest,
  setCollapse
}: IbanRequestMethods) => {
  const enableCollapse = useMatchMedia({ breakpoint: 1280, prefix: 'max' }) // collapse state (breakpoint)
  const paymentMethodsContainer = useRef(null)
  const [filteredOptions, setFilteredOptions] = useState([])

  useEffect(() => {
    if (enableCollapse && selectedOption) {
      setFilteredOptions(options.filter(option => option !== selectedOption))
    } else {
      setFilteredOptions(options)
    }
  }, [enableCollapse, selectedOption])

  useEffect(() => {
    if (enableCollapse && !collapse && paymentMethodsContainer.current) {
      paymentMethodsContainer.current.scroll(0, 0)
    }
  }, [collapse, enableCollapse])

  const PaymentOptionIcon = (paymentMethod: string): JSX.Element => {
    switch (paymentMethod) {
      case 'SWIFT':
        return <SwiftIcon />
      case 'SEPA':
        return <SepaIcon />
      default:
        return <IbanIssuingLocalIcon />
    }
  }

  const hoverAttributes = () => {
    return {
      onMouseOver: event => {
        const target = event.target as HTMLSpanElement
        event.stopPropagation()
        if (target.clientWidth > target.parentElement.clientWidth) {
          target.style.transform = `translateX(${-(target.clientWidth - target.parentElement.clientWidth + 24)}px)`
        }
      },
      onMouseLeave: event => {
        const target = event.target as HTMLSpanElement
        target.style.transform = `translateX(0px)`
      }
    }
  }

  return (
    <div
      style={{
        height: enableCollapse && collapse ? `${8.8 * options.length}rem` : '',
        overflow: enableCollapse && !collapse ? `hidden` : 'auto'
      }}
      className={cn('iban-payment-methods-wrapper')}
      ref={paymentMethodsContainer}>
      {enableCollapse && selectedOption && (
        <div className={cn('iban-payment-method-wrapper full-width selected')} onClick={() => setCollapse(true)}>
          <div className='payment-method-icon-wrapper'>{PaymentOptionIcon(selectedOption)}</div>
          <div className='payment-method-content'>
            <div className='payment-method-title-wrapper'>
              <span {...hoverAttributes()}>{tr(`METADATA.PAYMENT_OPTIONS.${selectedOption}`, selectedOption)}</span>
            </div>
          </div>
        </div>
      )}
      {isValidArray(filteredOptions) &&
        filteredOptions.map(option => {
          return (
            <div
              key={option}
              onClick={() => setSelectedOption(option)}
              date-test={dataTest}
              className={cn('iban-payment-method-wrapper', {
                'full-width': options.length === 1,
                selected: !enableCollapse && selectedOption && selectedOption === option,
                disabled: disabled
              })}>
              <div className='payment-method-icon-wrapper'>{PaymentOptionIcon(option)}</div>
              <div className='payment-method-content'>
                <div className='payment-method-title-wrapper'>
                  <span {...hoverAttributes()}>{option || tr(`METADATA.PAYMENT_OPTIONS.${option}`, option)}</span>
                </div>
              </div>
            </div>
          )
        })}
    </div>
  )
}
