import { Redirect, Route, Switch } from 'react-router-dom'

import { paths, selectCurrentUserRole, useAppSelector } from 'mmfintech-backend-api'
import { CustomerRoleEnum } from 'mmfintech-commons-types'

import { ProfileNavigation } from './ProfileNavigation'
import { Profile } from '../Profile/Profile'
import { Payments } from '../PaymentInfo/PaymentInfo'
import Security from '../Security/Security'

import './profileSettings.scss'

const ProfileSettings = () => {
  const customerRole = useAppSelector(selectCurrentUserRole)

  return (
    <div className='settings-wrapper'>
      <ProfileNavigation />

      <Switch>
        <Route path={paths.profile() + '/details'} exact component={Profile} />
        <Route path={paths.profile() + '/security'} exact component={Security} />
        <Route path={paths.profile() + '/payment'} exact component={Payments} />
        <Redirect
          to={
            paths.profile() +
            (customerRole === CustomerRoleEnum.SUBMITTER || customerRole === CustomerRoleEnum.APPROVER
              ? '/security'
              : customerRole === CustomerRoleEnum.STAFF
              ? '/security'
              : '/details')
          }
        />
      </Switch>
    </div>
  )
}

export default ProfileSettings
