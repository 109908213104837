import { useState } from 'react'

import { tr } from 'mmfintech-commons'

import { CounterpartyCreateModal } from '../CounterpartyCreateModal'
import { CoreButton, CoreModalDialog } from '@components'

import AddIcon from '@images/icons/cross-icon.svg?react'

import './counterpartyViewHeader.scss'

export const CounterpartyViewHeader = () => {
  const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false)

  const handleCreateModalOpen = () => setIsCreateModalVisible(true)
  const handleCreateModalClose = () => setIsCreateModalVisible(false)

  return (
    <div className='counterparty-header'>
      <CoreButton
        text={tr('FRONTEND.EXPENSE_MANAGEMENT.TITLE.NEW_COUNTERPARTY', 'New counterparty')}
        LeftIcon={<AddIcon />}
        data-test='button-add-counterparty'
        onClick={handleCreateModalOpen}
        size='large'
      />
      <CoreModalDialog
        header={`${tr('FRONTEND.COUNTERPARTY.ACTION.NEW', 'New')} ${tr(
          'FRONTEND.EXPENSE_MANAGEMENT.TITLE.COUNTERPARTY',
          'Counterparty'
        )}`}
        options={{ closeOnEscape: false, closeOnClickOutside: false }}
        visible={isCreateModalVisible}
        content={<CounterpartyCreateModal onClose={handleCreateModalClose} />}
        onClose={handleCreateModalClose}
      />
    </div>
  )
}
