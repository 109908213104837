import { useTransactionFilesManagementQry } from 'mmfintech-backend-api'
import { isValidArray, tr } from 'mmfintech-commons'

import DeleteIcon from '@images/icons/batch-delete.svg?react'
import UploadedIcon from '@images/icons/batch-uploaded.svg?react'

import './uploadedFilesPage.scss'

//for Bank transfer documents

export const UploadedFilesPane = () => {
  const { uploadedFiles, removeFile } = useTransactionFilesManagementQry({})

  return isValidArray(uploadedFiles) ? (
    <>
      {uploadedFiles.map(file => (
        <div className='uploaded-file-wrapper' key={file.id}>
          <div className='uploaded-content'>
            <UploadedIcon />
            <div className='uploaded-content-right'>
              <span className='uploaded-text'>{tr('FRONTEND.FILE_UPLOAD.FILE_LABEL', 'File uploaded')}!</span>
              <span className='file-name'>{file.fileName}</span>
            </div>
          </div>
          <div
            className='delete-icon-wrapper'
            onClick={() => {
              removeFile(file.id)
            }}>
            <DeleteIcon />
          </div>
        </div>
      ))}
    </>
  ) : null
}
