import styled from 'styled-components'

export const ReportsWrapper = styled.div`
  grid-area: main-content;

  display: flex;
  flex-direction: column;

  max-width: 100vw;
  padding: 0 3rem;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1320px) {
    min-width: 120rem;
  }

  @media (max-width: 1175px) {
    min-width: 80rem;
  }

  .loader {
    align-self: center;
    padding-top: 5rem;
  }

  .input {
    background: none;
    border: none;
    border-bottom: 1px solid rgba(163, 184, 194, 0.5);
    width: 35rem;
    height: 4rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.7rem;
    color: #85a0ad;

    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      color: #85a0ad;
    }
  }

  @media (max-width: 800px) {
    min-width: auto;
  }

  .userRow {
    font-size: 1.2rem;
  }

  .userCell {
    font-size: 1.2rem;
  }

  .actionCell {
    width: 100px;
    @media (max-width: 1368px) {
      width: auto;
      flex-direction: row;
      justify-content: center;
      border: none;
    }
  }

  .headRow {
    font-size: 1.4rem;
    border-bottom: 1px solid #e3eaed !important;
  }

  .actionIcons {
    margin-right: 15px;
  }

  .actionIconsHover:hover {
    cursor: pointer;
  }
  .data-table-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    max-width: 20rem;
  }
  .data-table-body > .data-table-row:hover {
    @media (max-width: 1000px) {
      border-bottom: 1px solid #808080 !important;
    }
  }
`

export const UsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent;
  min-height: 63.7rem;

  .table-label {
    color: #808080;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3rem;
    text-transform: uppercase;
  }

  @media (max-width: 960px) {
    background: transparent;
    box-shadow: none;
  }

  .mobile-container {
    @media (max-width: 960px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`

export const LabelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #282828;
  padding: 1rem 18rem 1rem 3rem;

  @media (max-width: 960px) {
    display: none;
  }
`
