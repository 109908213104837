import { useState } from 'react'
import { Link } from 'react-router-dom'

import { LineChart, Line, XAxis, Tooltip, Dot, ResponsiveContainer, YAxis } from 'recharts'
import moment from 'moment'
import cn from 'classnames'

import { paths, useGetAggregatedBalanceQuery, useGetAggregatedBalanceTotalQuery } from 'mmfintech-backend-api'
import {
  extractCurrencyCode,
  formatFloat,
  getCurrencyDecimals,
  isValidArray,
  tr
} from 'mmfintech-commons'

import './CoreGraphSection.scss'

import { CoreTabs } from '../CoreTabs'
import { CoreButton } from '../CoreButton'

import CrossIcon from '@images/icons/cross-icon.svg?react'
import ExchangeIcon from '@images/icons/exchange-icon.svg?react'
import ArrowIcon from '@images/icons/arrow-icon.svg?react'
import ArrowDownIcon from '@images/icons/arrow-down-icon.svg?react'
import { useMatchMedia } from '@hooks'
import { breakpoints } from '@constants'

const tabs = [
  { label: 'All', value: 'All' },
  { label: 'Crypto', value: 'Crypto' },
  { label: 'Fiat', value: 'Fiat' }
]
const paymentButtonProps = [
  { path: paths.banking.deposit(), label: 'Deposit', Icon: CrossIcon },
  { path: paths.banking.exchange(), label: 'Exchange', Icon: ExchangeIcon },
  { path: paths.banking.send(), label: 'Send', Icon: ArrowIcon }
]

export const CoreGraphSection = () => {
  const [currentTab, setCurrentTab] = useState<string>('All')
  const isMobile = useMatchMedia({ breakpoint: breakpoints.MAX_MOBILE_WIDTH, prefix: 'max' })
  const isSmallScreen = useMatchMedia({ breakpoint: 436, prefix: 'max' })

  const { aggregatedBalance } = useGetAggregatedBalanceQuery(
    {
      dateFrom: moment().subtract(6, 'days').format('YYYY-MM-DD'),
      dateTo: moment().format('YYYY-MM-DD'),
      currencyType: currentTab === 'All' ? undefined : currentTab == 'Crypto' ? 'CRYPTO' : 'FIAT'
    },
    {
      selectFromResult: ({ data }) => ({
        aggregatedBalance: isValidArray(data)
          ? data.map(el => {
              return {
                date: moment(el.date).format('D'),
                amount: el.amount,
                currencyCode: el.currencyCode
              }
            })
          : []
      })
    }
  )

  const { totalAggregatedBalance } = useGetAggregatedBalanceTotalQuery(
    {
      dateFrom: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      dateTo: moment().format('YYYY-MM-DD'),
      currencyType: currentTab === 'All' ? undefined : currentTab == 'Crypto' ? 'CRYPTO' : 'FIAT'
    },
    {
      selectFromResult: ({ data }) => ({
        totalAggregatedBalance: isValidArray(data)
          ? data.map(el => {
              return {
                date: moment(el.date).format('D'),
                amount: el.amount,
                currencyCode: el.currencyCode
              }
            })
          : []
      })
    }
  )

  const minValue = () => {
    if (isValidArray(aggregatedBalance)) {
      const minVal = Math.min(...aggregatedBalance.map(balance => balance.amount))
      const average =
        [...aggregatedBalance.map(balance => balance.amount)].reduce((p, c) => p + c, 0) / aggregatedBalance.length
      const percentage = (average - minVal) / average
      return minVal * (1 - percentage / 3)
    }
  }

  const maxValue = () => {
    if (isValidArray(aggregatedBalance)) {
      return Math.max(...aggregatedBalance.map(balance => balance.amount))
    }
  }

  return (
    <div className='graph-container'>
      <div className='graph-header'>
        {!isSmallScreen && <h6>{tr('FORNTEND_GRAPH.OVERVIEW_TITLE', 'Overview')}</h6>}
        <CoreTabs tabs={tabs} onChangeTab={setCurrentTab} tabState={currentTab} />
        <div className='graph-divider'></div>
      </div>
      <div className='graph-content-container'>
        <div className='graph-left-side'>
          <div className='graph-left-side-header'>
            <div className='graph-title'>{`${currentTab} ${currentTab === 'All' ? 'balances' : 'balance'}`}</div>
            <span className='graph-amount'>
              {isValidArray(aggregatedBalance) &&
                formatFloat(
                  aggregatedBalance[aggregatedBalance.length - 1].amount,
                  getCurrencyDecimals(extractCurrencyCode(aggregatedBalance[aggregatedBalance.length - 1]))
                )}
              {isValidArray(aggregatedBalance) && (
                <span className='graph-amount-currency'>
                  {extractCurrencyCode(aggregatedBalance[aggregatedBalance.length - 1])}
                </span>
              )}
            </span>
          </div>
          <div className='graph-payment-buttons'>
            {paymentButtonProps.map(({ path, label, Icon }) => {
              return (
                <Link className='graph-payment-button-link' to={path} key={'key-' + label}>
                  <div className='graph-payment-button-wrapper'>
                    <CoreButton
                      text={label}
                      style={{
                        borderRadius: '16px'
                      }}
                      variation='elevated'
                      size='large'
                      CollapsedIcon={
                        <Icon
                          style={{
                            filter:
                              ' brightness(0) saturate(100%) invert(47%) sepia(68%) saturate(5077%) hue-rotate(336deg) brightness(96%) contrast(96%)'
                          }}
                        />
                      }
                      collapsed
                    />
                    <span>{label}</span>
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
        {!isMobile && (
          <div className='graph-right-side'>
            <ResponsiveContainer width='100%' height={211}>
              <LineChart data={isValidArray(totalAggregatedBalance) ? totalAggregatedBalance : aggregatedBalance}>
                <Tooltip
                  content={
                    <CustomTooltip
                      balanceArray={isValidArray(totalAggregatedBalance) ? totalAggregatedBalance : aggregatedBalance}
                    />
                  }
                  cursor={<CustomCursor />}
                />
                <YAxis hide={true} domain={[minValue(), maxValue()]} />
                <XAxis
                  dataKey='date'
                  allowDecimals={false}
                  axisLine={false}
                  tickLine={false}
                  fontSize={14}
                  fontWeight={700}
                  padding={{ left: 10, right: 10 }}
                />
                <Dot r={1} />
                <Line
                  isAnimationActive={false}
                  type='monotone'
                  dataKey='amount'
                  stroke='red'
                  strokeWidth={2}
                  dot={null}
                  activeDot={{ r: 3, fill: '#fff', stroke: 'red', strokeWidth: 2 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    </div>
  )
}

const CustomTooltip = ({ balanceArray, ...external }) => {
  const { amount, currencyCode, date } = external?.payload?.[0]?.payload || {}
  const selectedElement = balanceArray.find(el => el.date == date)
  const selectedIndex = balanceArray.indexOf(selectedElement)

  const isPositive = () => {
    if (selectedIndex == 0) return true
    if (selectedIndex >= 0) {
      return amount > balanceArray[selectedIndex - 1].amount
    }
    return false
  }

  return (
    <div className='custom-tooltip'>
      <div className={cn('icon', { positive: isPositive(), negative: !isPositive() })}>
        <ArrowDownIcon />
      </div>
      <div>
        {amount} <span className='custom-tooltip-currency'>{currencyCode}</span>
      </div>
    </div>
  )
}

const CustomCursor = props => {
  const dot = document.getElementsByClassName('recharts-active-dot')[0]

  if (dot) {
    const activeDot = dot.childNodes[0] as HTMLElement
    const activeDotPos = activeDot?.getAttribute('cy')
    const { points } = props
    const startingPoint = points[0]
    const endingPoint = points[1]
    const [x1, y1] = [startingPoint.x, Number(activeDotPos)]
    const [x2, y2] = [endingPoint.x, endingPoint.y]
    if (activeDot?.getAttribute('cx') != endingPoint.x) {
      return null
    }
    return (
      <>
        <svg x1={x1} y1={y1} x2={x2} y2={y2}>
          <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={'rgba(255, 0, 0, 1)'} strokeWidth={1} />
        </svg>
        <circle cx={x1} cy='205' r='3' fill='red'></circle>
      </>
    )
  }
  return null
}
