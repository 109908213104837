import { ReactNode } from 'react'

import NoReportImage from '@images/expenses/no-reports-icon.svg?react'

import './noReports.scss'

type TNoReportsComponentProps = {
  title?: string
  children?: ReactNode
}

export const NoReportsComponent = ({ title, children }: TNoReportsComponentProps) => {
  return (
    <tbody>
      <tr>
        <td colSpan={10}>
          <div className='no-reports-wrapper'>
            <NoReportImage />
            {title && <p>{title}</p>}
            {children && children}
          </div>
        </td>
      </tr>
    </tbody>
  )
}
