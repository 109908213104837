import { ReactNode } from 'react'

import { useGetMerchantAttributesQuery } from 'mmfintech-backend-api'
import { getCountryName, tr } from 'mmfintech-commons'
import { RecipientOut } from 'mmfintech-commons-types'
import HintIcon from '@images/icons/hint_select_role_icon.svg?react'

import { CoreCurrencyIcon } from '@components'

import './paymentInfo.scss'
import ReactTooltip from 'react-tooltip'

const preparedToolTip = `
  <h2> ${tr('FRONTEND.USERS_MANAGEMENT.PAYMENT_DETAILS.HEADER_LABEL', 'Primary currency')}</h2>
  - ${tr(
    'FRONTEND.USERS_MANAGEMENT.PAYMENT_DETAILS.ADMIN.LINE1',
    'The Primary Account Currency refers to the main currency'
  )} <br/>
  ${tr(
    'FRONTEND.USERS_MANAGEMENT.PAYMENT_DETAILS.ADMIN.LINE2',
    'in which your account is denominated and all balances are maintained.'
  )} <br/>
  - ${tr('FRONTEND.USERS_MANAGEMENT.PAYMENT_DETAILS.ADMIN.LINE3', 'It is the default currency for processing,')} <br/>
  - ${tr(
    'FRONTEND.USERS_MANAGEMENT.PAYMENT_DETAILS.ADMIN.LINE4',
    'balance overviews and reporting related to your account.'
  )}
`

export const PaymentPreview = ({ recipient }: { recipient: RecipientOut }) => {
  const { data: userAttributes, isSuccess } = useGetMerchantAttributesQuery()
  const { preferredCurrency } = isSuccess && userAttributes

  const {
    accountNumber,
    bankCode,
    bankName,
    branchCode,
    country,
    currency,
    documentId,
    holderName,
    accountType,
    holderEmail,
    iban
  } = recipient || {}

  return (
    <div className='bank-metadata-view'>
      <DetailsRow
        label={tr('FRONTEND.RECIPIENTS.LABELS.PRIMARY_CURRENCY', 'Primary currency')}
        value={
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <div className='info' data-for='info-tooltip' data-tip={preparedToolTip}>
              <HintIcon />
            </div>
            <CoreCurrencyIcon currency={currency || preferredCurrency} circle />
            <span style={{ fontSize: '16px' }}> {currency || preferredCurrency}</span>
          </div>
        }
      />
      <DetailsRow label={tr('FRONTEND.RECIPIENTS.LABELS.ACCOUNT_NAME', 'Beneficiary Name')} value={holderName} />
      <DetailsRow value={getCountryName(country)} label={tr('FRONTEND.RECIPIENTS.LABELS.BANK_COUNTRY', 'Country')} />
      <DetailsRow value={accountNumber} label={tr('FRONTEND.RECIPIENTS.LABELS.ACCOUNT_NUMBER', 'Account number')} />
      <DetailsRow value={bankCode} label={tr('FRONTEND.RECIPIENTS.LABELS.BANK_CODE', 'Bank code')} />
      <DetailsRow value={bankName} label={tr('FRONTEND.RECIPIENTS.LABELS.BANK_NAME', 'Bank Name')} />
      <DetailsRow value={iban} label={'Iban'} />
      <DetailsRow value={holderEmail} label={tr('FRONTEND.RECIPIENTS.LABELS.EMAIL', 'Email')} />
      <DetailsRow value={branchCode} label={tr('FRONTEND.RECIPIENTS.LABELS.BRANCH_CODE', 'Branch code')} />
      <DetailsRow value={documentId} label={tr('FRONTEND.RECIPIENTS.LABELS.NATIONAL_ID', 'National ID')} />
      <DetailsRow value={accountType} label={tr('FRONTEND.RECIPIENTS.LABELS.ACCOUNT_TYPE', 'Account type')} />
      <ReactTooltip
        className='payment-preview-tooltip'
        id='info-tooltip'
        place='bottom'
        html
        type='dark'
        arrowColor='transparent'
      />
    </div>
  )
}

const DetailsRow = ({
  label,
  value,
  className = ''
}: {
  label: string
  value: string | number | ReactNode
  className?: string
}) => {
  if (!label || !value) return null

  return (
    <div className={`details-row ${className}`}>
      <span className='label'>{label}</span>
      <span className='value'>{value}</span>
    </div>
  )
}
