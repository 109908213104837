import { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Button } from 'mmfintech-portal-commons'

import { SuccessImage } from '../../icons'
import { SuccessSubtitle, SuccessContainer } from './useOnboarding.styled'

import {
  actions,
  api,
  paths,
  useApplicationFeeInitiateMutation,
  useGetMerchantQuery,
  useMerchantAccounts,
  useOnboardingBannerQry
} from 'mmfintech-backend-api'
import { GlobalContext, tr, OtpContext, isValidString, extractCurrencyCode, isValidObject } from 'mmfintech-commons'

import { AccountBalanceOut, MerchantAccountTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'
import ApplicationFeeDetailsModal from '@views/new_onboarding/elements/ApplicationFeeDetailsModal'
import ApplicationFeeInfoModal from '@views/new_onboarding/elements/ApplicationFeeInfoModal'

const errorTimeout = 1000 * 3 // 3 seconds

interface UseOnboardingOutput {
  showError: boolean
  shouldRequestIban: () => boolean
  payAccountSetupFee: () => void
  shouldPayAccountSetupFee: () => boolean
  startOrContinueOnboarding: (status?: string) => void
}

export const useOnboarding = (): UseOnboardingOutput => {
  const { modalHide, modalShow } = useContext(GlobalContext)
  const { setOtpOnSuccess } = useContext(OtpContext)

  const { data: merchant } = useGetMerchantQuery(null)
  const applicationFeeInitiateError = null // get applicationFeeInitiateError from OTP when paying

  const [showError, setShowError] = useState(true)

  const history = useHistory()
  const dispatch = useDispatch()

  const { accountSetupFee, capabilities, entityType, accountType, onboardingStatus } = merchant || {}
  const { ibanEligible } = capabilities || {}
  const { accounts } = useMerchantAccounts()
  const [applicationFeeInitiate] = useApplicationFeeInitiateMutation()

  const startSumSubOnboarding = () => {
    if (isValidString(entityType)) {
      if (accountType === MerchantAccountTypeEnum.PROSPECT && onboardingStatus === OnboardingStatusEnum.NOT_STARTED) {
        history.push(paths.onboarding.selectType())
      }
    } else {
      history.push(paths.onboarding.selectType())
    }
  }

  const { startOrContinueOnboarding } = useOnboardingBannerQry({
    startOnboarding: startSumSubOnboarding
  })

  const SuccessMessage = () => {
    const handleBack = () => {
      modalHide()
    }

    return (
      <SuccessContainer className='centered' data-test='application-fee-paid-success'>
        <SuccessImage />
        <SuccessSubtitle className='title'>
          {tr('FRONTEND.PAYMENT.SUCCESS', 'Your Application Fee has been paid successfully')}
        </SuccessSubtitle>
        <Button
          type='button'
          color='primary'
          text={tr('FRONTEND.EXCHANGE.BUTTON_OK', 'Ok')}
          onClick={handleBack}
          data-test='button-ok'
        />
      </SuccessContainer>
    )
  }

  const showFeePaymentSuccessModal = () => {
    modalShow({
      options: {
        size: 'auto',
        transparent: true,
        closeOnClickOutside: false,
        closeOnEscape: false
      },
      content: <SuccessMessage />
    })
  }

  const payAccountSetupFee = () => {
    modalHide()
    dispatch(actions.banking.depositCleanup())
    setShowError(true)

    const { amount, currency } = accountSetupFee || {}
    const euroAccountWithFunds = accounts?.find(
      (account: AccountBalanceOut) => extractCurrencyCode(account) === 'EUR' && account.availableBalance >= amount
    )

    if (euroAccountWithFunds) {
      const handleSubmit = async () => {
        setOtpOnSuccess(() => () => {
          showFeePaymentSuccessModal()
          dispatch(api.util.invalidateTags(['Merchant']))
        })
        await applicationFeeInitiate(null).unwrap().then()
      }
      modalShow({
        header: tr('FRONTEND.ONBOARDING.APPLICATION_FEE ', 'Application fee'),
        content: (
          <ApplicationFeeInfoModal amount={amount} currency={currency} onClose={modalHide} onSubmit={handleSubmit} />
        ),
        options: {
          closeOnClickOutside: false
        }
      })
    } else {
      modalShow({
        header: tr('FRONTEND.ONBOARDING.APPLICATION_FEE ', 'Application fee'),
        content: <ApplicationFeeDetailsModal amount={amount} currency={currency} />,
        options: {
          closeOnClickOutside: true
          // onClose: () => dispatch(actions.banking.depositCleanup())
        }
      })
    }
  }

  const shouldPayAccountSetupFee = () => isValidObject(accountSetupFee)

  const shouldRequestIban = () => ibanEligible

  useEffect(() => {
    if (applicationFeeInitiateError) {
      setTimeout(() => {
        setShowError(false)
      }, errorTimeout)
    }
  }, [applicationFeeInitiateError])

  return {
    showError,
    shouldRequestIban,
    payAccountSetupFee,
    shouldPayAccountSetupFee,
    startOrContinueOnboarding
  }
}
