import { SelectionElement } from '@components'
import './OtherCurrencies.scss'
import { isValidArray } from 'mmfintech-commons'

export const OtherCurrencies = ({ currencies, onClick, setInputFocused }) => {
  if (isValidArray(currencies)) {
    return (
      <>
        <div className='other-currency-label'> Other currencies </div>
        {currencies.map(option => {
          const { currencyFullName } = option

          return (
            <SelectionElement
              data-test='selected-content'
              key={currencyFullName}
              data={option}
              type='account'
              hideCaption={false}
              onSelect={() => {
                onClick(null,{ ...option, name: currencyFullName })
              }}
              setInputFocused={setInputFocused}
            />
          )
        })}
      </>
    )
  }

  return null
}
