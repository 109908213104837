import { useLazyRevealVoucherPinQuery } from 'mmfintech-backend-api'
import { formatFloat, getCurrencyDecimals, GlobalContext, tr } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import cn from 'classnames'
import { useDownloadVoucherCsv } from './useDownloadVoucherCsv'
import { RedeemModal } from '../redeemModal/RedeemModal'
import { CoreStatusView } from '@components'

import ClosedEye from '@images/icons/eye-closed.svg?react'
import OpenEye from '@images/icons/eye-opened.svg?react'
import CalendarOpenIcon from '@images/icons/calendar_open_icon.svg?react'
import ScanIcon from '@images/icons/scan_icon.svg?react'
import RedeemIcon from '@images/icons/redeem_voucher_icon.svg?react'
import VoucherExportIcon from '@images/icons/voucher_export_icon.svg?react'

import './SingleVoucher.scss'

interface SingleVoucherContainerProps {
  data: any
  readMore: (params?: any) => Promise<void>
  isActiveVouchers: boolean
}

export const SingleVoucherContainer = ({ data, readMore, isActiveVouchers }: SingleVoucherContainerProps) => {
  const [showPin, setShowPin] = useState(false)
  const { voucherType, amount, currency, expiryDate, totalVouchers, uuid, slipId, voucherNumber, status } = data || {}
  const { modalShow } = useContext(GlobalContext)
  const { handleExportAsCsv } = useDownloadVoucherCsv({ slipId })

  const [revealPin, { voucherPin, isError, error }] = useLazyRevealVoucherPinQuery({
    selectFromResult: ({ data, ...rest }) => ({
      voucherPin: data?.securityCode || '',
      ...rest
    })
  })

  const handleReloadVouchers = () => {
    readMore({ page: 0, size: isActiveVouchers ? 5 : 10 })
  }

  const handleRevealPin = () => {
    if (!showPin && !voucherPin) {
      return revealPin({ uuid: uuid })
    }

    setShowPin(prev => !prev)
  }

  useEffect(() => {
    if (voucherPin && !showPin) {
      setShowPin(true)
    }
  }, [voucherPin])

  useEffect(() => {
    if (isError) {
      toast.remove()
      if ('error' in error) {
        toast.error(error.error)
      } else {
        toast.error(<ErrorDisplay error={error} hideIcon />)
      }
    }
  }, [isError])

  const handleRedeemVoucher = () => {
    modalShow({
      content: <RedeemModal voucherId={uuid} currency={currency} handleReloadVouchers={handleReloadVouchers} />,
      header: tr('FRONTEND.YOUR_VOUCHER.REDEEM_VOUCHER', 'Redeem Voucher'),
      options: { closeOnClickOutside: true }
    })
  }

  return (
    <>
      {isActiveVouchers ? (
        <div key={uuid} className='single-active-voucher-container'>
          <div className={cn('single-voucher-top-banner', { bulk: voucherType === 'BULK' })}></div>
          <div className='single-voucher-top-side'>
            <div className='single-voucher-amount'>
              <span className='amount-title'>{tr('FRONTEND.YOUR_VOUCHER.AMOUNT_TITLE', 'Amount')}</span>
              <span className='amount-content'>
                {formatFloat(amount, getCurrencyDecimals(currency))}{' '}
                <span className='amount-content-currency'>{currency}</span>
              </span>
            </div>
            <div onClick={() => voucherType === 'REGULAR' && handleRevealPin()} className='single-voucher-pin-wrapper'>
              <div className='single-voucher-pin'>
                <span>PIN</span>
                <span className='single-voucher-pin-security'>****</span>
              </div>
              {voucherType === 'REGULAR' ? (
                <div className={cn('single-voucher-pin-icon', { left: showPin })}>
                  {showPin ? <OpenEye /> : <ClosedEye />}
                </div>
              ) : (
                <div className={cn('single-voucher-pin-icon', { left: true, bulk: true })}>
                  <span className='single-voucher-pin-icon-number'>{totalVouchers}</span>
                </div>
              )}
              <div
                className={cn('single-voucher-received-pin-wrapper', { show: showPin, bulk: voucherType === 'BULK' })}>
                <div className='single-voucher-received-pin-overlay'></div>
                <span className='single-voucher-received-pin'>
                  {voucherType === 'REGULAR' ? voucherPin : 'Number of Vouchers'}
                </span>
              </div>
            </div>
          </div>
          <div className='single-voucher-bottom-side'>
            <span className='single-voucher-bottom-side-title'>
              {tr('FRONTEND.YOUR_VOUCHER.VOUCHER_DETAILS', 'Voucher Details')}
            </span>
            <div className='single-voucher-bottom-side-container'>
              <div className='single-voucher-bottom-side-detail-wrapper'>
                <div className='single-voucher-bottom-side-content'>
                  <div className='single-voucher-bottom-side-wrapper'>
                    <div className='single-voucher-bottom-side-icon'>
                      <CalendarOpenIcon />
                    </div>
                    <div className='single-voucher-bottom-side-detail-container'>
                      <span className='single-voucher-bottom-side-detail-title'>
                        {tr('FRONTEND.YOUR_VOUCHER.EXPIRATION_DATE_LABEL', 'Expiration Date:')}
                      </span>
                      <span className='single-voucher-bottom-side-detail-content'>{expiryDate}</span>
                    </div>
                  </div>
                </div>
                {voucherType === 'REGULAR' && (
                  <div className='single-voucher-bottom-side-content'>
                    <div className='single-voucher-bottom-side-wrapper'>
                      <div className='single-voucher-bottom-side-icon'>
                        <ScanIcon />
                      </div>
                      <div className='single-voucher-bottom-side-detail-container'>
                        <span className='single-voucher-bottom-side-detail-title'>
                          {tr('FRONTEND.YOUR_VOUCHER.VOUCHER_NUMBER_LABEL', 'Voucher Number:')}
                        </span>
                        <span className='single-voucher-bottom-side-detail-content'>{voucherNumber}</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                onClick={() => (voucherType === 'BULK' ? handleExportAsCsv() : handleRedeemVoucher())}
                className='single-voucher-bottom-side-redeem-button'>
                <div className={cn('single-voucher-bottom-side-redeem-button-icon', { bulk: voucherType === 'BULK' })}>
                  {voucherType === 'REGULAR' ? <RedeemIcon /> : <VoucherExportIcon />}
                </div>
                <span>{tr('FRONTEND.YOUR_VOUCHER.REDEEM_VOUCHER', 'Redeem Voucher')}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div key={uuid} className='single-history-voucher-container'>
          <div className='single-history-voucher-amount'>
            <span className='single-history-voucher-amount-title'>
              {tr('FRONTEND.YOUR_VOUCHER.AMOUNT_TITLE', 'Amount')}
            </span>
            <span className='single-history-voucher-amount-content'>
              {formatFloat(amount, getCurrencyDecimals(currency))}{' '}
              <span className='amount-content-currency'>{currency}</span>
            </span>
          </div>
          <div className='single-history-voucher-details'>
            <div className='single-voucher-details-icon'>
              <CalendarOpenIcon />
            </div>
            <div className='single-voucher-details-container'>
              <span className='single-voucher-details-title'>
                {tr('FRONTEND.YOUR_VOUCHER.EXPIRATION_DATE_LABEL', 'Expiration Date:')}
              </span>
              <span className='single-voucher-details-content'>{expiryDate}</span>
            </div>
          </div>
          {voucherType === 'REGULAR' ? (
            <div className='single-history-voucher-details'>
              <div className='single-voucher-details-icon'>
                <ScanIcon />
              </div>
              <div className='single-voucher-details-container'>
                <span className='single-voucher-details-title'>
                  {tr('FRONTEND.YOUR_VOUCHER.VOUCHER_NUMBER_LABEL', 'Voucher Number:')}
                </span>
                <span className='single-voucher-details-content'>{voucherNumber}</span>
              </div>
            </div>
          ) : (
            <div className='single-history-voucher-details'>
              <div className='single-voucher-details-icon'>
                <ScanIcon />
              </div>
              <div className='single-voucher-details-container'>
                <span className='single-voucher-details-title'>
                  {tr('FRONTEND.YOUR_VOUCHER.TOTAL_VOUCHERS', 'Total Vouchers')}
                </span>
                <span className='single-voucher-details-content'>{totalVouchers}</span>
              </div>
            </div>
          )}
          <div className='single-history-voucher-status'>
            <CoreStatusView fade size='medium' status={status} />
          </div>
        </div>
      )}
    </>
  )
}
