import { CoreButton, CoreInput } from '@components'
import { useEditExternalWalletQry } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'
import { ExternalWalletTypeEnum } from 'mmfintech-commons-types'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import React, { useEffect } from 'react'

export const PerfectMoneyRecipientEdit = ({ recipientId, currencyCode, onClose, onSuccess }) => {
  const { formValues, handleSubmit, error, fetching, resetMutations } = useEditExternalWalletQry({
    type: ExternalWalletTypeEnum.PERFECT_MONEY,
    recipientId,
    currencyCode,
    onClose,
    onSuccess
  })

  const handleSubmitInternal = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSubmit()
  }

  useEffect(() => {
    return () => {
      resetMutations()
    }
  }, [])
  return (
    <div className='recipient-edit-wrapper' data-test='perfect-money-recipient-edit-modal'>
      <div className='recipient-edit-title'>
        {tr('FRONTEND.RECIPIENTS.LABELS.PM_INFORMATION', 'Payment information')}
      </div>
      <form className='recipient-edit-form' noValidate onSubmit={handleSubmitInternal}>
        <CoreInput
          type='text'
          data-test='name'
          label={tr('FRONTEND.RECIPIENTS.PERFECT_MONEY.NAME', 'Name')}
          maxLength={100}
          {...formValues.registerInput('name')}
          autoComplete='off'
        />

        <CoreInput
          type='text'
          data-test='identifier'
          label={tr('FRONTEND.RECIPIENTS.PERFECT_MONEY.IDENTIFIER', 'Account ID')}
          maxLength={100}
          {...formValues.registerInput('identifier')}
          autoComplete='off'
        />

        <CoreInput
          type='text'
          data-test='description'
          label={tr('FRONTEND.RECIPIENTS.PERFECT_MONEY.DESCRIPTION', 'Description')}
          maxLength={100}
          {...formValues.registerInput('description')}
          autoComplete='off'
        />

        <ErrorDisplay error={error} />

        <div className='recipient-edit-actions'>
          <CoreButton
            data-test='save-button'
            variation='primary'
            size='large'
            text={tr('FRONTEND.RECIPIENTS.BUTTON.SAVE', 'Save')}
            className='save-button'
            onClick={async e => {
              e.preventDefault()
              handleSubmit()
            }}
            isLoading={fetching}
            disabled={fetching}
            fullWidth
          />
        </div>
      </form>
    </div>
  )
}
