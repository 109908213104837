import cn from 'classnames'

import { ICustomCheckboxProps } from './CustomCheckbox.types'

import './customCheckbox.scss'

import CheckedIcon from '@images/icons/checked.svg?react'
import UncheckedIcon from '@images/icons/unchecked.svg?react'

export const CustomCheckbox = ({ icon, caption, checked, toggle, dataTest, optional }: ICustomCheckboxProps) => {
  return (
    <div className={`custom-checkbox-wrapper ${checked ? 'checked' : ''}`}>
      <div onClick={toggle} className='custom-checkbox-container'>
        <div className='custom-checkbox-label'>
          {icon && <span className={'icon'}>{icon}</span>}
          <span className='custom-checkbox-caption'>{caption}</span>
        </div>
        <span className={cn('checkbox', { checked })} data-test={dataTest}>
          {checked ? <CheckedIcon /> : <UncheckedIcon />}
        </span>
      </div>
      {checked ? optional : null}
    </div>
  )
}
