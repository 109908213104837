import { useHistory } from 'react-router-dom'

import { tr } from 'mmfintech-commons'
import { paths } from 'mmfintech-backend-api'

import { CoreButton } from '@components'
import { breakpoints } from '@constants'
import { useMatchMedia } from '@hooks'

import UploadInvoiceIcon from '@images/icons/upload-invoice-icon.svg?react'
import NewInvoiceIcon from '@images/icons/create-invoice-icon.svg?react'

import './invoiceActions.scss'

export const InvoiceActions = () => {
  const history = useHistory()
  const isMobile = useMatchMedia({ breakpoint: breakpoints.md, prefix: 'max' })

  return (
    <div className='invoice-action-buttons'>
      <CoreButton
        LeftIcon={<NewInvoiceIcon />}
        text={tr('FRONTEND.INVOICES.LIST.BUTTON_CREATE', 'Create Invoice')}
        onClick={() => {
          history.push(paths.invoices.create())
        }}
        size={isMobile ? 'normal' : 'large'}
        data-test='new-invoice-button'
      />

      <CoreButton
        LeftIcon={<UploadInvoiceIcon />}
        text={tr('FRONTEND.DASHBOARD.BUTTON_UPLOAD', 'Upload Invoice')}
        onClick={() => {
          history.push(paths.invoices.upload())
        }}
        size={isMobile ? 'normal' : 'large'}
        data-test='upload-invoice-button'
      />
    </div>
  )
}
