import FlagIcon from '../FlagIcon'

import { formatMoney, tr } from 'mmfintech-commons'
import cn from 'classnames'
import './selectionElements.scss'
import type { SelectionElementProps } from './selectionTypes'

import ArrowDownIcon from '@images/icons/chevron-down-black.svg?react'
import DeleteIcon from '@images/icons/multiselect-delete-icon.svg?react'
import { CoreCurrencyIcon } from '@components'

export const SelectionElement = ({
  onSelect,
  data,
  selectedList,
  type,
  setInputFocused,
  isSelected = false,
  hideCaption,
  inSelectedList = false,
  isCountryType,
  removeFromSelection,
  isAccountsLoading = false,
  error
}: SelectionElementProps) => {
  switch (type) {
    case 'account':
      if (data === 'all-accounts') {
        return (
          <div
            className={cn('selection-element-wrapper', {
              selected: inSelectedList,
              error: error
            })}
            onClick={() => {
              onSelect && onSelect(null)
            }}>
            {/* <img src={SelectAccountImage} width={30} /> */}
            <div className='selection-element-content pl-3'>
              <div data-test='selection-element-account-currency-code' className='body-medium'>
                {tr('FRONTEND.INPUT.SELECT.ALL_ACCOUNTS', 'All accounts')}
              </div>
              {isSelected && (
                <div data-test='selection-element-account-arrow-icon' className='selection-element-right-icon'>
                  {<ArrowDownIcon onClick={() => setInputFocused(true)} />}
                </div>
              )}
            </div>
            {error && (
              <div>
                <span>{error}</span>
              </div>
            )}
          </div>
        )
      }
      return (
        <div
          className={cn('selection-element-wrapper', {
            selected: inSelectedList
          })}
          onClick={() => {
            onSelect && onSelect(data)
            setInputFocused && setInputFocused(false)
          }}>
          <CoreCurrencyIcon isLoading={isAccountsLoading} currency={data?.currencyCode} size='30px' circle />
          <div className='selection-element-content pl-1'>
            <div data-test='selection-element-account-currency-code' className='body-medium'>
              {data?.name || `${data?.currencyCode} account`}
            </div>
            {!hideCaption && (
              <div>
                <span data-test='selection-element-account-caption-label' className='selection-element-caption-label'>
                  {tr('FRONTEND.INPUT.SELECT.BALANCE', 'Balance')}
                  {': '}
                </span>
                <span data-test='selection-element-account-caption-value' className='selection-element-caption-text'>
                  {formatMoney(data?.balance, data?.currencyCode)}
                </span>
              </div>
            )}
            {isSelected && (
              <div data-test='selection-element-account-arrow-icon' className='selection-element-right-icon'>
                {<ArrowDownIcon onClick={() => setInputFocused(true)} />}
              </div>
            )}
          </div>
          {error && (
            <div>
              <span>{error}</span>
            </div>
          )}
        </div>
      )
    case 'country':
      return (
        <div
          className={cn('selection-element-wrapper', {
            selected: inSelectedList
          })}
          onClick={() => {
            onSelect && onSelect(data)
          }}>
          <FlagIcon countryCode={data.value} height='20px' />
          <div className='selection-element-content pl-3'>
            <div data-test='selection-element-country-label' className='body-medium'>
              {data.label}
            </div>
            {isSelected && (
              <div data-test='selection-element-country-icon' className='selection-element-right-icon'>
                {<ArrowDownIcon onClick={() => setInputFocused(true)} />}
              </div>
            )}
          </div>
          {error && (
            <div>
              <span>{error}</span>
            </div>
          )}
        </div>
      )
    case 'date':
      return (
        <div
          className={cn('selection-element-wrapper', {
            selected: inSelectedList
          })}
          onClick={() => {
            onSelect && onSelect(data)
          }}>
          <div className='selection-element-content pl-3'>
            <div data-test='selection-element-date-label' className='body-medium'>
              {data}
            </div>
            {isSelected && (
              <div data-test='selection-element-date-arrow-icon' className='selection-element-right-icon'>
                {<ArrowDownIcon onClick={() => setInputFocused(true)} />}
              </div>
            )}
          </div>
          {error && (
            <div>
              <span>{error}</span>
            </div>
          )}
        </div>
      )
    case 'multiselect':
      return (
        <div
          className={cn('selection-element-wrapper', {
            selected: inSelectedList
          })}
          onClick={() => {
            onSelect && onSelect(data[0])
          }}>
          <div onClick={() => setInputFocused(true)} className='multiply-selection-element-content'>
            {selectedList.map(({ label, value }) => {
              return (
                <div
                  key={value}
                  data-test='selection-element-country-label'
                  className='body-medium multiply-selection-element-label'>
                  {isCountryType && <FlagIcon countryCode={value} height='20px' className='multiselect-flag' />}
                  {label}
                  <div
                    onClick={e => {
                      e.stopPropagation()
                      removeFromSelection(value)
                    }}
                    className='multiselect-selection-icon'>
                    <DeleteIcon />
                  </div>
                </div>
              )
            })}
            {isSelected && (
              <div data-test='selection-element-date-arrow-icon' className='selection-element-right-icon'>
                {<ArrowDownIcon />}
              </div>
            )}
          </div>
          {error && (
            <div>
              <span>{error}</span>
            </div>
          )}
        </div>
      )
    case 'currency':
      return (
        <div
          className={cn('selection-element-wrapper', {
            selected: inSelectedList
          })}
          onClick={() => {
            onSelect && onSelect(data)
          }}
          key={`currency-${data?.value}`}>
          <div className={`selection-element-content currency ${isCountryType ? 'pl-3' : 'pl-1'}`}>
            <CoreCurrencyIcon currency={data?.value} size='32px' circle />
            <div data-test='selection-element-label' className='body-medium'>
              {data.label || data}
            </div>
            {isSelected && (
              <div data-test='selection-element-arrow-icon' className='selection-element-right-icon'>
                {<ArrowDownIcon onClick={() => setInputFocused(true)} />}
              </div>
            )}
          </div>
          {error && (
            <div>
              <span>{error}</span>
            </div>
          )}
        </div>
      )

    case 'default':
      return (
        <>
          <div
            className={cn('selection-element-wrapper', {
              selected: inSelectedList,
              error: error
            })}
            onClick={() => {
              onSelect && onSelect(data)
            }}>
            <div className={`selection-element-content ${isCountryType ? 'pl-3' : 'pl-1'}`}>
              <div data-test='selection-element-label' className='body-medium max-length-text'>
                {data.label || data}
              </div>
              {isSelected && (
                <div data-test='selection-element-arrow-icon' className='selection-element-right-icon'>
                  {<ArrowDownIcon onClick={() => setInputFocused(true)} />}
                </div>
              )}
            </div>
          </div>
          {error && (
            <div className='error-wrapper'>
              <span className='error-content'>{error}</span>
            </div>
          )}
        </>
      )
  }
}
