import { useEffect, useState } from 'react'

import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'

import { isValidArray } from 'mmfintech-commons'

interface FilteredResponse {
  content: []
  size: number
  totalElements: number
  number: number
  totalPages: number
}

export const useCoreInfiniteScroll = (
  useGetDataListQuery: UseLazyQuery<any>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { ...queryParameters }
) => {
  const [localPage, setLocalPage] = useState(0)
  const [combinedData, setCombinedData] = useState<[]>([])
  const [getNext, { data: queryResponse, isFetching, isLoading, error }] = useGetDataListQuery({
    ...queryParameters
  })
  const {
    content: fetchData,
    number: remotePage,
    totalPages,
    totalElements
  } = (queryResponse as FilteredResponse) || {}

  useEffect(() => {
    if (isValidArray(fetchData)) {
      if (localPage === 0) {
        setCombinedData(fetchData)
      } else {
        setCombinedData(previousData => [...previousData, ...fetchData])
      }
    } else {
      setCombinedData([])
    }
  }, [fetchData])

  const readMore = async ({ size = 10, filter = {}, page = null }) => {
    let selectedPage = localPage + 1
    if (page || page == 0) {
      selectedPage = page
    }
    setLocalPage(selectedPage)

    try {
      await getNext({
        page: selectedPage,
        size,
        ...filter
      }).unwrap()
    } catch (error) {
      console.error(error?.message)
    }
  }

  return {
    combinedData,
    localPage,
    readMore,
    isLoading: isLoading,
    isFetching: isFetching,
    error,
    totalElements,
    totalPages,
    currentElementsNum: fetchData?.length * (remotePage + 1)
  }
}
