import { useEffect, useState } from 'react'

import { useBatchPaymentPreviewQry, useCurrencies } from 'mmfintech-backend-api'
import { BatchWithdrawalRowDTO } from 'mmfintech-commons-types'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { formatFloat, tr } from 'mmfintech-commons'

import { CoreButton, SendMoneyAmountRow, SendMoneyValueLabel } from '@components'
import { AccountPreview } from '../AccountPreview'

import SuccessIcon from '@images/success-image.png'

export const BatchPaymentPreview = ({ account, file, onSubmit, onCancel }) => {
  const {
    previewData: batchPaymentsUpload,
    previewError: batchPaymentsUploadError,
    initiateFetching,
    initiateError
  } = useBatchPaymentPreviewQry()
  const { getCurrencyPrecision } = useCurrencies()

  const [summary, setSummary] = useState(null)

  useEffect(() => {
    if (batchPaymentsUpload) {
      const total = batchPaymentsUpload.reduce((previous: any, current: BatchWithdrawalRowDTO) => {
        const { paymentPreview } = current
        const { fee, feeCurrency, processingAmount, processingCurrency, walletAmount, walletCurrency } =
          paymentPreview || {}
        const data = { amount: 0, fee: 0, total: 0, count: 0, ...previous }
        data.fee += fee
        data.amount += processingAmount
        data.total += walletAmount
        data.count += 1
        if (!data.totalCurrency) {
          data.feeCurrency = feeCurrency
          data.amountCurrency = processingCurrency
          data.totalCurrency = walletCurrency
        }
        return data
      }, {})
      setSummary(total)
    }
  }, [batchPaymentsUpload])

  return (
    <div className='send-money-container'>
      <div className='batch-payment-preview-header'>
        <img className='success-icon' src={SuccessIcon} alt='success' />
        <p className='success-text'>
          {tr('FRONTEND.BATCH_PAYMENTS.PREVIEW.SUCCESS_MESSAGE', 'Payment data successfully loaded!')}
        </p>
        <h3 className='success-title'>{tr('FRONTEND.BATCH_PAYMENTS.PREVIEW.SUMMARY', 'Summary')}</h3>
      </div>
      <div className='send-money-inside-container amount-container mb-2'>
        <div className='main-amount-label'>{tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.AMOUNT', 'Amount')}</div>
        <div className='main-amount-value'>
          <span data-test='amount'>
            {formatFloat(summary?.total, getCurrencyPrecision(summary?.totalCurrency))}{' '}
            <span className='main-amount-currency'>{summary?.totalCurrency}</span>
          </span>
        </div>

        {summary?.fee > 0 && (
          <SendMoneyAmountRow
            label={tr('FRONTEND.BATCH_PAYMENTS.PREVIEW.FEE_AMOUNT', 'Fee')}
            value={
              <span>
                {formatFloat(summary?.fee, getCurrencyPrecision(summary?.feeCurrency))}{' '}
                <span className='row-amount-currency'>{summary?.feeCurrency}</span>
              </span>
            }
            data-test='fee-amount'
          />
        )}

        <SendMoneyAmountRow
          label={tr('FRONTEND.BATCH_PAYMENTS.PREVIEW.NET_AMOUNT', 'Net amount')}
          value={
            <span>
              {formatFloat(summary?.amount, getCurrencyPrecision(summary?.amountCurrency))}{' '}
              <span className='row-amount-currency'>{summary?.amountCurrency}</span>
            </span>
          }
          data-test='net-amount'
        />
      </div>

      <div className='send-money-inside-container send-money-details'>
        <SendMoneyValueLabel
          label={tr('FRONTEND.BATCH_PAYMENTS.PREVIEW.ACCOUNT', 'Debited Account')}
          value={<AccountPreview account={account} />}
        />
        <SendMoneyValueLabel
          label={tr('FRONTEND.BATCH_PAYMENTS.PREVIEW.FILENAME', 'Batch File Name')}
          value={file?.name}
        />
        {summary?.count ? (
          <SendMoneyValueLabel
            label={tr('FRONTEND.BATCH_PAYMENTS.PREVIEW.COUNT', 'Transfers Count')}
            value={`${summary.count}`}
          />
        ) : null}
      </div>

      <div className='mb-4' />

      <ErrorDisplay error={[batchPaymentsUploadError, initiateError]} />

      <div className='preview-actions'>
        <CoreButton
          type='button'
          data-test='submit-button'
          text={tr('FRONTEND.BATCH_PAYMENTS.PREVIEW.EXECUTE_BUTTON', 'Execute Payment')}
          isLoading={initiateFetching}
          onClick={onSubmit}
          className='mb-1'
          size='large'
          fullWidth
        />
        <CoreButton
          variation='tertiary'
          type='button'
          data-test='cancel-button'
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          onClick={onCancel}
          size='large'
        />
      </div>
    </div>
  )
}
