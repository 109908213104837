import { useState } from 'react'
import ReactTooltip from 'react-tooltip'

import { useGetMerchantQuery } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'

import { CoreCheckbox } from '@components'

import './trustedRecipientCheckbox.scss'

export const TrustedRecipientCheckbox = ({ formValues, trustedRecipient }) => {
  const { data: merchant } = useGetMerchantQuery()

  const { capabilities } = merchant || {}
  const { trustedRecipientEnabled } = capabilities || {}
  const enabled = !!trustedRecipientEnabled

  const [checked, setChecked] = useState(!!trustedRecipient)

  const handleClick = () => {
    const newValue = !checked
    setChecked(newValue)
    if (newValue === !!trustedRecipient) {
      formValues.setValue('saveRecipientAsTrusted', '')
    } else {
      formValues.setValue('saveRecipientAsTrusted', newValue)
    }
  }

  if (!enabled) {
    return null
  }

  return (
    <>
      <div className='trusted-recipient-checkbox-styled'>
        <CoreCheckbox type='check' value={'trusted-recipient'} checked={checked} onClick={handleClick} />
        <div>{tr('FRONTEND.WITHDRAWAL.TRUST_RECIPIENT', 'Trust this recipient')}</div>
        <div
          className='info'
          data-for='info-tooltip'
          data-tip={tr(
            'FRONTEND.WITHDRAWAL.TRUST_RECIPIENT_INFO',
            'Do not ask for 2FA code for subsequent transfers to this recipient'
          )}
        />
      </div>

      <ReactTooltip id='info-tooltip' place='top' type='dark' effect='solid' html={false} />
    </>
  )
}
