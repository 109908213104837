import { ToastBar, Toaster, toast } from 'react-hot-toast'

import './coreToaster.scss'
import { CoreButton } from '@components'

import ToastClose from '@images/icons/close-toast-icon.svg?react'
import ToastSuccess from '@images/icons/success-toaster-icon.svg?react'
import ToastError from '@images/icons/error-toaster-icon.svg?react'

export function CoreToaster(): JSX.Element {
  return (
    <Toaster
      gutter={10}
      position='top-right'
      containerStyle={{
        top: '10rem',
        zIndex: '10010'
      }}
      toastOptions={{
        duration: 3000,
        success: {
          className: 'toast-success-wrapper',
          style: {
            maxWidth: 'none',
            borderRadius: '3.6rem',
            boxShadow: '0px 2px 8px 0px rgba(30, 41, 59, 0.12)',
            height: 'auto',
            padding: '1px 8px'
          },
          icon: <ToastSuccess />
        },
        error: {
          className: 'toast-error-wrapper',
          style: {
            maxWidth: 'none',
            borderRadius: '3.6rem',
            boxShadow: '0px 2px 8px 0px rgba(30, 41, 59, 0.12)',
            height: 'auto',
            padding: '1px 8px'
          },
          icon: <ToastError />
        }
      }}>
      {t => {
        return (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <div data-test={`toaster-${message}`} className='toast-content-wrapper'>
                <div data-test={`toaster-content-${message}`} className='content'>
                  {icon}
                  <span className='caption'>{message}</span>
                </div>
                <CoreButton
                  onClick={() => toast.dismiss(t.id)}
                  collapsed
                  style={{
                    backgroundColor: 'white'
                  }}
                  size='normal'
                  variation='tertiary'
                  CollapsedIcon={<ToastClose />}
                />
              </div>
            )}
          </ToastBar>
        )
      }}
    </Toaster>
  )
}
