import { useHistory } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import fileDownload from 'js-file-download'
import moment from 'moment'

import { useCoreInfiniteScroll } from '@hooks'
import { fillActivityFields } from '@utils'

import {
  endpoints,
  paths,
  transformMerchantActivity,
  useFileDownloader,
  useLazyGetMerchantActivitiesQuery
} from 'mmfintech-backend-api'
import { GlobalContext, isValidArray, tr } from 'mmfintech-commons'
import { InvoiceTypeEnum, MerchantActivityOut } from 'mmfintech-commons-types'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { CoreButton, CoreLoader } from '@components'
import TransactionDetails from '@views/new_transactions/TransactionDetails'
import InvoiceSend from '@views/new_invoices/InvoiceSend/InvoiceSend'
import { CustomerDetailsDialog } from '@views/new_customers'

import CheckIcon from '@images/icons/check-activity.svg?react'
import DepositIcon from '@images/icons/deposit-activity.svg?react'
import ExchangeIcon from '@images/icons/exchange-activity.svg?react'
import IbanIcon from '@images/icons/IBAN-issued-activity.svg?react'
import InvoiceIcon from '@images/icons/invoices-activity.svg?react'
import NoActivitiesIcon from '@images/icons/no-transactions.svg?react'
import OthersIcon from '@images/icons/others-icon-activity.svg?react'
import RecipientApprovedIcon from '@images/icons/recipient-approved-activity.svg?react'
import RefundIcon from '@images/icons/refund-activity.svg?react'
import WithdrawIcon from '@images/icons/withdraw-activity.svg?react'
import LoadIcon from '@images/icons/load.svg?react'

import './activitiesMenu.scss'

enum Category {
  Deposit_pending,
  Deposit_successful,
  Deposit_failed,
  Withdraw_pending,
  Withdraw_successful,
  Withdraw_failed,
  Refund_pending,
  Refund_successful,
  Refund_failed,
  Exchange,
  Recipients,
  Approved,
  Invoices,
  Welcome,
  Iban,
  Others
}
type ActivityCategories = {
  [K in keyof typeof Category]: string[]
}
type ActivityPaletteProps = {
  [K in keyof typeof Category]: {
    icon: JSX.Element
  }
}

interface ActivityComponentProps {
  day: string
  activities: MerchantActivityOut[]
}

export function groupByAndArrange(list: MerchantActivityOut[]) {
  const map = new Map()
  isValidArray(list) &&
    list?.forEach((item: MerchantActivityOut) => {
      const day = moment(item.time).format('DD.MM.YYYY')
      const collection = map.get(day)
      if (!collection) {
        map.set(day, [item])
      } else {
        collection.push(item)
      }
    })
  return map.size > 0 ? Array.from(map) : []
}

const SIZE = 10

export function extractCategoryFromActivity(activity: MerchantActivityOut) {
  const category = (Object.keys(activityCategories) as (keyof typeof activityCategories)[]).find(key =>
    activityCategories[key].includes(activity.type)
  )
  if (!category) return 'Others'
  return category
}

export const ActivitiesMenu = () => {
  const {
    combinedData: lastActivities,
    isFetching: lastActivitiesFetching,
    readMore: fetchActivities,
    error: lastActivitiesError,
    currentElementsNum,
    totalElements,
    localPage,
    totalPages
  } = useCoreInfiniteScroll(useLazyGetMerchantActivitiesQuery, {})

  const arrangedActivities = groupByAndArrange(lastActivities)

  useEffect(() => {
    void fetchActivities({
      size: SIZE,
      page: 0
    })
  }, [])

  const fetchMore = () => {
    void fetchActivities({
      size: SIZE
    })
  }

  return (
    <div className='activities-menu-wrapper'>
      <ErrorDisplay error={lastActivitiesError} />
      <div className='activity-list'>
        {lastActivitiesFetching && localPage == 0 ? (
          <CoreLoader />
        ) : isValidArray(arrangedActivities) && isValidArray(lastActivities) ? (
          <>
            {arrangedActivities.map(([day, activities], index: number) => (
              <ActivityComponent key={index} activities={activities} day={day} />
            ))}
            <div className='elements-info'>
              {currentElementsNum} of {totalElements}
              {(localPage < totalPages - 1 || lastActivitiesFetching) && (
                <CoreButton
                  text={tr('FRONTEND.ACTIVITIES.LOAD_MORE_BUTTON', 'Load More')}
                  size='large'
                  variation='tertiary'
                  onClick={fetchMore}
                  isLoading={lastActivitiesFetching}
                  LeftIcon={<LoadIcon />}
                />
              )}
            </div>
          </>
        ) : (
          <div className='no-activities'>
            <NoActivitiesIcon fill='black' />
            <span className='no-activities-message'>
              {tr('FRONTEND.ACTIVITIES.NO_ACTIVITIES_MESSAGE', 'No activities for the selected period.')}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

function ActivityComponent({ day, activities }: ActivityComponentProps) {
  const history = useHistory()
  const downloader = useFileDownloader()
  const { modalShow, sidebarRightHide, sidebarRightShow } = useContext(GlobalContext)

  const handleActivityClick = data => {
    const { redirect, customerId, invoiceId, invoiceType, transactionId } = data
    sidebarRightHide()
    switch (redirect) {
      case 'customer':
        if (customerId > 0) {
          sidebarRightShow({
            options: { size: 'auto', closeOnClickOutside: false },
            content: <CustomerDetailsDialog payerId={customerId} onClose={sidebarRightHide} />,
            header: tr('FRONTEND.CUSTOMERS.LIST.TITLE_DETAILS', 'Customer Details')
          })
        }
        break

      case 'invoice':
        if (invoiceId > 0) {
          if (invoiceType === InvoiceTypeEnum.OWN) {
            modalShow({
              options: { size: 'extra-large', closeIconPosition: 'speedy-special' },
              header: 'Invoice preview',
              content: <InvoiceSend invoiceIdPreview={invoiceId} />
            })
          } else {
            void downloader.download({
              url: endpoints.invoices.exportInvoiceAsPdf(invoiceId),
              method: 'GET',
              onSuccess: (data: any, filename: string) => {
                fileDownload(data, filename || `invoice-${invoiceId}.pdf`)
              }
            })
          }
        }
        break

      case 'transaction':
        if (transactionId > 0) {
          modalShow({
            header: tr('FRONTEND.TRANSACTIONS.DETAILS.TITLE', 'Transaction details'),
            content: <TransactionDetails isModal={true} transactionId={transactionId} />,
            options: {
              size: 'auto'
            }
          })
        }
        break

      case 'profile':
        history.push(paths.profile() + '/details')
        break

      case 'account':
        history.push(paths.banking.accounts.list())
        break

      default:
      // nothing
    }
  }

  return (
    <div className='activity-column-wrapper'>
      {day && (
        <div data-test={`activities-menu-date-${day}`} className='column-header'>
          {day}
        </div>
      )}
      <div className='activity-column'>
        {isValidArray(activities) &&
          activities.map((activity: MerchantActivityOut, index) => {
            const { text, time } = activity
            const category = extractCategoryFromActivity(activity)

            const { icon } = activityPalette[category]
            const data = transformMerchantActivity(activity)

            return (
              <div
                className='activity-wrapper'
                key={`${text} - ${index}`}
                onClick={() => {
                  handleActivityClick(data)
                }}>
                <div className='activity-content'>
                  {icon}
                  <div className='activity-content-left'>
                    <div data-test={`activity-title-${text}`} className='activity-title'>
                      {fillActivityFields(
                        activity,
                        tr(`FRONTEND.ACTIVITY.${activity.type}`, text.replace(/<.*?>/g, ''))
                      )}
                    </div>
                    <div data-test={`activity-timestamp-${text}`} className='timestamp'>
                      {day && !day.includes('Last')
                        ? moment(time).format('hh:mm')
                        : moment(time).format('DD/MM/YYYY hh:mm')}
                    </div>
                  </div>
                </div>
                <div className={`${getPointColor(category)} status-point`} />
              </div>
            )
          })}
      </div>
    </div>
  )
}

const activityCategories: ActivityCategories = {
  Withdraw_pending: ['WITHDRAWAL_PENDING'],
  Withdraw_successful: ['TRANSFER_SENT', 'WALLET_PAYMENT_COMPLETED', 'WITHDRAWAL_PENDING'],
  Withdraw_failed: ['WITHDRAWAL_REJECTED', 'WITHDRAWAL_REVERTED'],
  Deposit_pending: ['DEPOSIT_PENDING'],
  Deposit_successful: ['DEPOSIT_PROCESSED', 'TRANSFER_RECEIVED'],
  Deposit_failed: ['DEPOSIT_REJECTED', 'DEPOSIT_RETURNED'],
  Refund_pending: ['REFUND_PENDING'],
  Refund_successful: ['REFUND_PROCESSED'],
  Refund_failed: ['REFUND_REJECTED'],

  Exchange: ['EXCHANGE_PROCESSED'],
  Recipients: ['CONTACT_CREATED', 'CUSTOMER_CREATED'],
  Approved: ['ACCOUNT_APPROVED'],
  Invoices: [
    'ENOTAS_INVOICE_APPROVED',
    'ENOTAS_INVOICE_CANCELED',
    'ENOTAS_INVOICE_DECLINED',
    'INVOICE_CREATED',
    'INVOICE_OPENED',
    'INVOICE_PAID',
    'INVOICE_PARTIALLY_PAID'
  ],
  Welcome: ['REGISTERED_ACCOUNT'],
  Iban: ['ISSUE_IBAN_ISSUED', 'ISSUE_IBAN_DECLINED', 'ISSUE_IBAN_FAILED', 'ISSUE_IBAN_INITIATED'],
  Others: []
}

const getPointColor = category => {
  switch (category) {
    case 'Withdraw_pending':
    case 'Deposit_pending':
    case 'Refund_pending':
      return 'pending'

    case 'Withdraw_successful':
    case 'Deposit_successful':
    case 'Refund_successful':
      return 'success'

    case 'Withdraw_failed':
    case 'Deposit_failed':
    case 'Refund_failed':
      return 'fail'

    default:
      return ''
  }
}

export const activityPalette: ActivityPaletteProps = {
  Deposit_pending: { icon: <DepositIcon /> },
  Deposit_successful: { icon: <DepositIcon /> },
  Deposit_failed: { icon: <DepositIcon /> },
  Withdraw_pending: { icon: <WithdrawIcon /> },
  Withdraw_successful: { icon: <WithdrawIcon /> },
  Withdraw_failed: { icon: <WithdrawIcon /> },
  Refund_pending: { icon: <RefundIcon /> },
  Refund_successful: { icon: <RefundIcon /> },
  Refund_failed: { icon: <RefundIcon /> },
  Exchange: { icon: <ExchangeIcon /> },
  Recipients: { icon: <RecipientApprovedIcon /> },
  Invoices: { icon: <InvoiceIcon /> },
  Approved: { icon: <CheckIcon /> },
  Iban: { icon: <IbanIcon /> },
  Welcome: { icon: <CheckIcon /> },
  Others: { icon: <OthersIcon /> }
}
