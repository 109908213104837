import React, { memo, ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import cn from 'classnames'
import isEqual from 'lodash.isequal'

import { useModal } from 'mmfintech-commons'

import './sidebarRight.scss'

import CrossIcon from '@images/icons/cross-button-icon.svg?react'

interface SidebarRightProps {
  content: ReactNode
  header?: ReactNode
  options: {
    closeIconPosition?: 'default'
    closeOnClickOutside?: boolean
    closeOnEscape?: boolean
    hideCloseIcon?: boolean
    scrollable?: boolean
    maxWidth?: number
    onClose?: () => void
    transparent?: boolean
    size?: 'auto' | 'small' | 'medium' | 'large' | 'extra-large' | 'horizontal' | 'centered'
    type?: string
  }
  visible: boolean
  onClose: () => void
}

const SidebarRight: React.FC<SidebarRightProps> = ({ header, content, options, visible, onClose }) => {
  const { ref, modalOptions, clickOutsideRef } = useModal({
    options,
    visible,
    onClose
  })

  const location = useLocation()

  const onCloseModal = () => {
    typeof onClose === 'function' && onClose()
  }

  useEffect(() => {
    if (visible) {
      typeof onClose === 'function' && onClose()
    }
    // eslint-disable-next-line
  }, [location.pathname])

  return (
    <div className={cn('sidebar-right-wrapper', { visible: !!visible })} ref={ref}>
      <div
        ref={clickOutsideRef}
        data-test='modal-container'
        className={`sidebar-right-container ${cn({
          'size-auto': modalOptions.size === 'auto',
          'size-large': modalOptions.size === 'large',
          'size-small': modalOptions.size === 'small',
          'size-extra-large': modalOptions.size === 'extra-large',
          centered: modalOptions.size === 'centered',
          horizontal: modalOptions.size === 'horizontal',
          transparent: modalOptions.transparent === true,
          'overflow-none': modalOptions.overflow === 'none',
          // pass {type: "rounded"} if you want to use right modal with margins
          rounded: modalOptions?.type === 'rounded'
        })}`}>
        {(header || !options?.hideCloseIcon) && (
          <div className='header'>
            <div>{header}</div>
            {!options?.hideCloseIcon && <CrossIcon className='close-icon' onClick={onCloseModal} />}
          </div>
        )}
        <main className='modal-main'>{content}</main>
      </div>
    </div>
  )
}

export const CoreSidebarRight = memo(SidebarRight, (prevProps, nextProps) => isEqual(prevProps, nextProps))
