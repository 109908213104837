import { tr } from 'mmfintech-commons'
import './EmptyVoucherPage.scss'

import HintIcon from '@images/icons/missing-vouchers-icon.svg?react'

export const EmptyVoucherPage = ({ active }) => {
  return (
    <div className='empty-voucher-page'>
      <HintIcon />
      {active ? (
        <p>{tr('FRONTEND.VOUCHERS.EMPTY_VOUCHER_PAGE_ACTIVE', `You don’t have any Active vouchers`)}</p>
      ) : (
        <p>{tr('FRONTEND.VOUCHERS.EMPTY_VOUCHER_PAGE_HISTORY', `You don’t have any History vouchers`)}</p>
      )}
    </div>
  )
}
