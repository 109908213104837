import { FC } from 'react'

import { isValidArray } from 'mmfintech-commons'

import { ReportsTable } from '../ReportsTable'
import { IReportTableProps } from './@types'
import { Pagination } from '@components'

export const AllReports: FC<IReportTableProps> = ({ data, pagination, isApproverTable }) => (
  <>
    <ReportsTable data={data} isApproverTable={isApproverTable} reloadPagination={() => pagination.reload()} />
    {isValidArray(data) && <Pagination {...pagination.register()} />}
  </>
)
