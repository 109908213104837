import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import toast from 'react-hot-toast'

import { ErrorDisplay } from 'mmfintech-portal-commons'

import { GlobalContext, tr, OtpContext, isValidObject, isValidString } from 'mmfintech-commons'
import {
  actions,
  useCryptoTransferQry,
  responseDoesNotContainsChallenge,
  useGetMerchantQuery,
  extractOptionStatusFromAccount
} from 'mmfintech-backend-api'

import { MerchantAccountTypeEnum, PaymentOptionStatusEnum } from 'mmfintech-commons-types'

import { CoreButton, CoreOnboardingAnnounce, CorePaymentInput, CoreSelect, SuccessPane } from '@components'
import { CryptoTransferPreview } from './CryptoTransferPreview'
import { HeaderContext } from '../../../context/HeaderContext'
import { CryptoRecipientEdit } from '../../new_recipients/elements/CryptoRecipientEdit'

import NewRecipientIcon from '@images/icons/recipient-new.svg?react'
import RecipientIcon from '@images/icons/recipient-select.svg?react'

export const getMaxAmount = selectedAccount => {
  const { paymentOptions, availableBalance } = selectedAccount || {}
  const maxAmount = paymentOptions?.[0]?.maxAmount

  // Return the smaller value between available balance and max amount if both exist
  if (maxAmount && availableBalance) {
    return Math.min(maxAmount, availableBalance)
  }

  return maxAmount || availableBalance
}

export const CryptoTransfer = () => {
  const { data: merchant } = useGetMerchantQuery(null)
  const { setCurrentStep, currentStep } = useContext(HeaderContext)
  const { modalHide, modalShow } = useContext(GlobalContext)
  const { setOtpOnSuccess } = useContext(OtpContext)
  const [result, setResult] = useState(null)
  const history = useHistory()

  const recipientId = new URLSearchParams(window.location.search).get('recipientId')

  const { accountType } = merchant || {}

  const {
    formValues,
    handleRecipientChange,
    handlePreview,
    handleInitiate,
    selectedAccount,
    selectedRecipient,
    setSelectedAccount,
    filteredRecipients,
    accounts,
    accountsError,
    accountsFetching,
    recipientsError,
    recipientsFetching,
    reloadRecipients,
    previewError,
    previewFetching,
    resetMutations,
    shouldShowWarning
  } = useCryptoTransferQry({
    editRecipient: () => editRecipient(),
    onPreviewSuccess: () => setCurrentStep(2),
    onInitiateSuccess: (payload: any) => handleInitiateSuccess(payload),
    selectAddNew: false,
    validation: 'float',
    recipientId: parseInt(recipientId)
  })

  const editRecipient = (recipient?: any): void => {
    const { currencyCode } = selectedAccount || {}
    if (currencyCode?.length) {
      modalShow({
        header: recipient?.paymentInstrumentId
          ? tr('FRONTEND.RECIPIENTS.CRYPTO.UPDATE_TITLE', 'Edit recipient')
          : tr('FRONTEND.RECIPIENTS.CRYPTO.CREATE_TITLE', 'Create recipient'),
        content: (
          <CryptoRecipientEdit
            recipientId={recipient?.paymentInstrumentId}
            currencyCode={currencyCode}
            onClose={modalHide}
            onSuccess={(response: any) => {
              const { paymentInstrumentId } = response || {}
              toast.remove()
              toast.success(tr('FRONTEND.RECIPIENT.CREATE_SUCCESSFUL', 'Recipient successfully created'), {
                duration: 3_000
              })
              reloadRecipients(paymentInstrumentId)
            }}
          />
        )
      })
    }
  }

  const hasValidRecipientPaymentOption = () => {
    const { paymentOption } = selectedRecipient || {}
    const { status } = paymentOption || {}
    return status === PaymentOptionStatusEnum.AVAILABLE
  }

  const hasValidPaymentOption = () =>
    hasValidRecipientPaymentOption() ||
    extractOptionStatusFromAccount(selectedAccount) === PaymentOptionStatusEnum.AVAILABLE

  const transferSuccess = (data: any) => {
    setResult(data)
    setCurrentStep(3)
  }

  const handleInitiateSuccess = (data: any): void => {
    if (responseDoesNotContainsChallenge(data)) {
      transferSuccess(data)
    }
  }

  const handleSubmitInitiate = () => {
    setOtpOnSuccess(() => transferSuccess)
    handleInitiate()
  }

  useEffect(() => {
    return () => {
      setCurrentStep(1)
      setResult(null)
      resetMutations()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {currentStep === 1 && (
        <div className='send-money-container'>
          <div className='from-container'>
            <div className='label'>
              <span>{tr('FRONTEND.WITHDRAW.BANK_TRANSFER.FROM_LABEL', 'From')}</span>
            </div>
            <CorePaymentInput
              selectedOption={selectedAccount}
              setSelectedAccount={setSelectedAccount}
              dataTest={'deposit-payment-input-test'}
              options={accounts}
              enableMaxAmountButton
              maxInputAmount={getMaxAmount(selectedAccount)}
              minInputAmount={selectedAccount?.paymentOptions?.[0]?.minAmount}
              onChange={value => formValues.setValue('amount', value)}
              value={formValues.getValue('amount')}
              error={formValues.getError('amount')}
              isLoading={accountsFetching}
              hideCurrencySymbol={true}
            />
          </div>

          <div className='to-container'>
            <div className='container-sb'>
              <span className='label'>{tr('FRONTEND.WITHDRAW.BANK_TRANSFER.TO_LABEL', 'To')}</span>
              {!recipientId && (
                <CoreButton
                  variation='tertiary'
                  LeftIcon={<NewRecipientIcon />}
                  className='send-money-add-recipient'
                  size='normal'
                  text={tr('FRONTEND.RECIPIENTS.LIST.BUTTON_ADD', 'New recipient')}
                  isLoading={previewFetching}
                  onClick={() => editRecipient()}
                  data-test='button-add-recipient'
                />
              )}
            </div>

            <CoreSelect
              type='default'
              data-test='destination-payment-instrument-id'
              label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.RECIPIENT', 'Recipient')}
              options={filteredRecipients}
              onChange={handleRecipientChange}
              loading={recipientsFetching}
              disabled={recipientsFetching}
              value={
                selectedRecipient
                  ? { value: selectedRecipient?.paymentInstrumentId, label: selectedRecipient?.name }
                  : null
              }
              LeftIcon={<RecipientIcon />}
            />
          </div>

          {isValidObject(selectedRecipient) && isValidString(selectedRecipient.description) && (
            <div className='recipient-info-container'>
              <div className='recipient-description-wrapper'>
                <div className='description-label'>
                  {tr('FRONTEND.WITHDRAW.SEND_CRYPTO.RECIPIENT_DESCRIPTION', 'Recipient Description')}
                </div>
                <div className='description'>{selectedRecipient.description}</div>
              </div>
            </div>
          )}

          <div className='mb-4' />

          {shouldShowWarning() ||
            (accountType === MerchantAccountTypeEnum.PROSPECT && !hasValidPaymentOption() && (
              <div className='warning-banner'>
                <CoreOnboardingAnnounce />
              </div>
            ))}

          <ErrorDisplay error={[accountsError, recipientsError, previewError]} />

          <CoreButton
            type='button'
            size='large'
            text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
            disabled={
              accountsFetching ||
              recipientsFetching ||
              selectedRecipient?.paymentOption?.status !== PaymentOptionStatusEnum.AVAILABLE ||
              formValues.getFloat('amount') <= 0
            }
            isLoading={previewFetching}
            onClick={handlePreview}
            data-test='button-continue'
            fullWidth
          />
        </div>
      )}

      {currentStep === 2 && (
        <CryptoTransferPreview
          selectedAccount={selectedAccount}
          selectedRecipient={selectedRecipient}
          onSubmit={handleSubmitInitiate}
          onCancel={() => setCurrentStep(1)}
          formValues={formValues}
        />
      )}

      {currentStep === 3 && (
        <SuccessPane response={result} onClick={() => actions.routing.returnFromWithdraw(history)} />
      )}
    </>
  )
}
