import { CoreAmountTabs, CoreButton, CoreCheckbox, CoreInput, CorePaymentInput } from '@components'
import {
  useBuyVoucherMutation,
  useBuyVouchersBulkMutation,
  useGetMerchantQuery,
  useMerchantAccounts,
  useSelectedAccount
} from 'mmfintech-backend-api'
import { isValidArray, OtpContext, tr, useFormValues } from 'mmfintech-commons'
import { Currency } from 'mmfintech-commons-types'
import { useContext, useEffect, useMemo, useState } from 'react'
import { BULK_AMOUNTS, PREPARED_AMOUNTS, VOUCHER_TYPES } from './BuyVoucherTabConstants'
import cn from 'classnames'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import CalendarIcon from '@images/icons/calendar_kingdom_cash_icon.svg?react'

import './BuyVoucherTab.scss'

interface BuyVoucherTab {
  onSuccess: () => void
}

export const BuyVoucherTab = ({ onSuccess }: BuyVoucherTab) => {
  const { selectedAccount, selectedAccountId, selectedAccountCurrency, setSelectedAccount } = useSelectedAccount()
  const [buyVoucher, { error: buyVoucherError, isLoading: buyVoucherLoading }] = useBuyVoucherMutation({
    fixedCacheKey: 'buy-voucher-mutation'
  })
  const [buyBulkVoucher, { error: bulkError, isLoading: bulkLoading }] = useBuyVouchersBulkMutation({
    fixedCacheKey: 'buy-voucher-bulk-mutation'
  })
  const { activeAccounts: paymentAccounts, accountsFetching: paymentAccountsFetching } = useMerchantAccounts({
    validCurrency: (currency?: Currency) => !!currency.supportedForCashVoucher
  })

  const { data: merchant } = useGetMerchantQuery(null)
  const { capabilities } = merchant || {}
  const { setOtpOnSuccess } = useContext(OtpContext)

  // states
  const [selectedVoucherType, setSelectedVoucherType] = useState('single')
  const [selectedAmountTab, setSelectedAmountTab] = useState(null)

  const formValues = useFormValues({
    sourceAccountId: { required: true },
    amount: { required: true },
    currency: { required: true },
    count: { validation: 'numeric' }
  })

  // useEffects

  useEffect(() => {
    if (selectedAccount) {
      formValues.setValue('currency', selectedAccountCurrency)
      formValues.setValue('sourceAccountId', selectedAccountId)
    }
  }, [selectedAccount])

  useMemo(() => {
    if (isValidArray(paymentAccounts) && !selectedAccount) {
      setSelectedAccount(paymentAccounts[0])
    }
  }, [paymentAccounts])

  useEffect(() => {
    if (formValues.getValue('amount')) {
      if (selectedVoucherType === 'single') {
        setSelectedAmountTab(
          PREPARED_AMOUNTS.find(prepAmount => prepAmount.toString() === formValues.getValue('amount').toString())
        )
      } else {
        setSelectedAmountTab(
          BULK_AMOUNTS.find(prepAmount => prepAmount.toString() === formValues.getValue('amount').toString())
        )
      }
    }
  }, [formValues.getValue('amount')])

  useEffect(() => {
    if (selectedAmountTab) {
      formValues.setValue('amount', selectedAmountTab)
    }
  }, [selectedAmountTab])

  // functions

  const handlePurchase = async (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault()

    setOtpOnSuccess(() => (data: any) => {
      if (data && data?.refId) {
        onSuccess()
      }
    })

    if (formValues.areValid()) {
      try {
        selectedVoucherType === 'bulk'
          ? await buyBulkVoucher(formValues.prepare()).unwrap()
          : await buyVoucher(formValues.prepare()).unwrap()
      } catch (err) {
        formValues.handleErrors(err)
      }
    }
  }

  return (
    <div className='buy-cash-voucher-wrapper'>
      <div className='cash-voucher-top-wrapper'>
        <CorePaymentInput
          selectedOption={selectedAccount}
          setSelectedAccount={setSelectedAccount}
          dataTest={'deposit-payment-input-test'}
          options={paymentAccounts}
          onChange={value => formValues.setValue('amount', value)}
          value={
            selectedVoucherType === 'bulk'
              ? Number(formValues.getValue('amount')) * Number(formValues.getValue('count')) // shown total price for all the vouchers
              : formValues.getValue('amount')
          }
          isLoading={paymentAccountsFetching}
          disableAmountChange={selectedVoucherType === 'bulk'}
          hint='Amount should be a multiple of 5'
          error={formValues.getError('amount')}
          minInputAmount={5}
          maxInputAmount={5000}
          enableMinMaxLabel
        />
        <div
          style={{ paddingBottom: selectedVoucherType === 'bulk' ? '1.6rem' : '0' }} //prevent vertical moving
          className='cash-voucher-type-buttons-wrapper'>
          <div className='cash-voucher-type-buttons-content'>
            {VOUCHER_TYPES.map(({ Icon, label, value }) => {
              if (value === 'bulk' && !capabilities.enableBulkCashVouchers) {
                return null
              }
              return (
                <div
                  key={label}
                  className={cn('cash-voucher-type-button', { selected: value === selectedVoucherType })}
                  onClick={() => setSelectedVoucherType(value)}>
                  <div className='cash-voucher-type-icon'>
                    <Icon />
                  </div>
                  <span>{label}</span>
                  <CoreCheckbox
                    className='cash-voucher-type-button-checkbox'
                    checked={value === selectedVoucherType}
                    type='circle'
                    value={value}
                  />
                </div>
              )
            })}
          </div>
          {selectedVoucherType === 'bulk' && (
            <div>
              <CoreInput {...formValues.registerInput('count')} label='Number of Vouchers' />
            </div>
          )}
          <CoreAmountTabs
            amounts={selectedVoucherType === 'bulk' ? BULK_AMOUNTS : PREPARED_AMOUNTS}
            onChange={setSelectedAmountTab}
            selected={selectedAmountTab}
            currency={selectedAccount ? selectedAccount?.currencyCode : ''}
          />
        </div>
        <div className='cash-voucher-announce-wrapper'>
          <div className='icon'>
            <CalendarIcon />
          </div>
          <span>
            {tr('FRONTEND.CASH_VOUCHER.ANNOUNCE_SUBTITLE', 'Once purchased vouchers have 1 year until expiring.')}
          </span>
        </div>
      </div>
      <div className='cash-voucher-bottom-wrapper'>
        <ErrorDisplay error={[buyVoucherError, bulkError]} />
        <div className='cash-voucher-add-button-wrapper'>
          <CoreButton
            size='large'
            text='Buy'
            fullWidth
            onClick={handlePurchase}
            isLoading={buyVoucherLoading || bulkLoading}
          />
        </div>
      </div>
    </div>
  )
}
