import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import cn from 'classnames'

import { paths } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'

import './navigationSwitch.scss'

interface ConfigItem {
  name: string
  displayName: string
  path: string
}

interface IViewNavigation {
  selected: string
  setSelected: (arg: unknown) => void
}

export const NavigationSwitch: React.FC<IViewNavigation> = ({ selected, setSelected }) => {
  const history = useHistory()
  const { pathname } = history?.location

  const navigationConfig: ConfigItem[] = [
    {
      displayName: tr('FRONTEND.HEADER.REPORTS', 'Reports'),
      name: 'reports',
      path: paths.expenseManagement.reports()
    },
    {
      displayName: tr('FRONTEND.HEADER.COUNTERPARTY', 'Counterparties'),
      name: 'counterparty',
      path: paths.expenseManagement.counterParty()
    }
    // {
    //   displayName: tr('FRONTEND.EXPENSES.UPLOAD_FILE.DETAILS_VIEW', 'Analytics'),
    //   name: 'test',
    //   path: paths.banking.expenses()
    // }
  ]

  useEffect(() => {
    const endPath = pathname.split('/')[2]
    if (endPath) {
      setSelected(endPath)
    }
  }, [pathname])

  const handleViewChange = view => {
    setSelected(view)
  }

  const onChangeTab = (name: string, path: string) => {
    handleViewChange(name)
    history.push(path)
  }

  return (
    <div className='navigation-container'>
      {navigationConfig.map(({ name, displayName, path }, index) => (
        <div
          className={`navigation-button ${cn({ active: selected === name })}`}
          key={index}
          onClick={() => onChangeTab(name, path)}>
          <span>{displayName}</span>
        </div>
      ))}
    </div>
  )
}
