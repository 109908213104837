import { useOnboarding } from '@hooks'
import { CoreButton } from '@components'
import { useGetMerchantQuery } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'
import { MerchantAccountTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'
import './CoreOnboardingAnnounce.scss'
import AnnounceIcon from '@images/icons/announce-icon.svg?react'
import ArrowIcon from '@images/icons/arrow-down-icon.svg?react'

const announceConstants = {
  CASH_TO_CODE: {
    title: tr('FRONTEND.ANNOUNCE.CASHTOCODE.TITLE', 'Please Note'),
    text: tr(
      'FRONTEND.ANNOUNCE.CASHTOCODE.TEXT',
      'CashtoCode payment method supports only predefined amount. \nPlease select amount that is close to the one you want to deposit in your account.'
    ),
    buttonText: '',
    enableButton: false
  }
}

export enum AnnounceTypesEnum {
  CASH_TO_CODE = 'CASH_TO_CODE'
}

// TODO: Check if we need something from AccountWarning in CoreOnboardingAnnounce
// <AccountWarning />

const onboardingStatusText = status => {
  switch (status) {
    case OnboardingStatusEnum.UNDER_REVIEW:
    case OnboardingStatusEnum.WAITING_OPS_APPROVAL:
    case OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL:
      return tr(`FRONTEND.BANKING.ONBOARDING.TITLE_REVIEW`, 'Your account verification is under review.')
    case OnboardingStatusEnum.REJECTED_FINAL:
      return tr(
        'FRONTEND.BANKING.COMMON.ACCOUNT_REJECTED',
        'Please contact Customer Service to get support on your account verification.'
      )
    case OnboardingStatusEnum.IN_PROGRESS:
    case OnboardingStatusEnum.WAITING_QUESTIONNAIRE:
    case OnboardingStatusEnum.NOT_STARTED:
      return tr(
        `FRONTEND.BANKING.ONBOARDING.COMPLETE_TO_ACCESS_FEATURES`,
        'Complete your account Verify to get access to all features and increased payment limits.'
      )
    default:
      return tr(`FRONTEND.BANKING.ONBOARDING.TITLE`, 'Complete your account')
  }
}
export const CoreOnboardingAnnounce = ({ alternative }: { alternative?: AnnounceTypesEnum.CASH_TO_CODE | null }) => {
  const { data: merchant } = useGetMerchantQuery()
  const { startOrContinueOnboarding } = useOnboarding()
  const { onboardingStatus, accountType } = merchant || {}

  return (
    <>
      {accountType === MerchantAccountTypeEnum.PROSPECT && onboardingStatus !== OnboardingStatusEnum.APPROVED ? (
        <div className='core-onboarding-announce'>
          <div className='core-onboarding-announce-title-wrapper'>
            <div className='core-onboarding-header-left-side'>
              <div className='core-onboarding-announce-icon'>
                <AnnounceIcon />
              </div>
              <p>{onboardingStatusText(onboardingStatus)}</p>
            </div>
            {onboardingStatus !== OnboardingStatusEnum.WAITING_OPS_APPROVAL &&
            onboardingStatus !== OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL &&
            onboardingStatus !== OnboardingStatusEnum.REJECTED_FINAL ? (
              <CoreButton
                size='normal'
                text={tr(`FRONTEND.BANKING.ONBOARDING.BUTTON.VERIFY`, 'Verify')}
                fitContent
                RightIcon={<ArrowIcon />}
                className='core-onboarding-announce-button'
                onClick={() => startOrContinueOnboarding()}
              />
            ) : null}
          </div>

          {/* <div className='core-onboarding-announce-content-wrapper'>
            {onboardingStatus !== OnboardingStatusEnum.UNDER_REVIEW &&
              tr(
                `FRONTEND.BANKING.ONBOARDING.SUBTITLE`,
                'Verify to get access to all features and increased payment limits.'
              )}
          </div> */}
        </div>
      ) : (
        alternative && (
          // alternative path (if onboarding is done)
          <div className='core-onboarding-announce'>
            <div className='core-onboarding-announce-title-wrapper'>
              <div className='core-onboarding-header-left-side'>
                <div className='core-onboarding-announce-icon'>
                  <AnnounceIcon />
                </div>
                <p>{announceConstants[alternative].title}</p>
              </div>
              {announceConstants[alternative].enableButton && (
                <CoreButton
                  size='normal'
                  text={announceConstants[alternative].buttonText}
                  fitContent
                  RightIcon={<ArrowIcon />}
                  className='core-onboarding-announce-button'
                  onClick={() => startOrContinueOnboarding()}
                />
              )}
            </div>
            <div className='core-onboarding-announce-content-wrapper'>{announceConstants[alternative].text}</div>
          </div>
        )
      )}
    </>
  )
}
