import { memo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import cn from 'classnames'
import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'

import { useModal } from 'mmfintech-commons'

import './modalDialog.scss'

import CrossIcon from '@images/icons/cross-button-icon.svg?react'

const ModalDialog = ({ header, content, options, visible, onClose }) => {
  const { ref, modalOptions, clickOutsideRef } = useModal({
    options,
    visible,
    onClose
  })

  const location = useLocation()

  useEffect(() => {
    if (visible) {
      typeof onClose === 'function' && onClose()
    }
  }, [location.pathname])

  const onCloseModal = () => {
    typeof onClose === 'function' && onClose()
  }

  if (!visible) {
    return null
  }

  return (
    <div className='modal-wrapper' ref={ref}>
      <div
        ref={clickOutsideRef}
        data-test='modal-container'
        className={`modal-container ${cn({
          'size-auto': modalOptions.size === 'auto',
          'size-large': modalOptions.size === 'large',
          'size-small': modalOptions.size === 'small',
          'size-extra-large': modalOptions.size === 'extra-large',
          centered: modalOptions.size === 'centered',
          horizontal: modalOptions.size === 'horizontal',
          transparent: modalOptions.transparent === true,
          'overflow-none': modalOptions.overflow === 'none',
          'overflow-visible': modalOptions.overflow === 'visible'
        })}`}>
        {(header || !options?.hideCloseIcon) && (
          <div className='header'>
            <div>{header}</div>
            <div className='header-buttons-wrapper'>
              {options?.additionalActions && options?.additionalActions}
              {!options?.hideCloseIcon && <CrossIcon className='close-icon' onClick={onCloseModal} />}
            </div>
          </div>
        )}
        <main className='modal-main'>{content}</main>
      </div>
    </div>
  )
}
export const CoreModalDialog = memo(ModalDialog, (prevProps, nextProps) => isEqual(prevProps, nextProps))

ModalDialog.propTypes = {
  content: PropTypes.any,
  header: PropTypes.any,
  footer: PropTypes.any,
  options: PropTypes.shape({
    closeIconPosition: PropTypes.oneOf(['default']),
    closeOnClickOutside: PropTypes.bool,
    closeOnEscape: PropTypes.bool,
    hideCloseIcon: PropTypes.bool,
    scrollable: PropTypes.bool,
    maxWidth: PropTypes.number,
    onClose: PropTypes.func,
    additionalActions: PropTypes.any,

    transparent: PropTypes.bool,
    size: PropTypes.oneOf(['auto', 'small', 'medium', 'large', 'extra-large', 'horizontal', 'centered'])
  }),
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}
