import { useHistory } from 'react-router-dom'
import React from 'react'
import moment from 'moment'

import { isOwnerOrAdministrator, paths, selectCurrentUserRole, useAppSelector } from 'mmfintech-backend-api'
import { formatDateToUserLocale, formatFloat, getCurrencyDecimals, isValidArray, tr } from 'mmfintech-commons'
import { ExpenseManagementReport, ReportStateEnum } from 'mmfintech-commons-types'
import { DataTable } from 'mmfintech-portal-commons'

import { ReportStatusBadge, NoReportsComponent } from '../components'
import { breakpoints } from '@constants'
import { useMatchMedia } from '@hooks'

import RowIcon from '@images/icons/report-row-icon.svg?react'

import './reportsTable.scss'

const { Table, Row, HeadCell, Head, Body, Cell } = DataTable

type ReportTableProps = {
  data: ExpenseManagementReport[]
  isApproverTable?: boolean
  reloadPagination: () => void
}

export const ReportsTable: React.FC<ReportTableProps> = ({ data, isApproverTable }) => {
  const history = useHistory()
  const customerRole = useAppSelector(selectCurrentUserRole)
  const isLarge = useMatchMedia({ breakpoint: breakpoints.lg, prefix: 'min' })
  const isXLarge = useMatchMedia({ breakpoint: breakpoints.xl, prefix: 'min' })

  const handleReportRowClick = (report: ExpenseManagementReport) => {
    if (
      // Check if the user is able to access payments page
      (report.state === ReportStateEnum.APPROVED ||
        report.state === ReportStateEnum.COMPLETELY_PAID ||
        report.state === ReportStateEnum.PARTIALLY_PAID ||
        report.state === ReportStateEnum.UNPAID) &&
      isOwnerOrAdministrator(customerRole)
    ) {
      history.push(paths.expenseManagement.payments(report?.id))
      return
    }

    history.push(paths.expenseManagement.report(report.id))
  }

  return (
    <div className='reports-table-wrapper table-container'>
      <Table>
        {isValidArray(data) ? (
          <>
            {isLarge && (
              <Head>
                <Row className='headRow'>
                  <HeadCell colSpan={2}>{tr('FRONTEND.REPORTS.COLUMN_NAME', 'Name')}</HeadCell>
                  {isApproverTable && isXLarge && (
                    <HeadCell colSpan={2}>{tr('FRONTEND.REPORTS.COLUMN_SUBMITTER', 'Submitter')}</HeadCell>
                  )}
                  {isXLarge && <HeadCell>{tr('FRONTEND.REPORTS.COLUMN_PERIOD', 'Period')}</HeadCell>}
                  <HeadCell>{tr('FRONTEND.REPORTS.COLUMN_DESCRIPTION', 'Description')}</HeadCell>
                  <HeadCell>{tr('FRONTEND.REPORTS.COLUMN_EXPENSES', 'Expenses')}</HeadCell>
                  <HeadCell>{tr('FRONTEND.REPORTS.COLUMN_STATUS', 'Status')}</HeadCell>
                  <HeadCell className='text-right'>{tr('FRONTEND.REPORTS.COLUMN_TOTAL', 'Total')}</HeadCell>
                </Row>
              </Head>
            )}

            <Body>
              {data.map((report: ExpenseManagementReport) => {
                return (
                  <Row
                    key={report.id}
                    data-test={`open-report-${report.id}`}
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      handleReportRowClick(report)
                    }}>
                    <Cell className='table-cell name' colSpan={2} data-test={`name`}>
                      <RowIcon style={{ width: '24px', height: '24px', display: 'block' }} />
                      <div>
                        {report.name}
                        {!isLarge &&
                          formatDateToUserLocale(report.fromDate) &&
                          formatDateToUserLocale(report.toDate) && (
                            <span className='period'>
                              {formatDateToUserLocale(report.fromDate)} - {formatDateToUserLocale(report.toDate)}
                            </span>
                          )}
                      </div>
                    </Cell>
                    {isApproverTable && isXLarge && <Cell colSpan={2}>{report.submitter}</Cell>}

                    {isXLarge && (
                      <Cell data-test={`period`}>
                        {moment(new Date(report.fromDate)).format('DD/MM/YYYY')} -{' '}
                        {moment(new Date(report.toDate)).format('DD/MM/YYYY')}
                      </Cell>
                    )}
                    {isLarge && (
                      <>
                        <Cell data-test={`description`}>{report.description}</Cell>
                        <Cell data-test={`expenses`}>{report.expenses.length}</Cell>
                        <Cell data-test={`status`}>
                          <ReportStatusBadge status={report.state} />
                        </Cell>
                      </>
                    )}
                    <Cell className='total text-right' data-test={`total`}>
                      <div className='amount'>
                        <span className='total-amount'>
                          {formatFloat(report.totalAmount, getCurrencyDecimals(report.currency))}
                          <span className='row-amount-currency'>{report.currency}</span>
                        </span>
                        {!isLarge && (
                          <span>
                            <ReportStatusBadge status={report.state} />
                          </span>
                        )}
                      </div>
                    </Cell>
                  </Row>
                )
              })}
            </Body>
          </>
        ) : (
          <NoReportsComponent title='No Reports' />
        )}
      </Table>
    </div>
  )
}
