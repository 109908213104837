import styled from 'styled-components'

export const TableWrapper = styled.div`
  box-shadow: 0 4px 25px 0 #2e35381a;

  @media (max-width: 800px) {
    box-shadow: none;
  }
  padding-top: 16px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .recipients-table {
    width: 100%;
    padding: 15px 13px;
  }
`
