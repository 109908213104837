import DownloadIcon from '@images/icons/download-template-icon.svg?react'

import './templateButton.scss'

interface TemplateButtonProps {
  text?: string
  dataTest?: string
  disabled?: boolean
  onClick: () => void
}

export const TemplateButton = ({ text, dataTest, disabled, onClick }: TemplateButtonProps) => (
  <button className='template-button-styled' type='button' disabled={disabled} data-test={dataTest} onClick={onClick}>
    <div className='template-button-styled-content'>
      <DownloadIcon />
      <span>{text}</span>
    </div>
  </button>
)
