import { MerchantAccountTypeEnum, TwoFactorTypeEnum } from 'mmfintech-commons-types'
import { useGetMerchantQuery, useGetProfileQuery } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'

export const setCurrentStatus = (key: string) => {
  const { data: merchant } = useGetMerchantQuery()
  const { onboardingStatus, contactPhone, accountType } = merchant || {}
  const { data: profile } = useGetProfileQuery(null)
  const { twoFactorPhone, email } = profile || {}
  switch (key) {
    case 'accountVerification':
      return accountType === MerchantAccountTypeEnum.PROSPECT
        ? tr(`METADATA.SUMSUB_STATUS.${onboardingStatus}`, onboardingStatus.toLowerCase().split('_').join('-'))
        : tr('METADATA.SUMSUB_STATUS.APPROVED', 'Approved')
    case 'email':
      return email
    case 'twoFactorAuth':
      if (profile?.twoFactor === TwoFactorTypeEnum.TOTP) {
        return tr('	FRONTEND.SECURITY.TWO_FACTOR.GA', 'Google Authenticator')
      }

      return profile?.twoFactor?.toLowerCase() || ''
    case 'phoneNumber':
      return twoFactorPhone || contactPhone
    case 'setPhoneNumber':
      return null
    default:
      break
  }
}
