import { useState } from 'react'

import { tr } from 'mmfintech-commons'

import { CoreButton } from '@components'
import settings from '@settings'

import ClientFaqIcon from '@images/icons/client_faq_icon.svg?react'
import ContactSupportIcon from '@images/icons/contact_support_icon.svg?react'

import './SupportBlock.scss'

const extraSupportConst = [
  {
    Icon: <ClientFaqIcon />,
    title: (() => tr('FRONTEND.DEPOSIT.LIVE_CHAT_SUPPORT', 'Client FAQ'))(),
    path: settings.FAQUrl,
    enableChatOpen: false
  },
  {
    Icon: <ContactSupportIcon />,
    title: (() => tr('FRONTEND.DEPOSIT.LIVE_CHAT_SUPPORT', 'Contact support'))(),
    path: settings.customerServiceUrl,
    enableChatOpen: false
  }
]

export const SupportBlock = () => {
  const [isOpenChat, setIsOpenChat] = useState<boolean>(false)

  const openChat = () => {
    const windowObj = window as any
    if (windowObj?.zE) {
      setIsOpenChat(!isOpenChat)
      isOpenChat ? windowObj?.zE('messenger', 'open') : windowObj?.zE('messenger', 'close')
    }
  }
  return (
    <div className='deposit-right-side'>
      <div className='deposit-right-side-title'>
        {tr('FRONTEND.DEPOSIT.EXTRA_SUPPORT_LABEL', 'Need extra support?')}
      </div>
      {extraSupportConst.map(({ Icon, path, title, enableChatOpen }) => {
        return enableChatOpen ? (
          <div onClick={() => enableChatOpen && openChat()} className='deposit-right-side-support-wrapper' key={title}>
            <CoreButton collapsed CollapsedIcon={Icon} variation='elevated' size='large' />
            <div>{title}</div>
          </div>
        ) : (
          <a href={path} className='deposit-right-side-support-wrapper' key={title}>
            <CoreButton collapsed CollapsedIcon={Icon} variation='elevated' size='large' />
            <div>{title}</div>
          </a>
        )
      })}
    </div>
  )
}
