import { useContext } from 'react'

import toast from 'react-hot-toast'

import { useGetReportQuery, useProcessReportMutation } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'

import { ApproveModal, RejectModal, ReturnModal } from '@views/reports/report/modals'
import { CoreButton } from '@components'

import ApproveIcon from '@images/expenses/approve.svg?react'
import RejectIcon from '@images/expenses/reject.svg?react'
import ReturnIcon from '@images/expenses/return.svg?react'

const options = { closeOnEscape: true, closeOnClickOutside: true }

export const ActionReportButtons = ({ reportId }) => {
  const { modalShow, modalHide } = useContext(GlobalContext)
  const [processReport] = useProcessReportMutation()
  const { data: report } = useGetReportQuery({ id: Number(reportId) })
  const handleSubmit = async payload => {
    if (report) {
      try {
        await processReport({
          ...payload,
          reportId: report.id
        }).unwrap()

        modalHide()
      } catch (err) {
        toast.error(err?.error)
      }
    }
  }
  const modalProps = { handleSubmit, reportName: report?.name, reportId: reportId }

  const handleOpenApproveModal = () => {
    modalShow({
      header: tr('FRONTEND.REPORTS.ACTIONS.APPROVE_REPORT', 'Approve Report'),
      content: <ApproveModal {...modalProps} onClose={modalHide} />,
      options
    })
  }

  const handleOpenReturnModal = () => {
    modalShow({
      header: tr('FRONTEND.REPORTS.ACTIONS.RETURN_REPORT', 'Return Report'),
      content: <ReturnModal {...modalProps} onClose={modalHide} />,
      options
    })
  }

  const handleOpenRejectModal = () => {
    modalShow({
      header: tr('FRONTEND.REPORTS.ACTIONS.REJECT_REPORT', 'Reject Report'),
      content: <RejectModal {...modalProps} onClose={modalHide} />,
      options
    })
  }

  return (
    <>
      <CoreButton
        text={tr('FRONTEND.REPORTS.ACTIONS.APPROVE', 'Approve')}
        LeftIcon={<ApproveIcon />}
        data-test='button-approve'
        onClick={handleOpenApproveModal}
        size='large'
      />
      <CoreButton
        text={tr('FRONTEND.REPORTS.ACTIONS.REJECT', 'Reject')}
        LeftIcon={<RejectIcon />}
        data-test='button-reject'
        onClick={handleOpenRejectModal}
        size='large'
      />
      <CoreButton
        variation='tertiary'
        text={tr('FRONTEND.REPORTS.ACTIONS.RETURN', 'Return')}
        LeftIcon={<ReturnIcon />}
        data-test='button-return'
        onClick={handleOpenReturnModal}
        size='large'
      />
    </>
  )
}
