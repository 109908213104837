import { useState } from 'react'

import { CoreButton, CoreModalDialog } from '@components'
import { ReportEditModal } from '../../report/modals/ReportEditModal/ReportEditModal'
import { AddEditExpenseButton } from '../../report/reportHeaders/AddEditExpenseButton'
// import { HeaderContainer, VerticallyCentered } from '../../ReportsTableHeaderStyled'

import { tr } from 'mmfintech-commons'

import AddReportIcon from '@images/expenses/add-report.svg?react'

import './reportsActions.scss'

export const ReportsViewHeader = () => {
  const [isReportCreateModalVisible, setIsReportCreateModalVisible] = useState<boolean>(false)

  const handleCreateModalOpen = () => setIsReportCreateModalVisible(true)
  const handleCreateModalClose = () => {
    setIsReportCreateModalVisible(false)
  }

  return (
    <div className='reports-actions'>
      <AddEditExpenseButton />
      <CoreButton
        text={tr('FRONTEND.EXPENSE_MANAGEMENT.ADD_REPORT', 'Add Report')}
        LeftIcon={<AddReportIcon />}
        data-test='button-add-report'
        onClick={handleCreateModalOpen}
        size='large'
      />

      <CoreModalDialog
        header={tr('FRONTEND.REPORTS.CREATE.CREATE_NEW_REPORT', 'Create New Report')}
        options={{ closeOnEscape: false, closeOnClickOutside: false }}
        visible={isReportCreateModalVisible}
        content={<ReportEditModal onClose={handleCreateModalClose} />}
        onClose={handleCreateModalClose}
      />
    </div>
  )
}
