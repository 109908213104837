import './coreSteps.scss'

export const CoreSteps = ({
  step,
  maxStep,
  title
}: {
  step: number | string
  maxStep: number | string
  title?: string
}) => {
  return (
    <div className='step-info'>
      <span className='step'>
        {step}/{maxStep}
      </span>
      {title && (
        <>
          <div className='dash' />
          <span className='step-name'>{title}</span>
        </>
      )}
    </div>
  )
}
