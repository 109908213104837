import React, { createContext, useState } from 'react'

interface HeaderProps {
  currentStep: number
  totalSteps: []
  setCurrentStep?: React.Dispatch<React.SetStateAction<number>>
  setTotalSteps?: React.Dispatch<React.SetStateAction<any>>
}

export const HeaderContext = createContext<HeaderProps>({
  currentStep: 1,
  totalSteps: []
})

interface Props {
  children?: React.ReactNode
}

const HeaderContextProvider: React.FC<Props> = ({ children }) => {
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [totalSteps, setTotalSteps] = useState<[]>([])

  return (
    <HeaderContext.Provider
      value={{
        currentStep,
        totalSteps,
        setCurrentStep,
        setTotalSteps
      }}>
      {children}
    </HeaderContext.Provider>
  )
}

export default HeaderContextProvider
