import InfoIcon from '@images/icons/info-circle-black.svg?react'

import './warningMessage.scss'

interface WarningMessageProps {
  title: string
  lines: string[]
}

export const WarningMessage = ({ title, lines }: WarningMessageProps) => {
  return (
    <div className='warning-message-wrapper warning-block'>
      <InfoIcon />
      <div className='right'>
        <div className='label'>{title}</div>

        {lines?.map((line: string, index: number) => <p key={index}>{line}</p>)}
      </div>
    </div>
  )
}
