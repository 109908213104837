import { useEffect, useMemo } from 'react'

import { selectCurrentUserId, useAppSelector, useLazyGetUserExpenseLimitsQuery } from 'mmfintech-backend-api'
import { formatFloat, getCurrencyDecimals, tr } from 'mmfintech-commons'

import './spendingLimitBarUser.scss'

export const SpendingLimitBarUser = () => {
  const [getUserExpenseLimits, { data: userExpenseLimits }] = useLazyGetUserExpenseLimitsQuery()
  const userId = useAppSelector(selectCurrentUserId)

  const { availableMonthlyVolume, monthlyVolume, currency, spentMonthlyVolume } = userExpenseLimits || {}
  useEffect(() => {
    if (userId) {
      getUserExpenseLimits({ merchantUserId: userId })
    }
  }, [userId])

  const availableMonthlyVol = availableMonthlyVolume > 0 ? availableMonthlyVolume : 0
  const progressPercentage = monthlyVolume ? 100 - (availableMonthlyVol / monthlyVolume) * 100 : 100

  const progressBarColor = useMemo(() => {
    if (monthlyVolume) {
      return progressPercentage <= 50 ? 'green' : progressPercentage <= 80 ? 'orange' : 'red'
    } else {
      return 'blue'
    }
  }, [monthlyVolume, progressPercentage])

  if (!userExpenseLimits) {
    return null
  }

  return (
    <div className='progress-bar-wrapper-container'>
      <div className='progress-bar-info-wrapper'>
        <div className='money-spent'>
          <span className='info-text'>
            {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.MONEY_SPENT', 'Money Spent:')}
          </span>
          {formatFloat(spentMonthlyVolume, getCurrencyDecimals(currency))} <span className='currency'>{currency}</span>
        </div>
        <div className='monthly-limit'>
          <span className='info-text'>
            {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.MONTHLY_LIMIT', 'Monthly Limit:')}{' '}
          </span>
          {monthlyVolume ? (
            <>
              {formatFloat(monthlyVolume, getCurrencyDecimals(currency))} <span className='currency'>{currency}</span>
            </>
          ) : (
            <span className='infinity'>∞</span>
          )}
        </div>
      </div>
      <div className={`progress-bar-with-info-wrapper `}>
        <div
          style={{ width: `${progressPercentage < 9 ? 9 : progressPercentage}%` }}
          className={`progress-bar ${progressBarColor}`}
        />
      </div>
    </div>
  )
}
