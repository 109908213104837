import { useContext, useState } from 'react'

import { useChangeTwoFactorMutation } from 'mmfintech-backend-api'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { GlobalContext, tr } from 'mmfintech-commons'

import { CoreButton, CoreModalDialogFooter, CoreSteps } from '@components'
import { GoogleAuthenticatorInfo } from './GoogleAuthenticatorInfo'
import { WelcomeContent2 } from './WelcomeContent2'
import { useOnboarding } from '@hooks'

import './welcomeModal.scss'

type TWelcomeModalProps = {
  currentStep?: number
  isSuccess2Fa?: boolean
}

export const WelcomeModal = ({ currentStep, isSuccess2Fa }: TWelcomeModalProps) => {
  const [change2fa, { error: change2faError }] = useChangeTwoFactorMutation()
  const { startOrContinueOnboarding } = useOnboarding()
  const [step, setStep] = useState(currentStep || 1)
  const { modalHide } = useContext(GlobalContext)

  const handleSubmit = e => {
    if (e) e.preventDefault()

    change2fa({
      twoFactor: 'TOTP'
    })
  }

  return (
    <div className='welcome-wrapper'>
      <div className='welcome-container' data-test='welcome-modal'>
        <CoreSteps step={step} maxStep={2} />

        {step === 1 ? <GoogleAuthenticatorInfo /> : <WelcomeContent2 isSuccess2Fa={isSuccess2Fa} />}

        <ErrorDisplay error={change2faError} />

        <CoreModalDialogFooter>
          <div className='footer-buttons'>
            {step === 1 ? (
              <>
                <CoreButton
                  text={tr('FRONTEND.WELCOME.MODAL.SET_UP', 'Set up')}
                  onClick={handleSubmit}
                  size='large'
                  fullWidth
                />
                <CoreButton
                  variation='tertiary'
                  text={tr('FRONTEND.WELCOME.MODAL.SKIP_FOR_NOW', 'Skip for now')}
                  onClick={() => setStep(2)}
                  size='large'
                />
              </>
            ) : (
              <>
                <CoreButton
                  text={tr('FRONTEND.WELCOME.MODAL.START_ONBOARDING', 'Start Onboarding')}
                  onClick={startOrContinueOnboarding}
                  size='large'
                  fullWidth
                />
                <CoreButton
                  variation='tertiary'
                  text={tr('FRONTEND.WELCOME.MODAL.DISMISS', 'Dismiss')}
                  onClick={modalHide}
                  size='large'
                />
              </>
            )}
          </div>
        </CoreModalDialogFooter>
      </div>
    </div>
  )
}
