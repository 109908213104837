import { GlobalContext, tr, translateCountryName } from 'mmfintech-commons'
import { useFindCustomerQuery } from 'mmfintech-backend-api'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { useContext } from 'react'

import { CoreButton, CoreLoader, CoreModalDialogFooter } from '@components'
import { CustomerEdit } from '../CustomerEdit'

import './customerDetailsDialog.scss'

export const CustomerDetailsDialog = ({ onClose, payerId }: { onClose: () => void; payerId: number }) => {
  const { modalHide, modalShow } = useContext(GlobalContext)
  const {
    data: customer,
    error: customerError,
    isFetching: customerFetching
  } = useFindCustomerQuery({ payerId }, { skip: !payerId })

  const handleEditCustomer = () => {
    modalShow({
      options: { size: 'auto', closeOnClickOutside: false },
      content: <CustomerEdit payerId={payerId} onClose={modalHide} />,
      header: (
        <span style={{ paddingRight: '6.8rem' }}>{tr('FRONTEND.CUSTOMERS.EDIT.TITLE_EDIT', 'Edit Customer')}</span>
      )
    })
  }

  const {
    name,
    registrationNumber,
    vatNumber,
    addressLine1,
    city,
    postalCode,
    countryCode,
    email,
    phone,
    invoiceCounter,
    website,
    type
  } = customer || {}

  return (
    <div className='customer-details-dialog'>
      {customerFetching ? (
        <div className='loading-container'>
          <CoreLoader />
        </div>
      ) : (
        <>
          <div className='customer-details-container'>
            <div className='customer-details-card'>
              <span className='name'>{name}</span>
              <div className='open-invoices'>
                <div className='dot' />
                <span>
                  {invoiceCounter?.open ? invoiceCounter.open : 0}{' '}
                  {tr('FRONTEND.CUSTOMERS.INVOICES.COUNT_OPEN', 'Open invoices')}
                </span>
              </div>
            </div>
            <div className='customer-details-card'>
              <span className='card-title'>{tr('FRONTEND.CUSTOMERS.EDIT.TITLE_PAYMENT', 'Payment information')}</span>
              <div className='information-table'>
                <InformationRow
                  label={tr('FRONTEND.CUSTOMERS.EDIT.REG_NUMBER', 'Registration №')}
                  value={registrationNumber}
                />
                <InformationRow label={tr('FRONTEND.CUSTOMERS.EDIT.VAT_NUMBER', 'VAT Number')} value={vatNumber} />
                <InformationRow label={tr('FRONTEND.CUSTOMERS.EDIT.EMAIL', 'E-mail')} value={email} />
                <InformationRow label={tr('FRONTEND.CUSTOMERS.EDIT.WEBSITE', 'Website URL')} value={website} />
              </div>
            </div>
            <div className='customer-details-card'>
              <span className='card-title'>{tr('FRONTEND.CUSTOMERS.EDIT.TITLE_PERSONAL', 'Personal information')}</span>
              <div className='information-table'>
                <InformationRow label={tr('FRONTEND.CUSTOMERS.EDIT.PHONE', 'Phone')} value={phone} />
                <InformationRow label='ID' value={String(payerId)} />
                <InformationRow
                  label={tr('FRONTEND.CUSTOMERS.EDIT.COUNTRY', 'Country')}
                  value={translateCountryName(countryCode)}
                />
                <InformationRow
                  label={
                    type === 'COMPANY'
                      ? tr('FRONTEND.CUSTOMERS.EDIT.REG_ADDRESS', 'Registered Address')
                      : tr('FRONTEND.CUSTOMERS.EDIT.ADDRESS', 'Address')
                  }
                  value={addressLine1}
                />
                <InformationRow label={tr('FRONTEND.CUSTOMERS.EDIT.CITY', 'City')} value={city} />
                <InformationRow label={tr('FRONTEND.CUSTOMERS.EDIT.POSTAL_CODE', 'Postal Code')} value={postalCode} />
              </div>
            </div>
            <ErrorDisplay error={customerError} />
          </div>

          <CoreModalDialogFooter className='customer-footer'>
            <CoreButton
              text={tr('FRONTEND.BUTTONS.EDIT', 'Edit')}
              onClick={handleEditCustomer}
              disabled={customerError}
              size='large'
              fullWidth
            />
            <CoreButton
              text={tr('FRONTEND.BUTTONS.CLOSE', 'Close')}
              variation='tertiary'
              onClick={onClose}
              size='large'
            />
          </CoreModalDialogFooter>
        </>
      )}
    </div>
  )
}

const InformationRow = ({ label, value }: { label: string; value: string }) => {
  if (!value) return null

  return (
    <div className={`information-row`}>
      <span className='label'>{label}</span>
      <span className='value'>{value}</span>
    </div>
  )
}
