import { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import { useChangePhoneMutation, useGetMerchantQuery, useGetProfileQuery } from 'mmfintech-backend-api'
import {
  OtpContext,
  checkFinalValue,
  formatPhoneNumber,
  tr,
  translateError,
  useFormValues
} from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { CoreButton, CoreInput, CoreModalDialogFooter } from '@components'

import '../styled/changeMobile.scss'

function ChangeMobileNumber() {
  const { setOtpOnSuccess, setOtpOnError } = useContext(OtpContext)

  const { data: profile } = useGetProfileQuery(null)
  const { data: merchant } = useGetMerchantQuery()
  const { contactPhone } = merchant || {}
  const [changePhone, { error: changePhoneError }] = useChangePhoneMutation()

  const { twoFactorPhone } = profile || {}

  const [phoneError, setPhoneError] = useState(null)

  const formValues = useFormValues({
    phone: { required: true, validation: 'phone', value: twoFactorPhone || contactPhone }
  })

  const handleSubmit = e => {
    if (e) e.preventDefault()
    setOtpOnSuccess(() => response => {
      if (!response?.challenge) {
        toast.success(tr('FRONTEND.SECURITY.PHONE.SUCCESS_MESSAGE', 'Mobile phone is saved'))
      }
    })
    setOtpOnError(() => response => {
      toast.error(tr(response?.errorKey, response?.error))
    })
    const result = checkFinalValue(formValues.getValue('phone'), {
      validation: 'phone',
      required: true
    })
    if (!result.valid) {
      setPhoneError(translateError(result))
      return
    }
    if (formatPhoneNumber(twoFactorPhone || '') === formatPhoneNumber(formValues.getValue('phone'))) {
      setPhoneError(
        tr('FRONTEND.SECURITY.PHONE.ERROR_EQUAL', 'The phone number matches the one already entered in the system.')
      )
      return
    }
    setPhoneError(null)
    changePhone(formValues.prepare())
  }

  useEffect(() => {
    formValues.handleErrors(changePhoneError)
    // eslint-disable-next-line
  }, [changePhoneError])

  return (
    <form className='profile-mobile-wrapper' noValidate onSubmit={handleSubmit} data-test='change-mobile-number-modal'>
      <div className='profile-mobile-input'>
        <CoreInput
          type='phone'
          name='phone'
          label={tr('FRONTEND.SECURITY.PHONE.NUMBER', 'Mobile Number')}
          {...formValues.registerInput('phone')}
          error={formValues.getError('phone') || phoneError}
          autoComplete='off'
          autoFocus
          data-test='change-mobile-number-input'
          size='large'
        />
      </div>

      <ErrorDisplay error={changePhoneError} />
      <CoreModalDialogFooter>
        <CoreButton
          fullWidth
          size='large'
          text={tr('FRONTEND.ACCOUNTS.DETAILS.BUTTON.SAVE', 'Save')}
          data-test='change-mobile-number-save-button'
        />
      </CoreModalDialogFooter>
    </form>
  )
}

export default ChangeMobileNumber
