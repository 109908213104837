import { formatMoney, getCurrencyDecimals, isValidArray, tr } from 'mmfintech-commons'
import { MerchantActivityOut } from 'mmfintech-commons-types'
import { useEffect, useRef } from 'react'

import AmericanExpressIcon from '@images/icons/cardTypes/amex.svg?react'
import DefaultCardIcon from '@images/icons/cardTypes/card-icon.svg?react'
import DinersIcon from '@images/icons/cardTypes/diners.svg?react'
import DiscoverIcon from '@images/icons/cardTypes/discover.svg?react'
import JCBIcon from '@images/icons/cardTypes/jcb.svg?react'
import MastercardIcon from '@images/icons/cardTypes/mastercard.svg?react'
import VisaIcon from '@images/icons/cardTypes/visa.svg?react'

enum CardTypeEnum {
  // noinspection SpellCheckingInspection
  ELECTRON = 'ELECTRON',
  MAESTRO = 'MAESTRO',
  DANKORT = 'DANKORT',
  INTERPAYMENT = 'INTERPAYMENT',
  UNITONPAY = 'UNITONPAY',
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMEX = 'AMEX',
  DINERS_CLUB = 'DINERS_CLUB',
  DISCOVER = 'DISCOVER',
  JCB = 'JCB',
  OTHER = 'OTHER'
}

function titleCase(str: string): string {
  let splitStr = str.toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

export const fillActivityFields = (activity: MerchantActivityOut, translatedActivity: string): string => {
  let result = translatedActivity

  if (isValidArray(activity?.activityFields) && translatedActivity.includes('{{')) {
    activity.activityFields.forEach(activityField => {
      let replaceValue = activityField.value
      if (replaceValue.includes('METADATA.') || replaceValue.includes('MERCHANTS.')) {
        replaceValue = tr(activityField.value, titleCase(activityField.value.split('.').pop()))
      }
      if (activityField.key.includes('AMOUNT')) {
        const [value, currency] = activityField.value.split(';')
        replaceValue = formatMoney(value, currency, getCurrencyDecimals(currency))
      }
      result = result.replace(`{{${activityField.key}}}`, replaceValue)
    })
  }
  return result
}

export const useDebounce = (callback, delay) => {
  const timeoutRef = useRef(null)

  useEffect(() => {
    // Cleanup the previous timeout on re-render
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const debouncedCallback = (...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      callback(...args)
    }, delay)
  }

  return debouncedCallback
}

export function getDateBeforeMonths(months: number): string {
  const currentDate = new Date()
  currentDate.setMonth(currentDate.getMonth() - months)

  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0') // getMonth() is zero-based
  const day = String(currentDate.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export const detectCardType = (number: number): CardTypeEnum => {
  var re = {
    [CardTypeEnum.ELECTRON]: /^(4026|417500|4508|4844|4913|4917)\d{10}$/,
    [CardTypeEnum.MAESTRO]: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    [CardTypeEnum.DANKORT]: /^(5019)\d+$/,
    [CardTypeEnum.INTERPAYMENT]: /^(636)\d+$/,
    [CardTypeEnum.UNITONPAY]: /^(62|88)\d+$/,
    [CardTypeEnum.VISA]: /^4\d{12}(\d{3})?$/,
    [CardTypeEnum.MASTERCARD]:
      /^(?:(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12})$/,
    [CardTypeEnum.AMEX]: /^3[47][0-9]{5,}$/,
    [CardTypeEnum.DINERS_CLUB]: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
    [CardTypeEnum.DISCOVER]: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
    [CardTypeEnum.JCB]: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/
  }

  for (let key in re) {
    if (re[key].test(number)) {
      return key as CardTypeEnum
    }
  }

  return CardTypeEnum.OTHER
}

export const CardIssuerIcon = (number: number): React.FunctionComponent => {
  const cardType = detectCardType(number)

  const cardIcons = {
    [CardTypeEnum.VISA]: VisaIcon,
    [CardTypeEnum.AMEX]: AmericanExpressIcon,
    [CardTypeEnum.DINERS_CLUB]: DinersIcon,
    [CardTypeEnum.MASTERCARD]: MastercardIcon,
    [CardTypeEnum.DISCOVER]: DiscoverIcon,
    [CardTypeEnum.JCB]: JCBIcon
  }

  if (cardIcons[cardType]) return cardIcons[cardType]

  return DefaultCardIcon
}

export const IsCollapsedMenu = ({ setCollapsedMenu }) => {
  useEffect(() => {
    const targetNode = document.querySelector('.core-menu-wrapper')

    if (targetNode.classList.contains('collapsed')) setCollapsedMenu(true)

    const callback = mutationList => {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes' && mutation.attributeName == 'class') {
          setCollapsedMenu(mutation.target.classList.contains('collapsed'))
        }
      }
    }

    const observer = new MutationObserver(callback)

    observer.observe(targetNode, { attributes: true, childList: false, subtree: false })

    return () => {
      observer.disconnect()
    }
  }, [])

  return null
}

export const cutCryptoAddress = (address: string, firstChars: number = 6, lastChars: number = 4): string => {
  if (!address || address.length <= firstChars + lastChars) {
    return address
  }

  const start = address.slice(0, firstChars)
  const end = address.slice(-lastChars)

  return `${start}...${end}`
}
