import React, { useCallback, useMemo, useState } from 'react'
import { Document, Page } from 'react-pdf'

import { CoreButton, CoreLoader } from '@components'
import { breakpoints } from '@constants'
import { useMatchMedia } from '@hooks'

import PageChangeIcon from '@images/icons/pagination-right-arrow-icon.svg?react'
import ArrowDownIcon from '@images/icons/download-invoices.svg?react'
import Default from '@images/icons/receipt-default.svg?react'
import CloseIcon from '@images/icons/close.svg?react'

import './previewFile.scss'

const VIEWER_WIDTH = 384
const MIN_VIEWER_HEIGHT = 385
const SCALE = 2

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/'
}

export interface PreviewFileProps {
  className?: string
  file: {
    type: string
    fileUrl: string
  }
  isLoading?: boolean
  error?: string
  removeFile?: () => void
  downloadFile?: () => void
  stylingOptions?: {
    width?: number
    height?: number
    scale?: number
    showFooter?: boolean
  }
}

export const PreviewFile: React.FC<PreviewFileProps> = props => {
  const { className, file, removeFile, downloadFile, isLoading, stylingOptions } = props
  const isMobile = useMatchMedia({ breakpoint: breakpoints.md, prefix: 'max' })
  const {
    width = VIEWER_WIDTH,
    height = MIN_VIEWER_HEIGHT,
    scale = width < 100 ? 1 : SCALE,
    showFooter = true
  } = stylingOptions || {}

  const cursorType = height > 100 ? 'crosshair' : 'pointer'

  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [imageSrc, setImageSrc] = useState('')
  const [isPdfError, setIsPdfError] = useState(false)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
    setIsPdfError(false)
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  function previousPage() {
    if (pageNumber > 1) {
      changePage(-1)
    }
  }

  function nextPage() {
    if (pageNumber < numPages) {
      changePage(1)
    }
  }

  const handleMouseMove = useCallback(event => {
    const wrapper = event.currentTarget
    const rect = wrapper.getBoundingClientRect()
    if (width < 100) {
      return
    }
    const xPercent = ((event.clientX - rect.left) / rect.width) * 100
    const yPercent = ((event.clientY - rect.top) / rect.height) * 100

    wrapper.style.setProperty('--x', `${xPercent}%`)
    wrapper.style.setProperty('--y', `${yPercent}%`)
    wrapper.style.setProperty('--zoom', `${scale}`)
  }, [])

  useMemo(() => {
    if (file) {
      setNumPages(0)
      setImageSrc(file.fileUrl)
    }
  }, [file])

  if (!isLoading && !file) {
    return <div>Could not load the file</div>
  }

  return (
    <div className={`document-container-preview ${className ? className : ''}`}>
      <div
        className='document-wrapper'
        style={{
          width: isMobile ? '100%' : width
        }}>
        {isLoading ? (
          <div style={{ margin: '0 auto' }}>
            <CoreLoader />
          </div>
        ) : file?.type?.includes('image') ? (
          <div
            className='page-wrapper'
            style={{
              maxHeight: `${height}px`,
              cursor: cursorType
            }}
            onMouseLeave={e => {
              e.currentTarget.style.setProperty('--zoom', `1`)
            }}
            onMouseMove={handleMouseMove}>
            {imageSrc ? (
              <img
                src={imageSrc}
                width={isMobile ? '100%' : width}
                style={{ objectFit: 'contain' }}
                onError={() => setImageSrc('')}
                loading='lazy'
                alt=''
              />
            ) : (
              <Default style={{ width: isMobile ? '100%' : width, height: '100%' }} />
            )}
          </div>
        ) : (
          <>
            {!isPdfError ? (
              <Document
                file={file.fileUrl}
                options={options}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={() => setIsPdfError(true)}>
                <div
                  className='page-wrapper'
                  style={{
                    maxHeight: `${height}px`,
                    cursor: cursorType
                  }}
                  onMouseLeave={e => {
                    e.currentTarget.style.setProperty('--zoom', `1`)
                  }}
                  onMouseMove={handleMouseMove}>
                  <Page pageNumber={pageNumber} width={width} _className='pdf-page' devicePixelRatio={scale} />
                </div>
              </Document>
            ) : (
              <Default style={{ width: isMobile ? '100%' : width, height: '100%' }} />
            )}
          </>
        )}
      </div>
      {showFooter ? (
        <div className='page-footer'>
          <div className='buttons-container'>
            {typeof removeFile === 'function' ? (
              <CoreButton
                text='Remove'
                onClick={removeFile}
                LeftIcon={<CloseIcon />}
                className='action-button'
                variation='tertiary'
              />
            ) : null}
            {typeof downloadFile === 'function' ? (
              <CoreButton
                text='Download'
                onClick={downloadFile}
                className='action-button'
                LeftIcon={<ArrowDownIcon />}
                variation='tertiary'
              />
            ) : null}
          </div>
          {numPages > 0 && (
            <div className='controls'>
              <PageChangeIcon className='forward-icon' onClick={previousPage} />
              <div>
                {pageNumber} of {numPages}{' '}
              </div>
              <PageChangeIcon onClick={nextPage} />
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}
