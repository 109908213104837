import React from 'react'

import { tr } from 'mmfintech-commons'

import { CoreButton, CoreModalDialogFooter } from '@components'

import './noReferenceModal.scss'

interface NoReferenceModalProps {
  onClose: () => void
  handleContinue: (e: React.MouseEvent) => void
}

export const NoReferenceModal = ({ onClose, handleContinue }: NoReferenceModalProps) => {
  return (
    <div className='no-reference-modal-wrapper'>
      <div className='content-text'>
        {tr(
          'FRONTEND.WITHDRAW.BANK_TRANSFER.NO_REFERENCE.TEXT',
          'The recipient might not know who is making the transfer. Do you want to continue anyway?'
        )}
      </div>

      <CoreModalDialogFooter className='footer'>
        <div className='actions'>
          <CoreButton
            data-test='continue-button'
            text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
            onClick={(e: React.MouseEvent) => {
              handleContinue(e)
              onClose()
            }}
            size='large'
            fullWidth
          />
          <CoreButton
            data-test='cancel-button'
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            onClick={onClose}
            size='large'
            variation='tertiary'
          />
        </div>
      </CoreModalDialogFooter>
    </div>
  )
}
