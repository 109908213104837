import './CoreCoinLoader.scss'
import EllipseIcon from '@images/еllipse-coin.svg?react'

export const CoreCoinLoader = () => {
  return (
    <div className='loader-wrapper'>
      <div className='loader-container'>
        <div className='loader-coin'>
          <EllipseIcon className='front' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <div className='fill' />
          <EllipseIcon className='back' />
        </div>
      </div>
    </div>
  )
}
