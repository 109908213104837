import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  responseDoesNotContainsChallenge,
  selectCurrentUserRole,
  useAppSelector,
  useGetMerchantQuery,
  useIntraAccountTransferQry,
  useMerchantAccounts
} from 'mmfintech-backend-api'
import { CustomerRoleEnum, MerchantAccountTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { isValidArray, tr } from 'mmfintech-commons'

import {
  AccessDenied,
  CoreApplicationFeeAnnounce,
  CoreButton,
  CorePaymentInput,
  CoreSelect,
  SuccessPane
} from '@components'
import { IntraAccountTransferPreview } from './IntraAccountTransferPreview'
import { HeaderContext } from '../../../context/HeaderContext'

export const IntraAccountTransfer = () => {
  const { setCurrentStep, currentStep } = useContext(HeaderContext)
  const customerRole = useAppSelector(selectCurrentUserRole)
  const [result, setResult] = useState(null)
  const history = useHistory()
  const { data: merchant } = useGetMerchantQuery(null)
  const { accountType, onboardingStatus } = merchant || {}
  const { activeAccounts } = useMerchantAccounts()
  const [filteredSourceAccounts, setFilteredSourceAccounts] = useState(activeAccounts)
  const [filteredTargetAccounts, setFilteredTargetAccounts] = useState(activeAccounts)

  const {
    formValues,
    isDataValid,
    accountsError,
    accountsFetching,
    sourceAccount,
    setSourceAccount,
    targetAccount,
    setTargetAccount,
    handlePreview,
    handleInitiate,
    previewError,
    previewLoading,
    resetMutations
  } = useIntraAccountTransferQry({
    onPreviewSuccess: () => setCurrentStep(2),
    onInitiateSuccess: (response: any) => handleInitiateSuccess(response),
    validation: 'float'
  })

  useEffect(() => {
    if (isValidArray(activeAccounts) && !isValidArray(filteredSourceAccounts)) {
      setFilteredSourceAccounts(activeAccounts)
    }
  }, [activeAccounts])

  const transferSuccess = (data: any) => {
    setResult(data)
    setCurrentStep(3)
  }

  const handleInitiateSuccess = (data: any): void => {
    if (responseDoesNotContainsChallenge(data)) {
      transferSuccess(data)
    }
  }

  const handleBackClick = () => {
    setCurrentStep(1)
    setResult(null)
    resetMutations()
    history.goBack()
  }

  useEffect(() => {
    if (targetAccount) {
      setFilteredSourceAccounts(activeAccounts.filter(acc => acc.id !== targetAccount.id))
    }
  }, [targetAccount, activeAccounts])

  useEffect(() => {
    if (sourceAccount) {
      setFilteredTargetAccounts(activeAccounts.filter(acc => acc.id !== sourceAccount.id))
    }
  }, [sourceAccount, activeAccounts])

  if (customerRole === CustomerRoleEnum.STAFF) {
    return <AccessDenied />
  }

  return (
    <>
      {currentStep === 1 && (
        <div className='send-money-container'>
          <div className='from-container'>
            <div className='label'>
              <span>From</span>
            </div>
            <CorePaymentInput
              selectedOption={sourceAccount}
              setSelectedAccount={setSourceAccount}
              dataTest={'deposit-payment-input-test'}
              options={filteredSourceAccounts}
              paymentOption={sourceAccount}
              enableMaxAmountButton
              onChange={value => formValues.setValue('amount', value)}
              value={formValues.getValue('amount')}
              error={formValues.getError('amount')}
              isLoading={accountsFetching}
            />
          </div>

          <div className='to-container'>
            <div className='label'>
              <span>{tr('FRONTEND.WITHDRAW.BANK_TRANSFER.TO_LABEL', 'To')}</span>
            </div>
            <CoreSelect
              type='account'
              label={tr('FRONTEND.SEND_OWN_ACCOUNTS.TO_ACCOUNT', 'Account')}
              options={filteredTargetAccounts}
              onChange={(_, v) => {
                setTargetAccount(v)
              }}
              value={targetAccount}
              enableAccountCreation={true}
            />
          </div>

          {accountType === MerchantAccountTypeEnum.PROSPECT && onboardingStatus === OnboardingStatusEnum.APPROVED && (
            <div className='warning-banner'>
              <CoreApplicationFeeAnnounce />
            </div>
          )}

          <ErrorDisplay error={[accountsError, previewError]} />

          <CoreButton
            type='button'
            size='large'
            text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
            disabled={accountsFetching || !isDataValid()}
            isLoading={previewLoading}
            onClick={handlePreview}
            data-test='button-continue'
            fullWidth
          />
        </div>
      )}

      {currentStep === 2 && (
        <IntraAccountTransferPreview
          fromAccount={sourceAccount}
          toAccount={targetAccount}
          onContinue={handleInitiate}
          onCancel={() => setCurrentStep(1)}
        />
      )}

      {currentStep === 3 && <SuccessPane response={result} onClick={handleBackClick} />}
    </>
  )
}
