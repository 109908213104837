import parse from 'html-react-parser'

import {
  getOnboardingStatusName,
  isMerchantIndividual,
  useAppSelector,
  useGetMerchantQuery
} from 'mmfintech-backend-api'
import { CustomerRoleEnum, MerchantAccountTypeEnum } from 'mmfintech-commons-types'
import { tr } from 'mmfintech-commons'

import { useOnboarding } from '@hooks'
import { CoreButton } from '@components'

import './tiers.scss'

export const Tiers = () => {
  const { data: merchant } = useGetMerchantQuery()
  const { onboardingStatus, accountType, entityType, tier } = merchant || {}
  const { customerRole } = useAppSelector(state => state.auth)

  const { startOrContinueOnboarding, shouldPayAccountSetupFee, payAccountSetupFee } = useOnboarding()
  const isIndividual = isMerchantIndividual(merchant)

  return (
    <div className='verification-process'>
      <p className='verification-heading'>{tr('FRONTEND.PROFILE.TIERS.HEADING', 'Tiers')}</p>

      <div className='tiers-container'>
        <div className='verification-level'>
          <div className='level-left'>
            <div className='level-heading'>
              {tr('FRONTEND.PROFILE.TIERS.LEVEL', 'Level %NUMBER%').replace('%NUMBER%', '1')}
            </div>
            {isIndividual
              ? tr(
                  'FRONTEND.PROFILE.TIERS_1',
                  'The basic verification level allows depositing Fiat and Crypto; as well as sending and exchanging Crypto up to $10,000 daily and $50,000 monthly.'
                )
              : tr(
                  'FRONTEND.PROFILE.TIERS.COMPANY.LINE_1',
                  'The basic verification level allows unlimited depositing, sending, and exchanging crypto.'
                )}
          </div>
          <div className='level-right'>
            <div className='verified-container'>
              <CompletedPoint />
              {tr('FRONTEND.PROFILE.TIERS.COMPLETED', 'Completed')}
            </div>
          </div>
        </div>

        <div className='verification-level'>
          <div className='level-left'>
            <div className='level-heading'>
              {tr('FRONTEND.PROFILE.TIERS.LEVEL', 'Level %NUMBER%').replace('%NUMBER%', '2')}
            </div>
            {isIndividual
              ? tr(
                  'FRONTEND.PROFILE.TIERS_2',
                  'Answering some questions increases limits up to $100,000 daily, $500,000 monthly and lets you withdraw & exchange Fiat, Crypto and Forex trading.'
                )
              : tr(
                  'FRONTEND.PROFILE.TIERS.COMPANY.LINE_2',
                  'Answering some questions will unlock depositing and sending fiat, forex trading, as well as Kingdom PSP services.'
                )}
          </div>
          <div className='level-right'>
            {onboardingStatus === 'NOT_STARTED' || onboardingStatus === 'IN_PROGRESS' ? (
              <CoreButton
                variation='primary'
                size='normal'
                text={
                  onboardingStatus === 'NOT_STARTED'
                    ? tr('FRONTEND.PROFILE.TIERS.GET_STARTED', 'Get Started')
                    : tr('FRONTEND.PROFILE.TIERS.IN_PROGRESS', 'In Progress')
                }
                onClick={() => {
                  startOrContinueOnboarding()
                }}
              />
            ) : onboardingStatus !== 'APPROVED' ? (
              <div className='verified-container not-verified'>
                {onboardingStatus ? getOnboardingStatusName(onboardingStatus) : ''}
              </div>
            ) : isIndividual ? (
              <div className='verified-container'>
                <CompletedPoint />
                {tr('FRONTEND.PROFILE.TIERS.COMPLETED', 'Completed')}
              </div>
            ) : !shouldPayAccountSetupFee() ? (
              <div className='verified-container'>
                <CompletedPoint />
                {tr('FRONTEND.PROFILE.TIERS.COMPLETED', 'Completed')}
              </div>
            ) : (
              <CoreButton
                variation='primary'
                size='normal'
                text={tr('FRONTEND.ONBOARDING.SETUP_FEE.PAY_NOW_BUTTON', 'Pay Now')}
                onClick={payAccountSetupFee}
              />
            )}
          </div>
        </div>

        {(accountType === MerchantAccountTypeEnum.COMPANY || entityType === MerchantAccountTypeEnum.COMPANY) &&
          customerRole !== CustomerRoleEnum.STAFF && (
            <div className='verification-level'>
              <div className='level-left'>
                <div className='level-heading'>
                  {tr('FRONTEND.PROFILE.TIERS.LEVEL', 'Level %NUMBER%').replace('%NUMBER%', '3')}
                </div>
                <div>
                  {parse(
                    tr(
                      'FRONTEND.PROFILE.TIERS.COMPANY.LINE_3',
                      'Get in touch with <b>The Kingdom Bank Customer Support</b> or <b>Sales Team</b> and upgrade to Level 3 for a dedicated IBAN.'
                    )
                  )}
                </div>
              </div>
              <div className='level-right'>
                {tier === 'TIER_3' ? (
                  <div className='verified-container'>
                    <CompletedPoint />
                    {tr('FRONTEND.PROFILE.TIERS.COMPLETED', 'Completed')}
                  </div>
                ) : (
                  <CoreButton
                    variation='primary'
                    size='normal'
                    type='button'
                    className='get-started-button'
                    text={tr('FRONTEND.PROFILE.TIERS.GET_IN_TOUCH', 'Get in Touch')}
                    onClick={() => {
                      window.location.href = 'https://calendly.com/meetthekingdombank/hello'
                    }}
                  />
                )}
              </div>
            </div>
          )}
      </div>
    </div>
  )
}

const CompletedPoint = () => {
  return <div className='completed-point' />
}
