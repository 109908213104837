import { useContext } from 'react'

import { GlobalContext, tr } from 'mmfintech-commons'

import { FileUploadModal } from '../FileUploadModal'

import UploadIcon from '@images/icons/upload-icon-blue.svg?react'
import TooltipIcon from '@images/icons/tooltip-icon.svg?react'

import './fileUploadPane.scss'
import ReactTooltip from 'react-tooltip'

export const FileUploadPane = () => {
  const { modalHide, modalShow }: any = useContext(GlobalContext)

  const handleOpenDocumentsModal = () => {
    modalShow({
      options: { closeOnClickOutside: false },
      header: 'Supporting document',
      content: <FileUploadModal onHide={modalHide} />
    })
  }

  return (
    <div className='file-upload-pane-wrapper'>
      <div className='file-upload-pane-title'>
        <span>
          {tr('FRONTEND.WITHDRAW.BANK_TRANSFER.SUPPLEMENTAL_DOCUMENTS.UPLOAD.TITLE', 'Upload Supporting Documents')}
        </span>
        <div data-for={`tooltip-info`} data-tip={'Upload tooltip'}>
          <TooltipIcon />
        </div>
        <ReactTooltip
          id='tooltip-info'
          effect='solid'
          offset={{ left: 160 }}
          className='custom-tool-tip-component file-upload-tooltip'
          place='bottom'>
          <div>
            <h6>
              {tr(
                'FRONTEND.WITHDRAW.BANK_TRANSFER.SUPPLEMENTAL_DOCUMENTS.UPLOAD.TOOLTIP_TITLE',
                'Upload a supporting document for your bank transfer.'
              )}
            </h6>
            <p>
              {tr(
                'FRONTEND.WITHDRAW.BANK_TRANSFER.SUPPLEMENTAL_DOCUMENTS.UPLOAD.TOOLTIP_TEXT',
                'This document is necessary to ensure compliance with financial regulations and to help prevent unauthorized or fraudulent transactions. Please ensure that the document accurately reflects the nature and details of the transfer.'
              )}
            </p>
          </div>
        </ReactTooltip>
      </div>
      <div className='file-upload-pane-files' onClick={handleOpenDocumentsModal} data-test='upload-files'>
        <UploadIcon />
        <div>
          Upload Files <span className='asterisk'>*</span>
        </div>
      </div>
    </div>
  )
}
