import { Dispatch, SetStateAction, memo, useCallback, useEffect, useRef, useState } from 'react'

import './expenseTabs.scss'

interface ITab {
  title: string | React.ReactElement
  name: string
  isActive?: boolean
  handleClick?: () => void
}

interface ITabs {
  config: ITab[]
  setTab: Dispatch<SetStateAction<string>>
  currentTab: string
}

const Tab = memo<ITab>(({ title, handleClick, isActive }) => {
  return (
    <span className={`tab ${isActive ? 'active' : ''}`} onClick={handleClick}>
      <div>{title}</div>
    </span>
  )
})

export const ExpenseTabs = memo<ITabs>(({ config, setTab, currentTab }) => {
  const getActiveIndex = () => {
    const foundIndex = config.findIndex(tab => tab.name === currentTab)
    return foundIndex !== -1 ? foundIndex : 0
  }
  const [activeIndex, setActiveIndex] = useState(getActiveIndex())
  const carouselRef = useRef(null)

  const onMouseDown = useCallback(e => {
    e.preventDefault()

    const drag = mouseOverEvent => {
      document.body.style.cursor = 'pointer'
      carouselRef.current.scroll({
        left: carouselRef.current.scrollLeft - mouseOverEvent.movementX
      })

      carouselRef.current.scrollLeft - mouseOverEvent.movementX + carouselRef.current.clientWidth + 5 >
        carouselRef.current.scrollWidth
    }
    carouselRef.current.addEventListener('mousemove', drag)

    const cancelDrag = () => {
      carouselRef.current?.removeEventListener('mousemove', drag)
      document.body.style.cursor = 'default'
    }

    carouselRef.current.addEventListener('mouseup', cancelDrag, { once: true })
    carouselRef.current.addEventListener('mouseleave', cancelDrag, { once: true })
  }, [])

  useEffect(() => {
    carouselRef.current.addEventListener('mousedown', onMouseDown)

    return () => {
      carouselRef.current?.removeEventListener('mousedown', onMouseDown)
    }
  }, [])

  useEffect(() => {
    setActiveIndex(getActiveIndex())
  }, [currentTab])

  return (
    <div className='tabs-wrapper'>
      <div className='styled-tabs' ref={carouselRef}>
        {config.map((item, index) => {
          const handleClick = () => {
            setTab(item.name)
          }

          return (
            <Tab
              title={item.title}
              name={item.name}
              key={`${index}-${item.title}`}
              handleClick={handleClick}
              isActive={activeIndex === index}
            />
          )
        })}
      </div>
    </div>
  )
})
