import parse from 'html-react-parser'
import { useState } from 'react'

import { useSendInvoiceByEmailMutation } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'

import { CoreModalDialog, ConfirmationModal, CoreButton } from '@components'

import EmailIcon from '@images/icons/send-email-invoices.svg?react'
import Success from '@images/icons/success-check.svg?react'

import './sendInvoiceByEmail.scss'

export const SendInvoiceByEmail = ({ invoiceId, email }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [sendInvoiceEmail, { isSuccess, isLoading, error, reset }] = useSendInvoiceByEmailMutation()

  const handleClose = () => {
    setIsModalVisible(false)
    reset()
  }

  return (
    <>
      <CoreButton
        data-test='button-send-email'
        onClick={() => setIsModalVisible(true)}
        LeftIcon={<EmailIcon />}
        variation='elevated'
        text={tr('FRONTEND.INVOICES.SEND.BUTTON_SEND', 'Send E-mail')}
        size='large'
      />

      <CoreModalDialog
        header={!isSuccess ? tr('FRONTEND.INVOICES.SEND.CONFIRM', 'Send Invoice By E-mail') : null}
        options={{ size: 'medium', hideCloseIcon: true }}
        onClose={handleClose}
        content={
          <ConfirmationModal
            question={
              isSuccess ? (
                <div className='success-invoice'>
                  <p className='title'>{tr('FRONTEND.INVOICES.SEND.SUCCESS_MAIN_TITLE', 'Email successfully sent!')}</p>
                  <span>
                    {tr('FRONTEND.INVOICES.SEND.SUCCESS_TITLE', 'Invoice sent successfully')}.{' '}
                    {parse(tr('FRONTEND.INVOICES.SEND.SUCCESS_SUBTITLE', 'Go to invoices to view it.'))}
                  </span>
                </div>
              ) : (
                <div className='success-invoice'>
                  <span>{tr('FRONTEND.INVOICES.SEND.MESSAGE', 'Please confirm sending this invoice to')}:</span>
                  <br />
                  {email}
                </div>
              )
            }
            icon={isSuccess ? <Success style={{ width: '42px', height: '42px' }} /> : null}
            onConfirm={() => (isSuccess ? handleClose() : sendInvoiceEmail({ invoiceId }))}
            confirmCaption={isSuccess ? tr('FRONTEND.BUTTONS.OK', 'Ok') : tr('FRONTEND.BUTTONS.CONFIRM', 'Confirm')}
            cancelCaption={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            onCancel={handleClose}
            hasCancel={isSuccess ? false : true}
            loading={isLoading}
            error={error}
          />
        }
        visible={isModalVisible}
      />
    </>
  )
}
