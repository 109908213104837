import { useLocation } from 'react-router-dom'
import { useContext } from 'react'

import { useGetMerchantQuery, useGetReportQuery } from 'mmfintech-backend-api'
import { ReportStateEnum } from 'mmfintech-commons-types'
import { GlobalContext, tr } from 'mmfintech-commons'

import { AddExpenseModal } from '@views/reports/expenses/AddExpenseModal/AddExpenseModal'
import { CoreButton } from '@components'

import AddExpenseIcon from '@images/expenses/add-expense.svg?react'

const actionStatuses = [ReportStateEnum.NEW, ReportStateEnum.EDIT]

type TAddEditExpenseButtonProps = {
  reload?: () => void
  reportId?: number
}

export const AddEditExpenseButton = ({ reload, reportId }: TAddEditExpenseButtonProps) => {
  const { modalHide, modalShow } = useContext(GlobalContext)
  const { data: merchant } = useGetMerchantQuery(null)
  const { merchantId: userId } = merchant || {}

  const { pathname } = useLocation()

  const { data, isSuccess } = useGetReportQuery({ id: Number(reportId) })
  const { state, merchantId: reportUserId } = isSuccess && data
  const handleCreateExpenseModalOpen = () => {
    modalShow({
      header: tr('FRONTEND.EXPENSES.EXPENSE_MODAL.CREATE_NEW_EXPENSE', 'Create New Expense'),
      options: { size: 'large', closeOnEscape: false, closeOnClickOutside: false },
      content: (
        <AddExpenseModal
          onClose={() => modalHide()}
          reportId={reportId ? String(reportId) : undefined}
          reload={reload}
        />
      )
    })
  }

  if (reportId && userId !== reportUserId) return null

  if (!(pathname === '/expense-management/reports') && !actionStatuses.includes(state)) {
    return null
  }

  return (
    <>
      <CoreButton
        text={tr('FRONTEND.EXPENSE_MANAGEMENT.ADD_EXPENSE', 'Add Expense')}
        LeftIcon={<AddExpenseIcon />}
        data-test='button-add-expense-modal'
        onClick={handleCreateExpenseModalOpen}
        size='large'
      />
    </>
  )
}
