import { CoreButton, CoreSelect } from '@components'
import { GlobalContext, isValidArray, tr, useFormValues } from 'mmfintech-commons'
import { useContext, useEffect, useMemo } from 'react'
import { api, useMerchantAccounts, useRedeemVoucherByIdMutation, useSelectedAccount } from 'mmfintech-backend-api'
import toast from 'react-hot-toast'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import './RedeemModal.scss'
import { useDispatch } from 'react-redux'
import { Currency } from 'mmfintech-commons-types'

interface RedeemModalProps {
  voucherId: string | number
  currency: string
  handleReloadVouchers: () => void
}

export const RedeemModal = ({ voucherId, currency, handleReloadVouchers }: RedeemModalProps) => {
  const { modalHide } = useContext(GlobalContext)
  const dispatch = useDispatch()
  const accounts = useRedeemVoucherEligibleAccounts(currency)
  const { selectedAccount, setSelectedAccount } = useSelectedAccount()

  const [redeemVoucher, { error, isLoading, reset }] = useRedeemVoucherByIdMutation()

  const formValues = useFormValues({
    destinationAccountId: { required: true },
    uuid: { required: true, value: voucherId }
  })

  const handleRedeemVoucher = async () => {
    if (formValues.areValid()) {
      try {
        await redeemVoucher(formValues.prepare()).unwrap()
        toast.success('You have successfully redeemed your voucher.')

        reset()
        dispatch(api.util.invalidateTags(['Vouchers']))
        handleReloadVouchers()
        modalHide()
      } catch {
        toast.error('Unable to redeem voucher. Please check voucher details or contact support for assistance.')
      }
    }
  }

  const filteredAccounts = useMemo(() => {
    return accounts.filter(account => account.currencyCode === currency)
  }, [accounts])

  useEffect(() => {
    if (isValidArray(filteredAccounts)) {
      setSelectedAccount(filteredAccounts[0])
    }
  }, [filteredAccounts])

  useEffect(() => {
    formValues.setValue('destinationAccountId', selectedAccount?.id)
  }, [selectedAccount])

  return (
    <div className='redeem-voucher-modal-wrapper'>
      <div className='redeem-voucher-modal-content'>
        <span className='redeem-voucher-title'>{tr('FRONTEND.VOUCHER.REDEEM_VOUCHER_TITLE', 'To account')}</span>
        <CoreSelect
          type='account'
          options={filteredAccounts}
          label={tr('FRONTEND.TRANSACTIONS.ACCOUNT_LABEL', 'Account')}
          value={selectedAccount}
          onChange={(_, v) => {
            setSelectedAccount(v)
          }}
        />
      </div>

      <ErrorDisplay error={error} />
      <CoreButton disabled={isLoading} text='Redeem' fullWidth onClick={handleRedeemVoucher} size='large' />
    </div>
  )
}

const useRedeemVoucherEligibleAccounts = (voucherCurrencyCode?: string) => {
  const matchCurrency = voucherCurrencyCode ? voucherCurrencyCode : ''

  const { activeAccounts: accounts } = useMerchantAccounts({
    validCurrency: (currency: Currency) =>
      !!currency.supportedForCashVoucher && (voucherCurrencyCode ? matchCurrency.includes(currency.currencyCode) : true)
  })

  return accounts
}
