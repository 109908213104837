import cn from 'classnames'

import './flagIcon.scss'
import { configuration } from 'mmfintech-backend-api'
import { getCountryImageUrl } from 'mmfintech-commons'

interface FlagIconProps {
  countryCode?: string
  width?: number | string
  height?: number | string
  circle?: boolean
  className?: string
}

const FlagIcon = ({ countryCode, width = 'auto', height = '2rem', circle = false, className = '' }: FlagIconProps) => {
  return (
    <span
      data-test={`flag-icon-${countryCode}`}
      style={{ width, height }}
      className={cn('flag-icon-wrapper', className, { circle })}>
      <img src={`${configuration.getBaseUrl()}${getCountryImageUrl(countryCode, 'svg')}`} alt='' />
    </span>
  )
}

export default FlagIcon
