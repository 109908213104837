import { useHistory } from 'react-router-dom'

import { Counterparty } from 'mmfintech-commons-types'
import { isValidArray, tr } from 'mmfintech-commons'
import { DataTable } from 'mmfintech-portal-commons'
import { paths } from 'mmfintech-backend-api'

import { ReportStatusBadge } from '@views/reports/components'
import { breakpoints } from '@constants'
import { useMatchMedia } from '@hooks'

import './counterpartyTable.scss'

const { Table, Row, HeadCell, Head, Body, Cell } = DataTable

type CounterpartyTableProps = {
  data: Counterparty[]
}

const CounterpartyTable: React.FC<CounterpartyTableProps> = ({ data }) => {
  const history = useHistory()
  const isMobile = useMatchMedia({ breakpoint: breakpoints.md, prefix: 'max' })

  const handleCounterpartyRowClick = (counterparty: Counterparty) => {
    history.push(paths.expenseManagement.singleCounterparty(counterparty.id))
  }

  return (
    <div className='counterparty-table-wrapper table-container'>
      <Table>
        <Head>
          <Row className='headRow'>
            <HeadCell colSpan={2}>{tr('FRONTEND.COUNTERPARTY.COLUMN_NAME', 'Name')}</HeadCell>
            <HeadCell>{tr('FRONTEND.COUNTERPARTY.COLUMN_TOTAL_EXPENSES', 'Total')}</HeadCell>
            <HeadCell className={isMobile ? 'text-right' : ''}>
              {tr('FRONTEND.COUNTERPARTY.COLUMN_AWAITING_PAYMENT', 'Awaiting Payment')}
            </HeadCell>
            {!isMobile && (
              <>
                <HeadCell>{tr('FRONTEND.COUNTERPARTY.COLUMN_PAID', 'Paid')}</HeadCell>
                <HeadCell className='text-right'>{tr('FRONTEND.COUNTERPARTY.COLUMN_STATUS', 'Status')}</HeadCell>
              </>
            )}
          </Row>
        </Head>

        <Body>
          {isValidArray(data)
            ? data.map((counterparty: Counterparty) => {
                const { expenseMetrics } = counterparty
                return (
                  <Row
                    className='table-row'
                    key={counterparty.id}
                    onClick={() => handleCounterpartyRowClick(counterparty)}>
                    <Cell colSpan={2}>
                      <div>{counterparty.name}</div>
                      {isMobile && <ReportStatusBadge status={counterparty.state} />}
                    </Cell>
                    <Cell>{expenseMetrics?.totalExpensesCount}</Cell>
                    <Cell className={isMobile ? 'text-right' : ''}>{expenseMetrics?.outstandingExpensesCount}</Cell>
                    {!isMobile && (
                      <>
                        <Cell>{expenseMetrics?.paidExpensesCount}</Cell>
                        <Cell className='text-right'>
                          <ReportStatusBadge status={counterparty.state} />
                        </Cell>
                      </>
                    )}
                  </Row>
                )
              })
            : null}
        </Body>
      </Table>
    </div>
  )
}

export default CounterpartyTable
