import { useHistory } from 'react-router-dom'

import parse from 'html-react-parser'

import { paths, selectCurrentUserRole, useAppSelector } from 'mmfintech-backend-api'
import { CustomerRoleEnum } from 'mmfintech-commons-types'
import { tr } from 'mmfintech-commons'

import { CoreButton, CoreModalDialogFooter } from '@components'

import DepositIcon from '@images/icons/cross-icon.svg?react'
import ExchangeIcon from '@images/icons/exchange-icon.svg?react'

import './noSuitableAccountModal.scss'

export const NoSuitableAccountModal = ({ onClose }) => {
  const history = useHistory()
  const customerRole = useAppSelector(selectCurrentUserRole)

  const hasDepositAccess = customerRole !== CustomerRoleEnum.STAFF
  const handleDepositClick = () => {
    history.push(paths.banking.deposit())
    onClose()
  }

  const handleExchangeClick = () => {
    history.push(paths.banking.exchange())
    onClose()
  }

  return (
    <>
      <div className='modal-message-wrapper' data-test='no-suitable-account-warning'>
        <div className='message-container'>
          {hasDepositAccess
            ? parse(
                tr(
                  'FRONTEND.WITHDRAW.NO_ACCOUNT_MESSAGE',
                  'You have no suitable account to initiate the payment. <b>Deposit</b> or <b>Exchange</b> respective funds to be able to send them.'
                )
              )
            : tr(
                'FRONTEND.WITHDRAW.NO_ACCOUNT_MESSAGE.STAFF',
                'You have no suitable account to initiate the payment. Please ask Administrator or the Owner to Deposit or Exchange funds to be able to send them.'
              )}
        </div>
      </div>
      <CoreModalDialogFooter>
        <div className='no-suitable-accounts-actions'>
          {hasDepositAccess ? (
            <>
              <CoreButton
                size='large'
                data-test='button-deposit'
                onClick={handleDepositClick}
                LeftIcon={<DepositIcon />}
                text={tr('FRONTEND.DASHBOARD.BUTTONS.DEPOSIT', 'Deposit')}
                fullWidth
              />

              <CoreButton
                size='large'
                className='styled-button'
                data-test='exchange-button'
                onClick={handleExchangeClick}
                LeftIcon={<ExchangeIcon />}
                text={tr('FRONTEND.DASHBOARD.BUTTONS.EXCHANGE', 'Exchange')}
                fullWidth
              />
            </>
          ) : (
            <CoreButton
              size='large'
              data-test='exchange-button'
              onClick={onClose}
              text={tr('FRONTEND.BUTTONS.OK', 'OK')}
              fullWidth
            />
          )}
        </div>
      </CoreModalDialogFooter>
    </>
  )
}
