import { ReactNode } from 'react'

import { tr } from 'mmfintech-commons'

import { CoreButton } from '@components'

import SuccessIcon from '@images/icons/success-check.svg?react'
import ErrorIcon from '@images/icons/error-icon.svg?react'

import './successPane.scss'

interface SuccessPaneProps {
  message?: ReactNode
  onClick?: () => void
  confirmMessage?: string
  response?: any
}

export const SuccessPane = ({
  onClick,
  response,
  message,
  confirmMessage = tr('FRONTEND.BUTTONS.OK', 'Ok')
}: SuccessPaneProps) => {
  const { status, message: responseMessage } = response || {}
  const isFailure = ['CANCELLED', 'FAILED'].includes(status)
  const failureMessage = responseMessage || tr('FRONTEND.WITHDRAW.BANK_TRANSFER.FAILURE', 'Transfer failed')

  return (
    <div
      className='success-pane-wrapper'
      data-test={isFailure ? 'transaction-status-failed' : 'transaction-status-success'}>
      <div className='success-pane-content'>
        <div className='icon'>{isFailure ? <ErrorIcon /> : <SuccessIcon />}</div>
        <div className='success-pane-message'>
          {isFailure ? failureMessage : message || tr('FRONTEND.WITHDRAW.BANK_TRANSFER.SUCCESS', 'Successfully sent')}
        </div>
      </div>
      <div className='footer'>
        <CoreButton size='large' text={confirmMessage} onClick={onClick} data-test='button-ok' fullWidth />
      </div>
    </div>
  )
}
