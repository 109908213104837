import { useContext } from 'react'

import { GlobalContext } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { tr } from 'mmfintech-commons'
import { useAccountCreateQuery } from 'mmfintech-backend-api'
import { CoreButton, CoreInput, CoreSelect } from '@components'
import './AccountCreateModal.scss'

interface AccountCreateModalProps {
  filteredCurrencies?: any[]
  onSuccess: (currency?: string, accountId?: number) => void
}

export const AccountCreateModal = ({ filteredCurrencies, onSuccess }: AccountCreateModalProps) => {
  const { modalHide } = useContext(GlobalContext)

  const { currencies, formValues, createAccountError, createAccountFetching, handleSubmit } = useAccountCreateQuery({
    filteredCurrencies,
    onSuccess
  })

  return (
    <form className='create-account-iban-issuing-modal' noValidate data-test='create-account-iban-issuing-modal'>
      <div className='create-account-iban-issuing-wrapper'>
        <CoreSelect
          type='default'
          label={tr('FRONTEND.ACCOUNTS.CREATE.CURRENCY_LABEL', 'Currency')}
          options={currencies}
          {...formValues.registerShort('currency')}
          onChange={(_, value) => formValues.setValue('currency', value)}
        />
        <CoreInput
          label={tr('FRONTEND.ACCOUNTS.CREATE.NAME_LABEL', 'Account name')}
          {...formValues.registerShort('name')}
          clearable
          onChange={(_, value) => formValues.setValue('name', value)}
        />
      </div>

      <ErrorDisplay error={createAccountError} />

      <div className='create-account-buttons-iban-issuing-wrapper'>
        <CoreButton
          variation='primary'
          size='large'
          fullWidth
          text={tr('FRONTEND.ACCOUNTS.CREATE.SUBMIT_BUTTON', 'Add')}
          isLoading={createAccountFetching}
          data-test='button-submit'
          onClick={handleSubmit}
        />

        <CoreButton
          variation='tertiary'
          size='large'
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          disabled={createAccountFetching}
          data-test='button-cancel'
          onClick={modalHide}
        />
      </div>
    </form>
  )
}
