import { useHistory } from 'react-router-dom'
import { FC, useEffect } from 'react'
import toast from 'react-hot-toast'

import {
  isOwnerOrAdministrator,
  paths,
  selectCurrentUserRole,
  useAppSelector,
  useProcessReportMutation
} from 'mmfintech-backend-api'
import { ReportActionEnum } from 'mmfintech-commons-types'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { tr } from 'mmfintech-commons'

import { CoreButton, CoreModalDialogFooter } from '@components'
import { ReportActionModalProps } from '../@types'

import ApproveIcon from '@images/expenses/approve-report.svg?react'

import './approveModal.scss'

export const ApproveModal: FC<ReportActionModalProps> = ({ handleSubmit, reportName, reportId, onClose }) => {
  const [_, { error, isLoading, reset }] = useProcessReportMutation()
  const customerRole = useAppSelector(selectCurrentUserRole)

  const history = useHistory()

  const onConfirm = async () => {
    try {
      await handleSubmit({ action: ReportActionEnum.APPROVE })

      toast.success(tr('FRONTEND.REPORTS.ACTIONS.APPROVE_SUCCESS', 'The report has been approved successfully'), {
        position: 'top-center',
        style: { marginTop: '-50px' }
      })

      if (isOwnerOrAdministrator(customerRole)) {
        history.push(paths.expenseManagement.payments(reportId))
      }
    } catch (error) {}
  }

  useEffect(() => {
    return () => {
      reset()
    }
  }, [])

  return (
    <div className='approve-modal-wrapper'>
      <div className='approve-modal-inner'>
        <div className='icon'>
          <ApproveIcon />
        </div>
        <div className='confirmation'>
          {tr('FRONTEND.REPORTS.ACTIONS.APPROVE_REPORT_DESCRIPTION', 'Are you sure you want to approve report')}:{' '}
          <p>{reportName}</p>
        </div>
      </div>

      <ErrorDisplay error={error} />

      <CoreModalDialogFooter>
        <div className='buttons-container'>
          <CoreButton
            text={tr('FRONTEND.BUTTONS.CONFIRM', 'Confirm')}
            onClick={onConfirm}
            data-test='approve-report-button'
            isLoading={isLoading}
            size='large'
            fullWidth
          />

          <CoreButton
            variation='tertiary'
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            onClick={onClose}
            data-test='cancel-reject-report-button'
            isLoading={isLoading}
            size='large'
          />
        </div>
      </CoreModalDialogFooter>
    </div>
  )
}
