import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import toast from 'react-hot-toast'

import { ErrorDisplay } from 'mmfintech-portal-commons'
import {
  CoreApplicationFeeAnnounce,
  CoreButton,
  CoreInput,
  CoreOnboardingAnnounce,
  CorePaymentInput,
  CoreRadioButton,
  CoreSelect,
  NoReferenceModal,
  SuccessPane
} from '@components'
import { GlobalContext, tr, OtpContext, isValidArray, isValidObject, isValidString } from 'mmfintech-commons'
import {
  actions,
  useBankTransferQry,
  useCountries,
  responseDoesNotContainsChallenge,
  extractOptionStatusFromAccount,
  useGetMerchantQuery,
  useTransactionFilesManagementQry,
  useProviderSpecificFieldsQry
} from 'mmfintech-backend-api'
import {
  CountrySupportedPurposeEnum,
  MerchantAccountTypeEnum,
  OnboardingStatusEnum,
  PaymentOptionStatusEnum,
  WithdrawalSubFlowEnum
} from 'mmfintech-commons-types'

import { SpecificField } from './SpecificFiels'
import { FileUploadPane } from './FileUploadPane'
import { BankAccountRecipientEdit } from '../../new_recipients/elements/BankAccountRecipientEdit'
import { UploadedFilesPane } from './UploadedFilesPane'
import { BankTransferPreview } from './BankTransferPreview'
import { HeaderContext } from '../../../context/HeaderContext'

import NewRecipientIcon from '@images/icons/recipient-new.svg?react'
import RecipientIcon from '@images/icons/recipient-select.svg?react'
import ReferenceIcon from '@images/icons/note.svg?react'

enum BankTransferStep {
  INITIAL = 'INITIAL',
  PROVIDER_SPECIFIC_FIELDS = 'PROVIDER_SPECIFIC_FIELDS',
  PREVIEW = 'PREVIEW',
  RESULT = 'RESULT'
}

export const BankTransfer = () => {
  const { modalHide, modalShow } = useContext(GlobalContext)
  const { setOtpOnSuccess } = useContext(OtpContext)
  const { data: merchant } = useGetMerchantQuery()
  const { uploadedFiles, uploadFileError, resetFiles, getDocumentIds } = useTransactionFilesManagementQry()

  const recipientId = new URLSearchParams(window.location.search).get('recipientId')
  const { setCurrentStep } = useContext(HeaderContext)

  const [step, setStep] = useState<BankTransferStep>(BankTransferStep.INITIAL)
  const [result, setResult] = useState(null)
  const [ownAccount, setOwnAccount] = useState<boolean>(true)

  const [triggered, setTriggered] = useState<boolean>(false)
  const [triggeredRecipientId, setTriggeredRecipientId] = useState<number>(0)

  const history = useHistory()

  const {
    isDataValid,
    formValues,
    handleRecipientChange,
    handlePreviewExtended,
    handleInitiate,
    reloadRecipients,
    selectedAccount,
    setSelectedAccount,
    selectedRecipient,
    filteredRecipients,
    shouldShowWarning,
    accounts,
    accountsError,
    accountsFetching,
    recipientsError,
    recipientsFetching,
    previewFetching,
    previewError,
    prepareData,
    resetMutations
  } = useBankTransferQry({
    flowType: WithdrawalSubFlowEnum.BANKWIRE,
    editRecipient: (id?: number) => editRecipient(id),
    onPreviewSuccess: () => {
      setStep(BankTransferStep.PREVIEW)
      setCurrentStep(2)
    },
    onInitiateSuccess: (response: any) => handleInitiateSuccess(response),
    selectAddNew: false,
    recipientId: parseInt(recipientId)
  })

  const {
    fetchProviderSpecificFields,
    providerSpecificFields,
    providerSpecificFieldsError,
    providerSpecificFieldsFetching,
    specificFieldsValues,
    prepareProviderSpecificData
  } = useProviderSpecificFieldsQry()

  const { countryOptions } = useCountries(CountrySupportedPurposeEnum.WITHDRAWAL)

  const { accountType, onboardingStatus } = merchant || {}
  const selectedRecipientBankInfo = selectedRecipient?.iban || selectedRecipient?.accountNumber

  const transferSuccess = (data: any) => {
    setResult(data)
    setStep(BankTransferStep.RESULT)
    setCurrentStep(3)
  }

  const handleInitiateSuccess = (data: any): void => {
    if (responseDoesNotContainsChallenge(data)) {
      transferSuccess(data)
    }
  }

  const handleRecipientEditSuccess = (data: any) => {
    const { paymentInstrumentId } = data || {}
    reloadRecipients(paymentInstrumentId)
    toast.remove()
    toast.success(tr('FRONTEND.RECIPIENT.CREATE_SUCCESSFUL', 'Recipient successfully created'), {
      duration: 3_000
    })
    modalHide()
  }

  const editRecipient = (recipientId?: number) => {
    const { currencyCode, supportedCountries, id } = selectedAccount || {}
    const options =
      isValidArray(supportedCountries) && !supportedCountries.includes('ALL')
        ? countryOptions.filter(node => supportedCountries.includes(node.value))
        : countryOptions

    if (currencyCode) {
      modalShow({
        header:
          recipientId > 0 ? (
            <div className='recipient-edit-header'>{tr('FRONTEND.RECIPIENTS.EDIT.TITLE', 'Recipient Details')}</div>
          ) : (
            <div className='title single'>{tr('FRONTEND.RECIPIENTS.CREATE.TITLE', 'Create recipient')}</div>
          ),
        content: (
          <BankAccountRecipientEdit
            accountId={id}
            recipientId={recipientId}
            currencyCode={currencyCode}
            countryOptions={options}
            onSuccess={handleRecipientEditSuccess}
          />
        )
      })
    }
  }

  const handleContinue = () => {
    const continueToPreview = () => {
      if (isDataValid()) {
        void fetchProviderSpecificFields(prepareData(), (response: any) => {
          if (isValidArray(response)) {
            setStep(BankTransferStep.PROVIDER_SPECIFIC_FIELDS)
            setCurrentStep(1)
          } else {
            specificFieldsValues.reset()
            internalPreview()
          }
        })
      }
    }

    if (formValues.getValue('reference') !== '') {
      continueToPreview()
    } else {
      modalShow({
        options: { size: 'medium' },
        header: tr('FRONTEND.WITHDRAW.BANK_TRANSFER.NO_REFERENCE.TITLE', 'You Have Not Entered a Reference'),
        content: <NoReferenceModal onClose={modalHide} handleContinue={continueToPreview} />
      })
    }
  }

  const handleSubmitSpecificFields = () => {
    if (specificFieldsValues.areValid()) {
      internalPreview()
    }
  }

  const internalPreview = () => {
    handlePreviewExtended(getDocumentIds(), prepareProviderSpecificData(), ownAccount)
  }

  const handleAdvancedInitiate = () => {
    setOtpOnSuccess(() => transferSuccess)
    handleInitiate(getDocumentIds())
  }

  const hasValidRecipientPaymentOption = () => {
    const { paymentOption } = selectedRecipient || {}
    const { status } = paymentOption || {}
    return status === PaymentOptionStatusEnum.AVAILABLE
  }

  const hasValidPaymentOption = () =>
    hasValidRecipientPaymentOption() ||
    extractOptionStatusFromAccount(selectedAccount) === PaymentOptionStatusEnum.AVAILABLE

  useEffect(() => {
    return () => {
      setStep(BankTransferStep.INITIAL)
      setCurrentStep(1)
      setResult(null)
      resetMutations()
      resetFiles()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (accountType === MerchantAccountTypeEnum.COMPANY) {
      setOwnAccount(
        selectedRecipient && selectedRecipient.hasOwnProperty('ownAccount') ? selectedRecipient.ownAccount : true
      )
    }
    //eslint-disable-next-line
  }, [selectedRecipient])

  useEffect(() => {
    if (
      triggered &&
      !recipientsFetching &&
      triggeredRecipientId === Number(formValues.getValue('destinationPaymentInstrumentId'))
    ) {
      setTriggered(false)
      setTriggeredRecipientId(0)
      handleContinue()
    }
  }, [triggered, recipientsFetching, formValues.getValue('destinationPaymentInstrumentId')])

  return (
    <>
      {step === BankTransferStep.INITIAL && (
        <>
          <div className='send-money-container' data-test='bank-transfer-container'>
            <div className='from-container'>
              <div className='label'>
                <span>{tr('FRONTEND.WITHDRAW.BANK_TRANSFER.FROM_LABEL', 'From')}</span>
              </div>
              <CorePaymentInput
                selectedOption={selectedAccount}
                setSelectedAccount={setSelectedAccount}
                dataTest={'deposit-payment-input-test'}
                options={accounts}
                enableMaxAmountButton
                paymentOption={selectedRecipient?.paymentOption}
                onChange={(value: any) => formValues.setValue('amount', value)}
                value={formValues.getValue('amount')}
                error={formValues.getError('amount')}
                isLoading={accountsFetching}
              />
            </div>

            <div>
              <div className='to-container'>
                <div className='container-sb'>
                  <span className='label'>{tr('FRONTEND.WITHDRAW.BANK_TRANSFER.TO_LABEL', 'To')}</span>

                  {!recipientId && (
                    <CoreButton
                      variation='tertiary'
                      LeftIcon={<NewRecipientIcon />}
                      className='send-money-add-recipient'
                      size='normal'
                      text={tr('FRONTEND.RECIPIENTS.LIST.BUTTON_ADD', 'New recipient')}
                      isLoading={previewFetching}
                      onClick={() => editRecipient()}
                      data-test='button-add-recipient'
                    />
                  )}
                </div>
                <CoreSelect
                  type='default'
                  data-test='destination-payment-instrument-id'
                  label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.RECIPIENT', 'Recipient')}
                  options={filteredRecipients}
                  onChange={handleRecipientChange}
                  loading={recipientsFetching}
                  disabled={recipientsFetching}
                  value={
                    selectedRecipient
                      ? { value: selectedRecipient?.paymentInstrumentId, label: selectedRecipient?.name }
                      : null
                  }
                  LeftIcon={<RecipientIcon />}
                />
              </div>
              {isValidObject(selectedRecipient) && (
                <div className='recipient-info-container'>
                  {isValidString(selectedRecipient.description) && (
                    <div className='recipient-description-wrapper'>
                      <div className='description-label'>Recipient Description</div>
                      <div className='description'>{selectedRecipient.description}</div>
                    </div>
                  )}
                  {isValidString(selectedRecipientBankInfo) && (
                    <div className='recipient-description-wrapper'>
                      <div className='description-label'>Recipient bank details</div>
                      <div className='description'>{selectedRecipientBankInfo}</div>
                    </div>
                  )}
                </div>
              )}

              {selectedRecipient && accountType === MerchantAccountTypeEnum.COMPANY && (
                <>
                  <div className='radio-group-wrapper'>
                    <div className='radio-group-title'>
                      {tr(
                        'FRONTEND.WITHDRAW.BANK_TRANSFER.SUPPLEMENTAL_DOCUMENTS.RADIO.TITLE',
                        'Transfer to own account?'
                      )}
                    </div>

                    <CoreRadioButton
                      key='isOwnAccount'
                      radioGroupe={'is-own-account'}
                      buttons={[
                        {
                          value: true,
                          label: tr('FRONTEND.QUESTIONNAIRE.LABEL_YES', 'Yes'),
                          dataTest: 'test'
                        },
                        {
                          value: false,
                          label: tr('FRONTEND.QUESTIONNAIRE.LABEL_NO', 'No'),
                          dataTest: 'test'
                        }
                      ]}
                      type='circle'
                      checked={ownAccount}
                      onClick={(value: any) => setOwnAccount(value)}
                      flexDirection='row'
                      gap={'2.4rem'}
                    />
                  </div>

                  {!ownAccount ? (
                    <>
                      <FileUploadPane />
                      <UploadedFilesPane />
                      <div className='send-money-disclaimer' data-test='disclaimer'>
                        {tr(
                          'FRONTEND.WITHDRAW.BANK_TRANSFER.DISCLAIMER',
                          'Failure to provide supporting document/s while initiating a third-party withdrawal may cause delays in processing your transaction.'
                        )}
                      </div>
                    </>
                  ) : null}
                </>
              )}

              <CoreInput
                type='text'
                name='reference'
                data-test='reference'
                maxLength={50}
                label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.REFERENCE', 'Reference')}
                {...formValues.registerInput('reference')}
                autoComplete='off'
                LeftIcon={<ReferenceIcon />}
              />

              <div className='mb-4' />

              {(shouldShowWarning() ||
                (accountType === MerchantAccountTypeEnum.PROSPECT && !hasValidPaymentOption())) && (
                <div className='warning-banner'>
                  <CoreOnboardingAnnounce />
                </div>
              )}

              {accountType === MerchantAccountTypeEnum.PROSPECT &&
                onboardingStatus === OnboardingStatusEnum.APPROVED && (
                  <div className='warning-banner'>
                    <CoreApplicationFeeAnnounce />
                  </div>
                )}

              <ErrorDisplay
                error={[accountsError, recipientsError, previewError, uploadFileError, providerSpecificFieldsError]}
              />

              <CoreButton
                type='button'
                size='large'
                text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
                disabled={
                  accountsFetching ||
                  recipientsFetching ||
                  !hasValidRecipientPaymentOption() ||
                  formValues.getFloat('amount') <= 0 ||
                  (accountType === MerchantAccountTypeEnum.COMPANY && !ownAccount && !isValidArray(uploadedFiles))
                }
                isLoading={previewFetching || providerSpecificFieldsFetching}
                onClick={handleContinue}
                data-test='button-continue'
                fullWidth
              />
            </div>
          </div>
        </>
      )}

      {step === BankTransferStep.PROVIDER_SPECIFIC_FIELDS && (
        <>
          <div className='send-money-container' data-test='bank-transfer-container'>
            <div className='label'>
              <span>
                {tr(
                  'FRONTEND.WITHDRAW.BANK_TRANSFER.PROVIDER_SPECIFIC_FIELDS',
                  'Additional information we need in order to make the payment'
                )}
                :
              </span>
            </div>

            {providerSpecificFields.map(setup => (
              <SpecificField
                key={setup.fieldName}
                setup={setup}
                {...specificFieldsValues.registerInput(setup.fieldName)}
              />
            ))}

            <ErrorDisplay error={previewError} />

            <div className='mb-4' />

            <CoreButton
              type='button'
              size='large'
              text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
              isLoading={previewFetching}
              onClick={handleSubmitSpecificFields}
              data-test='button-continue'
              fullWidth
            />
          </div>
        </>
      )}

      {step === BankTransferStep.PREVIEW && (
        <BankTransferPreview
          formValues={formValues}
          selectedAccount={selectedAccount}
          selectedRecipient={selectedRecipient}
          loading={recipientsFetching}
          onSubmit={handleAdvancedInitiate}
          onCancel={() => {
            setStep(BankTransferStep.INITIAL)
            setCurrentStep(1)
          }}
        />
      )}

      {step === BankTransferStep.RESULT && (
        <SuccessPane response={result} onClick={() => actions.routing.returnFromWithdraw(history)} />
      )}
    </>
  )
}
