import { useContext, useState } from 'react'
import toast from 'react-hot-toast'

import { useChangePasswordMutation } from 'mmfintech-backend-api'
import { OtpContext, checkFinalValue, tr, translateError } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import '../styled/changePasswordModal.scss'
import { CoreButton, CoreInput, CoreModalDialogFooter, PasswordTerms } from '@components'

function ChangePassword() {
  const [error, setError] = useState(null)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const { setOtpOnSuccess } = useContext(OtpContext)
  const [changePassword, { error: changePasswordError }] = useChangePasswordMutation()

  const handleSubmit = e => {
    setOtpOnSuccess(() => response => {
      if (!response?.challenge) {
        toast.success(tr('FRONTEND.SECURITY.PASSWORD.SUCCESS_MESSAGE', 'Password is saved'))
      }
    })
    if (e) e.preventDefault()
    const result = checkFinalValue(newPassword, {
      validation: 'password',
      required: true
    })
    if (!result.valid) {
      setError(translateError(result))
      return
    }

    if (oldPassword.trim() === newPassword.trim()) {
      setError(tr('VALIDATION.PASSWORD.DIFFER', 'New password should be different from the old one'))
      return
    }

    changePassword({ oldPassword: oldPassword.trim(), newPassword: newPassword.trim() })
  }

  return (
    <form
      className='profile-change-password-container'
      noValidate
      onSubmit={handleSubmit}
      data-test='change-password-modal'>
      <CoreInput
        data-test='change-password-input'
        type='password'
        name='oldPassword'
        label={tr('FRONTEND.SECURITY.PASSWORD.OLD_PASSWORD', 'Old Password')}
        value={oldPassword}
        // LeftIcon={<LockPasswordIcon />}
        onChange={(_name, value) => setOldPassword(value)}
        size='large'
      />
      <div>
        <CoreInput
          data-test='change-new-password-input'
          type='password'
          name='newPassword'
          label={tr('FRONTEND.SECURITY.PASSWORD.NEW_PASSWORD', 'New Password')}
          error={error}
          value={newPassword}
          // LeftIcon={<LockPasswordIcon />}
          onChange={(_name, value) => {
            setError(null)
            setNewPassword(value)
          }}
          size='large'
        />
        <PasswordTerms
          data-test='change-password-terms'
          password={newPassword}
          // className='password-terms'
        />
      </div>

      <ErrorDisplay error={changePasswordError} />
      <CoreModalDialogFooter>
        <CoreButton
          text={tr('FRONTEND.ACCOUNTS.DETAILS.BUTTON.SAVE', 'Save')}
          data-test='change-password-save-button'
          fullWidth
          size='large'
        />
      </CoreModalDialogFooter>
    </form>
  )
}

export default ChangePassword
