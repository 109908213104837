import { CoreButton } from '@components'
import { GlobalContext, tr } from 'mmfintech-commons'
import { useContext } from 'react'

import TrashIcon from '@images/icons/trash-modal.svg?react'

import '../styled/deleteAccountConfirm.scss'

export const DeleteAccountConfirmModal = ({ handleSubmit }) => {
  const { modalHide } = useContext(GlobalContext)

  return (
    <div className='delete-profile-wrapper'>
      <div className='delete-profile-icon'>
        <TrashIcon />
      </div>
      <div className='delete-profile-content'>
        {tr(
          'FRONTEND.SECURITY.DELETE_ACCOUNT.MESSAGE',
          'Are you sure? You will lose all your data. This cannot be undone!'
        )}
      </div>
      <div className='delete-profile-actions'>
        <CoreButton
          variation='primary'
          text={tr('FRONTEND.SECURITY.DELETE_ELEMENT.BUTTON', 'Delete')}
          size='large'
          fullWidth
          onClick={() => handleSubmit()}
        />
        <CoreButton
          variation='tertiary'
          text={tr('FRONTEND.SECURITY.CANCEL.BUTTON', 'Cancel')}
          size='large'
          onClick={modalHide}
        />
      </div>
    </div>
  )
}
