import { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import { useChangeEmailMutation, useGetProfileQuery } from 'mmfintech-backend-api'
import { OtpContext, checkFinalValue, tr, translateError } from 'mmfintech-commons'

import '../styled/changeEmail.scss'

import { CoreButton, CoreInput, CoreModalDialogFooter } from '@components'

import LetterIcon from '@images/icons/email.svg?react'

function ChangeEmail() {
  const { setOtpOnSuccess, setOtpOnError } = useContext(OtpContext)
  const { data: profile } = useGetProfileQuery(null)
  const [changeEmail, { error: changeEmailError }] = useChangeEmailMutation()

  const [email, setEmail] = useState<string>()
  const [error, setError] = useState(null)
  const { email: currentEmail } = profile || {}

  const handleSubmit = e => {
    if (e) {
      e.preventDefault()
    }

    setOtpOnSuccess(() => response => {
      if (!response?.challenge) {
        toast.success(tr('FRONTEND.SECURITY.EMAIL.SUCCESS_MESSAGE', 'Email is saved'))
      }
    })
    setOtpOnError(() => response => {
      toast.error(tr(response?.errorKey, response?.error))
    })
    const result = checkFinalValue(email, { validation: 'email', required: true })

    if (!result.valid) {
      setError(translateError(result))
      return
    }

    if (email !== profile?.email) {
      changeEmail({ email })
    } else {
      setError(tr('FRONTEND.SECURITY.EMAIL.SAME_MESSAGE', 'Entered email should be different than the existing one.'))
    }
  }

  useEffect(() => {
    if (changeEmailError && 'cause' in changeEmailError) {
      const { cause } = changeEmailError
      if (Array.isArray(cause)) {
        const find = cause.find(item => item.field === 'email')
        if (find) {
          setError(tr(find.cause))
        }
      }
    }
  }, [changeEmailError])

  useEffect(() => {
    if (profile) {
      setEmail(currentEmail)
    }
  }, [profile])

  return (
    <form className='profile-email-container' noValidate onSubmit={handleSubmit} data-test='change-email-modal'>
      <CoreInput
        type='text'
        name='email'
        label={tr('FRONTEND.SECURITY.EMAIL.EMAIL', 'E-mail Address')}
        error={error}
        value={email}
        onChange={(_name, value) => setEmail(value.toString())}
        LeftIcon={<LetterIcon />}
        className='profile-email-input-field'
        data-test='change-email-modal-input'
        size='large'
      />
      <CoreModalDialogFooter>
        <CoreButton
          text={tr('FRONTEND.ACCOUNTS.DETAILS.BUTTON.SAVE', 'Save')}
          fullWidth
          size='large'
          data-test='change-email-modal-save'
        />
      </CoreModalDialogFooter>
    </form>
  )
}

export default ChangeEmail
