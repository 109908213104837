import { CoreButton } from '@components'
import { GlobalContext, tr } from 'mmfintech-commons'
import { useContext } from 'react'

import ErrorIcon from '@images/icons/error-icon.svg?react'

export const DeleteAccountErrorModal = ({ error }) => {
  const { modalHide } = useContext(GlobalContext)

  return (
    <div className='delete-profile-wrapper'>
      <div className='delete-profile-error-icon'>
        <ErrorIcon />
      </div>
      <div className='delete-profile-content'>{tr(error.errorKey, error.error)}</div>
      <div className='delete-profile-actions'>
        <CoreButton
          variation='primary'
          text={tr('FRONTEND.SECURITY.OK.BUTTON', 'OK')}
          size='large'
          fullWidth
          onClick={modalHide}
        />
      </div>
    </div>
  )
}
