import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import toast from 'react-hot-toast'

import { GlobalContext, isValidArray, tr } from 'mmfintech-commons'
import { paths, useCreateExpenseRequestQry } from 'mmfintech-backend-api'

import { ExpenseRequestModalWrapper } from './CreateExpenseRequestModal.styled'
import { Button, Input, ErrorDisplay } from 'mmfintech-portal-commons'

export const CreateExpenseRequestModal = ({ formValues }: any) => {
  const { modalHide }: any = useContext(GlobalContext)

  const history = useHistory()

  const { description, setDescription, submitExpenseRequest, expenseRequestsError, expenseRequestsLoading } =
    useCreateExpenseRequestQry(formValues)

  const handleRequestSuccess = () => {
    modalHide()
    toast.success(tr('PORTAL.EXPENSE_REQUEST.SUCCESS_MESSAGE', 'Request successfully accepted'))
    history.push(paths.banking.expenses())
  }

  return (
    <ExpenseRequestModalWrapper>
      <h3>{tr('PORTAL.EXPENSE_REQUEST.TITLE', 'You have reached your monthly limit')}</h3>

      <p>
        {tr(
          'PORTAL.EXPENSE_REQUEST.WARNING',
          'You can submit an expense request and after approval is received the transaction will be automatically initiated.'
        )}
      </p>

      <Input
        type='textarea'
        className='description-input'
        label={tr('PORTAL.EXPENSE_REQUEST.DESCRIPTION', 'Description')}
        value={description}
        name='description'
        onChange={(_: string, value: string) => setDescription(value)}
        rows={5}
      />

      <ErrorDisplay
        error={
          expenseRequestsError
            ? isValidArray(expenseRequestsError?.cause)
              ? expenseRequestsError?.cause?.map((errCause: any) => errCause?.cause)
              : expenseRequestsError
            : null
        }
      />

      <Button
        color='primary'
        text={tr('PORTAL.EXPENSE_REQUEST.REQUEST_BUTTON', 'Request')}
        onClick={() => submitExpenseRequest(handleRequestSuccess)}
        loading={expenseRequestsLoading}
      />
      <Button color='secondary' text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')} onClick={modalHide} />
    </ExpenseRequestModalWrapper>
  )
}
