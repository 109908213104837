import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import toast from 'react-hot-toast'

import {
  MerchantAccountTypeEnum,
  OnboardingStatusEnum,
  PaymentOptionStatusEnum,
  WithdrawalSubFlowEnum
} from 'mmfintech-commons-types'
import { GlobalContext, OtpContext, isValidObject, isValidString, tr } from 'mmfintech-commons'
import {
  actions,
  useWalletTransferQry,
  responseDoesNotContainsChallenge,
  useGetMerchantQuery
} from 'mmfintech-backend-api'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import {
  CoreButton,
  CoreInput,
  CorePaymentInput,
  CoreSelect,
  SuccessPane,
  CoreOnboardingAnnounce,
  CoreApplicationFeeAnnounce
} from '@components'
import { HeaderContext } from '../../../../context/HeaderContext'
import { WalletTransferPreview } from '../WalletTransferPreview'
import { PerfectMoneyRecipientEdit } from '../../../new_recipients/elements/PerfectMoneyRecipientEdit'

import { useSearchParams } from '@hooks'

import NewRecipientIcon from '@images/icons/recipient-new.svg?react'
import RecipientIcon from '@images/icons/recipient-select.svg?react'
import ReferenceIcon from '@images/icons/note.svg?react'

export const PerfectMoneyTransfer = () => {
  const { modalHide, modalShow } = useContext(GlobalContext)
  const { setOtpOnSuccess } = useContext(OtpContext)
  const { data: merchant } = useGetMerchantQuery()
  const { setCurrentStep, currentStep } = useContext(HeaderContext)

  const { recipientId } = useSearchParams()

  const [result, setResult] = useState(null)

  const history = useHistory()

  const {
    formValues,
    handleRecipientChange,
    handlePreview,
    handleInitiate,
    reloadRecipients,
    selectedAccount,
    selectedRecipient,
    setSelectedAccount,
    filteredRecipients,
    shouldShowWarning,
    accounts,
    accountsError,
    accountsFetching,
    recipientsError,
    recipientsFetching,
    previewFetching,
    previewError,
    resetMutations
  } = useWalletTransferQry({
    flowType: WithdrawalSubFlowEnum.PERFECT_MONEY,
    editRecipient: (id?: number) => handleEditRecipient(id),
    onPreviewSuccess: () => setCurrentStep(2),
    onInitiateSuccess: (response: any) => handleInitiateSuccess(response),
    selectAddNew: false,
    recipientId
  })

  const { onboardingStatus, accountType } = merchant || {}

  const handleEditRecipient = (recipient?: any) => {
    const { currencyCode } = selectedAccount || {}
    if (currencyCode) {
      modalShow({
        header: recipient?.paymentInstrumentId
          ? tr('FRONTEND.RECIPIENTS.PERFECT_MONEY.UPDATE_TITLE', 'Edit recipient')
          : tr('FRONTEND.RECIPIENTS.PERFECT_MONEY.CREATE_TITLE', 'Create recipient'),
        content: (
          <PerfectMoneyRecipientEdit
            recipientId={recipient?.paymentInstrumentId}
            currencyCode={currencyCode}
            onClose={modalHide}
            onSuccess={(response: any) => {
              const { paymentInstrumentId } = response || {}
              toast.remove()
              toast.success(tr('FRONTEND.RECIPIENT.CREATE_SUCCESSFUL', 'Recipient successfully created'), {
                duration: 3_000
              })
              reloadRecipients(paymentInstrumentId)
            }}
          />
        )
      })
    }
  }

  const transferSuccess = (data: any) => {
    setResult(data)
    setCurrentStep(3)
  }

  const handleInitiateSuccess = (data: any): void => {
    if (responseDoesNotContainsChallenge(data)) {
      transferSuccess(data)
    }
  }

  const handleAdvancedInitiate = () => {
    setOtpOnSuccess(() => transferSuccess)
    handleInitiate()
  }

  useEffect(() => {
    return () => {
      setCurrentStep(1)
      setResult(null)
      resetMutations()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {currentStep === 1 && (
        <div className='send-money-container' data-test='perfect-money-transfer-container'>
          <div className='from-container'>
            <div className='label'>
              <span>From</span>
            </div>
            <CorePaymentInput
              selectedOption={selectedAccount}
              setSelectedAccount={setSelectedAccount}
              dataTest={'deposit-payment-input-test'}
              options={accounts}
              enableMaxAmountButton
              paymentOption={selectedRecipient?.paymentOption}
              onChange={value => formValues.setValue('amount', value)}
              value={formValues.getValue('amount')}
              error={formValues.getError('amount')}
              isLoading={accountsFetching}
            />
          </div>

          <div className='to-container'>
            <div className='container-sb'>
              <span className='label'>{tr('FRONTEND.WITHDRAW.PERFECT_MONEY.TO', 'To')}</span>

              {!recipientId && onboardingStatus !== OnboardingStatusEnum.NOT_STARTED && (
                <CoreButton
                  variation='tertiary'
                  LeftIcon={<NewRecipientIcon />}
                  className='send-money-add-recipient'
                  size='normal'
                  text={tr('FRONTEND.RECIPIENTS.LIST.BUTTON_ADD', 'New recipient')}
                  isLoading={previewFetching}
                  onClick={handleEditRecipient}
                  data-test='button-add-recipient'
                />
              )}
            </div>
            <CoreSelect
              data-test='destination-payment-instrument-id'
              label={tr('FRONTEND.WITHDRAW.PERFECT_MONEY.RECIPIENT', 'Recipient')}
              options={filteredRecipients}
              onChange={handleRecipientChange}
              loading={recipientsFetching}
              disabled={recipientsFetching}
              {...formValues.registerShort('destinationPaymentInstrumentId')}
              value={selectedRecipient?.holderName}
              LeftIcon={<RecipientIcon />}
              preselectValue={recipientId}
            />
          </div>

          {isValidObject(selectedRecipient) && isValidString(selectedRecipient.description) && (
            <div className='recipient-info-container'>
              <div className='recipient-description-wrapper'>
                <div className='description-label'>
                  {tr('FRONTEND.WITHDRAW.PERFECT_MONEY.RECIPIENT_DESCRIPTION', 'Recipient description')}
                </div>
                <div className='description'>{selectedRecipient.description}</div>
              </div>
            </div>
          )}

          <CoreInput
            type='text'
            name='reference'
            data-test='reference'
            maxLength={50}
            label={tr('FRONTEND.WITHDRAW.PERFECT_MONEY.REFERENCE', 'Reference')}
            {...formValues.registerInput('reference')}
            autoComplete='off'
            LeftIcon={<ReferenceIcon />}
          />

          <div className='mb-4' />

          {shouldShowWarning() && (
            <div className='warning-banner'>
              <CoreOnboardingAnnounce />
            </div>
          )}

          {accountType === MerchantAccountTypeEnum.PROSPECT && onboardingStatus === OnboardingStatusEnum.APPROVED && (
            <div className='warning-banner'>
              <CoreApplicationFeeAnnounce />
            </div>
          )}

          <ErrorDisplay error={[accountsError, recipientsError, previewError]} />

          <CoreButton
            type='button'
            size='large'
            text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
            disabled={
              accountsFetching ||
              recipientsFetching ||
              selectedRecipient?.paymentOption?.status !== PaymentOptionStatusEnum.AVAILABLE ||
              formValues.getFloat('amount') <= 0
            }
            isLoading={previewFetching}
            onClick={handlePreview}
            data-test='button-continue'
            fullWidth
          />
        </div>
      )}

      {currentStep === 2 && (
        <WalletTransferPreview
          formValues={formValues}
          selectedAccount={selectedAccount}
          selectedRecipient={selectedRecipient}
          onSubmit={handleAdvancedInitiate}
          onCancel={() => setCurrentStep(1)}
        />
      )}

      {currentStep === 3 && (
        <SuccessPane response={result} onClick={() => actions.routing.returnFromWithdraw(history)} />
      )}
    </>
  )
}
