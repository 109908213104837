import { isValidArray } from 'mmfintech-commons'
import './CoreAmountTabs.scss'
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import cn from 'classnames'

interface CoreAmountTabsProps {
  amounts: number[]
  currency: string
  onChange: Dispatch<SetStateAction<number>>
  selected: number
}

export const CoreAmountTabs = ({ amounts, currency, onChange, selected }: CoreAmountTabsProps) => {
  const [selectable, setSelectable] = useState<boolean>(true)
  const carouselRef = useRef(null)

  const selectSavingElement = (indexOffset: number, atIndex?: number): void => {
    if (carouselRef && carouselRef.current) {
      const selectedIndex = atIndex != undefined ? atIndex : 0
      const amountNodeList = carouselRef.current.childNodes
      if (selectedIndex >= 0 && amounts[selectedIndex + indexOffset]) {
        onChange(amounts[selectedIndex + indexOffset])
        if (amountNodeList && amountNodeList[selectedIndex + indexOffset]) {
          amountNodeList[selectedIndex + indexOffset].scrollIntoView({
            behavior: 'smooth',
            inline: 'center',
            block: 'center'
          })
        }
      }
    }
  }

  const onMouseDown = useCallback(e => {
    e.preventDefault()

    const drag = mouseOverEvent => {
      document.body.style.cursor = 'pointer'
      setSelectable(false)
      carouselRef.current.scroll({
        left: carouselRef.current.scrollLeft - mouseOverEvent.movementX
      })
    }
    carouselRef.current.addEventListener('mousemove', drag)
    const cancelDrag = () => {
      carouselRef.current.removeEventListener('mousemove', drag)
      document.body.style.cursor = 'default'
      setTimeout(() => {
        setSelectable(true)
      }, 200)
    }
    carouselRef.current.addEventListener('mouseup', cancelDrag, { once: true })
    carouselRef.current.addEventListener('mouseleave', cancelDrag, { once: true })
  }, [])

  useEffect(() => {
    carouselRef.current.addEventListener('mousedown', onMouseDown)

    return () => {
      carouselRef.current?.removeEventListener('mousedown', onMouseDown)
    }
  }, [])

  useEffect(() => {
    if (isValidArray(amounts) && selected) {
      const accountIndex = amounts.indexOf(selected)

      selectSavingElement(0, accountIndex)
    }
  }, [amounts, selected])

  return (
    <div ref={carouselRef} className='preselected-amount-wrapper'>
      {isValidArray(amounts) &&
        amounts.map((amount, index) => {
          return (
            <div
              key={`${amount}-${index}`}
              onClick={() => {
                if (selectable) {
                  onChange(amount)
                }
              }}
              className={cn('preselected-amount', { selected: amount === selected })}>
              <span>{`${amount} ${currency}`}</span>
            </div>
          )
        })}
    </div>
  )
}
