import { tr } from 'mmfintech-commons'

import NoDetails from '@images/expenses/no-payment-details.svg?react'

import './noPaymentDetails.scss'

export const NoPaymentDetails = () => {
  return (
    <div className='no-payment-details'>
      <div className='icon'>
        <NoDetails />
      </div>
      <span className='title'>
        {tr('FRONTEND.SETTINGS_PAYMENT.LABELS.NO_DETAILS_TITLE', 'Your account doesn’t have payment details.')}
      </span>
      <span className='subtitle'>
        {tr('FRONTEND.SETTINGS_PAYMENT.LABELS.NO_DETAILS_SUBTITLE', 'Please update your information')}
      </span>
    </div>
  )
}
