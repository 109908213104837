import toast from 'react-hot-toast'
import QRCode from 'qrcode.react'

import { copyTextToClipboard, tr } from 'mmfintech-commons'

import ClipboardIcon from '@images/icons/clipboard.svg?react'

import './googleAuthenticator.scss'

const formatSecret = (secret: string) => secret.replace(/(.{4})/g, '$1 ').trim()

export const GoogleAuthenticator = ({
  totpSecretKeyUri = 'otpauth://totp/KINGDOM:yordan.titov+3@mmfintech.io?secret=KM2UKRCKJZJEGNSQIJEU4SSUINMUSRJVIVHDEMSMGJLE6RCZI5FA&issuer=KINGDOM&algorithm=SHA256&digits=6&period=30'
}) => {
  if (!totpSecretKeyUri) return null

  const secret = totpSecretKeyUri?.split('?secret=')[1]?.split('&')[0]

  return (
    <div className='google-authenticator-wrapper'>
      <div className='google-authenticator-container'>
        <div className='description'>
          <span>
            {tr(
              'FRONTEND.GOOGLE_AUTHENTICATOR.DESCRIPTION_1',
              '1. Download the Google Authenticator app on your mobile device.'
            )}
          </span>
          <span>
            {tr('FRONTEND.GOOGLE_AUTHENTICATOR.DESCRIPTION_2', '2. Scan the QR code below using Google Authenticator.')}
          </span>
        </div>
        {totpSecretKeyUri && (
          <div className='qr-code-wrapper'>
            <div className='qr-code'>
              <QRCode value={totpSecretKeyUri} renderAs='svg' size={150} />
              <>
                <span>{tr('FRONTEND.GOOGLE_AUTHENTICATOR.QR_CODE_LABEL', 'or enter this code manually')}</span>
                <div className='secret'>
                  <div
                    className='copy'
                    data-tip='Copied'
                    data-event='click'
                    data-event-off='mouseleave'
                    data-for='account-details-copy'>
                    <ClipboardIcon
                      onClick={() =>
                        copyTextToClipboard(
                          secret,
                          toast.success(
                            tr('FRONTEND.GOOGLE_AUTHENTICATOR.SECRET_CLIPBOARD_MESSAGE', 'Secret copied to clipboard'),
                            {
                              position: 'top-right'
                            }
                          )
                        )
                      }
                    />
                  </div>
                  <span>{formatSecret(secret)}</span>
                </div>
              </>
            </div>
          </div>
        )}
        <div className='code-input-container'>
          <span>
            {tr('FRONTEND.GOOGLE_AUTHENTICATOR.DESCRIPTION_3', '3. Enter the 6-digit code from the authenticator app')}
          </span>
        </div>
      </div>
    </div>
  )
}
