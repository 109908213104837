import { InstructionsWrapper } from './Instructions.styled'

import { tr } from 'mmfintech-commons'

export const Instructions = () => {
  return (
    <InstructionsWrapper>
      <div className='title'>{tr('FRONTEND.BATCH_PAYMENTS.INSTRUCTIONS.TITLE', 'Instructions')}</div>

      <ul>
        <li>
          {tr(
            'FRONTEND.BATCH_PAYMENTS.INSTRUCTIONS.LINE1',
            'For Crypto or SEPA/SWIFT batch payments download, fill in, and upload the respective CSV template'
          )}
        </li>
        <li>
          {tr(
            'FRONTEND.BATCH_PAYMENTS.INSTRUCTIONS.LINE2',
            'For all transactions the fields amount, currency and transfer type are mandatory'
          )}
        </li>
      </ul>

      <div className='subtitle'>
        {tr('FRONTEND.BATCH_PAYMENTS.INSTRUCTIONS.ADDITIONAL', 'Additional mandatory fields')}:
      </div>

      <ul>
        <li>{tr('FRONTEND.BATCH_PAYMENTS.INSTRUCTIONS.LINE3', 'For Crypto the field wallet address')}</li>
        <li>
          {tr(
            'FRONTEND.BATCH_PAYMENTS.INSTRUCTIONS.LINE4',
            'For SEPA/SWIFT transfers the fields IBAN or account number, bank code, and country.'
          )}
        </li>
        <li>
          {tr(
            'FRONTEND.BATCH_PAYMENTS.INSTRUCTIONS.LINE5',
            'If there are missing or wrong fields, they will be highlighted in the preview section.'
          )}
        </li>
      </ul>
    </InstructionsWrapper>
  )
}
