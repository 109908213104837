import styled from 'styled-components'

export const InstructionsWrapper = styled.div`
  background-color: black;
  border-radius: 4px;
  margin-bottom: 2rem;
  padding: 2rem;

  color: #ffffff;
  font-family: inherit;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  .title {
    font-size: 1.4rem;
    font-weight: 700;
  }

  .subtitle {
    color: #a0a0a0;
    font-family: inherit;
    font-size: 1.2rem;
    font-style: italic;
    font-weight: 400;
    line-height: 100%;
    margin: 0;
  }

  ul {
    padding-left: 2rem;
    li {
      margin-bottom: 0.5rem;
    }
  }
`
