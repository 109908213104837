import {
  isOwnerOrAdministrator,
  selectCurrentUserRole,
  useAppSelector,
  useGetMerchantQuery,
  useGetReportQuery
} from 'mmfintech-backend-api'
import { CustomerRoleEnum, ReportStateEnum } from 'mmfintech-commons-types'

import { ActionReportButtons } from '../../report/reportHeaders/ActionsReportButtons'
import { ActivitiesButton } from '../../report/reportHeaders/ActivitiesButton'
import { SubmitReportButton } from './SubmitReportButton/SubmitReportButton'

export const ReportHeaderButtons = ({ reportId }) => {
  const { data: report } = useGetReportQuery({ id: Number(reportId) })
  const { data: merchant } = useGetMerchantQuery(null)
  const { merchantId: userId } = merchant || {}
  const customerRole = useAppSelector(selectCurrentUserRole)

  switch (report?.state) {
    case ReportStateEnum.NEW:
    case ReportStateEnum.EDIT:
      return (
        <>
          {userId == report?.merchantId ? (
            <>
              <SubmitReportButton report={report} />
              {report?.state === ReportStateEnum.EDIT && <ActivitiesButton reportId={reportId} />}
            </>
          ) : null}
        </>
      )

    case ReportStateEnum.WAITING_APPROVAL:
      if (customerRole === CustomerRoleEnum.APPROVER) {
        return <ActionReportButtons reportId={reportId} />
      }
      return null
    case ReportStateEnum.WAITING_FINANCE_APPROVAL:
      if (isOwnerOrAdministrator(customerRole)) {
        return <ActionReportButtons reportId={reportId} />
      }
      return null
    default:
      return <ActivitiesButton reportId={reportId} />
  }
}
