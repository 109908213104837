import { useContext, useEffect, useState } from 'react'

import cn from 'classnames'
import { fixDateOnly, GlobalContext, monthBackDate, tr } from 'mmfintech-commons'
import { breakpoints } from '@constants'
import { useMatchMedia } from '@hooks'
import { paths, useGetMerchantQuery } from 'mmfintech-backend-api'

import { AccountsSection } from './AccountsSection'
import { CardOrderModal, CoreGraphSection, DashboardBanner, RecentTransactions } from '@components'

import './AdminDashboard.scss'

import CardsImage from '@images/cards-banner.png'
import InviteImage from '@images/invite-banner.png'
import SavingsImage from '@images/savings-banner.png'
// import { MerchantAccountTypeEnum } from 'mmfintech-commons-types'

export const AdminDashboard = () => {
  const [collapsedMenu, setCollapsedMenu] = useState(false)
  const [collapse, setCollapse] = useState(false)
  const { modalShow, modalHide } = useContext(GlobalContext)

  const isMobile = useMatchMedia({ breakpoint: breakpoints.MAX_MOBILE_WIDTH, prefix: 'max' })

  const { data: merchant } = useGetMerchantQuery()
  const { capabilities /*accountType*/ } = merchant || {}

  const handleOrderClick = () => {
    modalShow({
      header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
      content: <CardOrderModal onClose={modalHide} />,
      options: {
        closeOnClickOutside: false
      }
    })
  }

  // Fix responsiveness when collapse menu
  const fixResponsiveness = () => {
    collapsedMenu
      ? setTimeout(() => {
          setCollapse(true)
        }, 500)
      : setCollapse(false)
  }

  useEffect(() => {
    if (!isMobile) {
      fixResponsiveness()
    }
  }, [collapsedMenu])
  // Fix responsiveness when collapse menu

  useEffect(() => {
    const targetNode = document.querySelector('.core-menu-wrapper')

    if (targetNode.classList.contains('collapsed')) setCollapsedMenu(true)

    const callback = mutationList => {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes' && mutation.attributeName == 'class') {
          setCollapsedMenu(mutation.target.classList.contains('collapsed'))
        }
      }
    }

    const observer = new MutationObserver(callback)

    observer.observe(targetNode, { attributes: true, childList: false, subtree: false })

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <div className='admin-dashboard-wrapper' data-test='admin-dashboard'>
      <div className='admin-dashboard-left-section'>
        <CoreGraphSection />
        <RecentTransactions
          filter={{
            from: fixDateOnly(monthBackDate()),
            to: fixDateOnly(new Date()),
            page: 0,
            size: 5,
            sort: 'reqTime-desc,id-desc'
          }}
        />
      </div>

      <div className={cn('admin-dashboard-right-section', { collapsed: collapse && !isMobile })}>
        <AccountsSection />
        {/* {capabilities?.enableCardIssuing && accountType === MerchantAccountTypeEnum.INDIVIDUAL ? ( */}
        {capabilities?.enableCardIssuing ? (
          <DashboardBanner
            heading={tr('FRONTEND.DASHBOARD.BANNER.CARDS.HEADING', 'Cards')}
            image={CardsImage}
            title={tr('FRONTEND.DASHBOARD.BANNER.CARDS.TITLE', 'TKB Cards')}
            description={tr(
              'FRONTEND.DASHBOARD.BANNER.CARDS.CONTENT',
              'Use your The Kingdom Bank physical or virtual debit card for purchases in all merchants and terminals, which accepts Master cards.'
            )}
            redirect={() => handleOrderClick()}
            buttonLabel={tr('FRONTEND.DASHBOARD.BUTTON.ORDER_CARD', 'Order Card')}
          />
        ) : (
          <DashboardBanner
            heading={tr('FRONTEND.DASHBOARD.BANNER.SAVINGS.HEADING', 'Savings')}
            image={SavingsImage}
            title={tr('FRONTEND.DASHBOARD.BANNER.SAVINGS.TITLE', 'Explore our Saving Plans')}
            description={tr(
              'FRONTEND.DASHBOARD.BANNER.SAVINGS.CONTENT',
              'It’s the extra earnings you want from a savings account with the flexibility you need — all in one place.'
            )}
            redirect={paths.more.savings()}
            buttonLabel={tr('FRONTEND.DASHBOARD.BUTTON.VIEW_MORE', 'View More')}
          />
        )}
        <DashboardBanner
          heading={tr('FRONTEND.DASHBOARD.BANNER.INVITE.HEADING', 'Invite & Earn')}
          image={InviteImage}
          title={tr('FRONTEND.DASHBOARD.BANNER.INVITE.TITLE', 'Explore our Referral Programs')}
          description={tr(
            'FRONTEND.DASHBOARD.BANNER.INVITE.CONTENT',
            'Invite and earn a commission for each person who registers in The Kingdom Bank.'
          )}
          redirect={paths.referralProgram()}
          buttonLabel={tr('FRONTEND.DASHBOARD.BUTTON.VIEW_MORE', 'View More')}
        />
      </div>
    </div>
  )
}
