import { useContext } from 'react'

import { GlobalContext, tr } from 'mmfintech-commons'
import { IConfirmationModalProps } from './ConfirmationModal.types'

import { CoreButton, CoreModalDialogFooter } from '@components'

import './confirmationModal.scss'
import { ErrorDisplay } from 'mmfintech-portal-commons'

export const ConfirmationModal = ({
  question,
  onConfirm,
  className,
  confirmCaption,
  onCancel,
  cancelCaption,
  hasCancel = true,
  icon,
  error,
  loading
}: IConfirmationModalProps) => {
  const { modalHide } = useContext(GlobalContext)
  const handleConfirm = async () => {
    modalHide()
    onConfirm && (await onConfirm())
  }

  return (
    <div data-test='confirmation-dialog-modal' className={className}>
      <div className='confirm-modal-wrapper'>
        {icon && <div className='icon'>{icon}</div>}
        <div className='confirm-content'>{question}</div>

        {error && <ErrorDisplay className='error-wrapper' error={error} />}

        <CoreModalDialogFooter>
          <div className='confirm-buttons'>
            <CoreButton
              className='action'
              variation='primary'
              size='large'
              onClick={handleConfirm}
              data-test='button-confirm'
              text={confirmCaption || tr('FRONTEND.BUTTONS.CONFIRM', 'Confirm')}
              isLoading={loading}
            />
            {hasCancel && (
              <CoreButton
                className='cancel'
                variation='tertiary'
                size='large'
                onClick={() => {
                  onCancel && onCancel()
                  modalHide()
                }}
                data-test='button-cancel'
                text={cancelCaption ? cancelCaption : tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
                isLoading={loading}
              />
            )}
          </div>
        </CoreModalDialogFooter>
      </div>
    </div>
  )
}
