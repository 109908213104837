import { useState } from 'react'

import { ExpenseManagementReport } from 'mmfintech-commons-types'
import { tr } from 'mmfintech-commons'

import { SubmitReportModal } from '../../../report/modals'
import { CoreButton, CoreModalDialog } from '@components'

import SubmitReportIcon from '@images/expenses/submit-report.svg?react'

interface ISubmitReportButton {
  report: ExpenseManagementReport
}

export const SubmitReportButton: React.FC<ISubmitReportButton> = ({ report }) => {
  const [isSubmitModalVisible, setIsSubmitModalModalVisible] = useState<boolean>(false)

  const handleSubmitReportModalClose = () => setIsSubmitModalModalVisible(false)
  const handleSubmitReportModalOpen = () => setIsSubmitModalModalVisible(true)

  return (
    <>
      <CoreButton
        text={tr('FRONTEND.EXPENSE_MANAGEMENT.SUBMIT_REPORT', 'Submit Report')}
        LeftIcon={<SubmitReportIcon />}
        data-test='button-submit-report-modal'
        onClick={handleSubmitReportModalOpen}
        size='large'
      />
      <CoreModalDialog
        header={tr('FRONTEND.EXPENSE_MANAGEMENT.SUBMIT_REPORT', 'Submit Report')}
        options={{ closeOnEscape: false, closeOnClickOutside: false }}
        visible={isSubmitModalVisible}
        content={<SubmitReportModal report={report} onClose={handleSubmitReportModalClose} />}
        onClose={handleSubmitReportModalClose}
      />
    </>
  )
}
