import { useHistory } from 'react-router-dom'

import { paths } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'

import './noTransactionsMessage.scss'

export const NoTransactionsMessage = ({
  iconLink,
  showDepositMessage = true,
  accountId,
  hasFilters
}: {
  iconLink?: string
  showDepositMessage?: boolean
  accountId?: number
  recipientId?: number
  hasFilters?: boolean
}) => {
  const history = useHistory()
  return (
    <div className='no-transactions-message-wrapper'>
      {iconLink && <img src={iconLink} alt='no-transactions-icon' />}
      <p data-test='no-transactions-title' className='no-transactions-message'>
        {tr('FRONTEND.TRANSACTIONS.NO_TRANSACTIONS_TEXT', 'Your recent transactions will show here!')}
      </p>
      <p data-test='no-transactions-subtitle' className='no-transactions-subtext'>
        <span data-test='no-transactions-context'>
          {hasFilters
            ? tr(
                'FRONTEND.TRANSACTIONS.NO_TRANSACTIONS_SUBTEXT_3',
                'No transactions for the selected period or search criteria'
              )
            : tr('FRONTEND.TRANSACTIONS.NO_TRANSACTIONS_SUBTEXT_1', "You don't have any transactions yet.")}
        </span>
        {showDepositMessage && !hasFilters && (
          <>
            <span data-test='no-transactions-context'>
              {tr('FRONTEND.TRANSACTIONS.NO_TRANSACTIONS_SUBTEXT_2', 'You can start now with your first')}
            </span>
            <span
              onClick={() => history.push(paths.banking.deposit(), { redirectedAccountId: accountId })}
              // to={paths.banking.deposit()}
              data-test='no-transactions-deposit'
              className='no-transactions-deposit-link'>
              {tr('FRONTEND.TRANSACTIONS.NO_TRANSACTIONS_LINK', 'Deposit')}
            </span>
          </>
        )}
      </p>
    </div>
  )
}
