import { useCountries, useCustomerEditQry } from 'mmfintech-backend-api'
import { CountrySupportedPurposeEnum } from 'mmfintech-commons-types'
import { ErrorDisplay, Spinner } from 'mmfintech-portal-commons'
import { tr } from 'mmfintech-commons'

import { CoreButton, CoreInput, CoreModalDialogFooter, CoreSelect, CoreSteps } from '@components'

import './customerEdit.scss'

interface CustomerEditProps {
  payerId?: number
  onClose?: () => void
  onSuccess?: (value: number | string) => void
}

export const CustomerEdit = ({ payerId = 0, onClose, onSuccess }: CustomerEditProps) => {
  const { page, loading, formValues, handleNext, handleBack, handleSubmit, saveCustomerError, saveCustomerFetching } =
    useCustomerEditQry({
      payerId,
      onCreate: payerId => {
        onSuccess && onSuccess(payerId)
        onClose()
      },
      onUpdate: () => {
        onClose()
      },
      required: ['name', 'registrationNumber', 'email', 'phone', 'addressLine1', 'city', 'postalCode', 'countryCode'],
      step1: ['name', 'registrationNumber', 'vatNumber', 'email', 'website']
    })

  const { countryOptions } = useCountries(CountrySupportedPurposeEnum.DEPOSIT)

  return (
    <div className='customer-edit-wrapper' data-test='customer-edit-modal'>
      <div className='title'>
        {page === 1
          ? // TODO: Localizations
            'Payment information'
          : 'Personal information'}
      </div>

      <CoreSteps step={page} maxStep={2} />

      <form className='styled-form' noValidate>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {page === 1 ? (
              <>
                <CoreInput
                  type='text'
                  data-test='company-name'
                  label={tr('FRONTEND.CUSTOMERS.EDIT.COMPANY_NAME', 'Company Name')}
                  {...formValues.registerInput('name')}
                  autoComplete='off'
                />
                <CoreInput
                  type='text'
                  data-test='registration-number'
                  label={tr('FRONTEND.CUSTOMERS.EDIT.REG_NUMBER', 'Registration №')}
                  {...formValues.registerInput('registrationNumber')}
                  autoComplete='off'
                />
                <CoreInput
                  type='text'
                  data-test='vat-number'
                  label={tr('FRONTEND.CUSTOMERS.EDIT.VAT_NUMBER', 'VAT Number')}
                  {...formValues.registerInput('vatNumber')}
                  autoComplete='off'
                />
                <CoreInput
                  type='text'
                  data-test='email'
                  label={tr('FRONTEND.CUSTOMERS.EDIT.COMPANY_EMAIL', 'Company E-mail')}
                  {...formValues.registerInput('email')}
                  autoComplete='off'
                />
                <CoreInput
                  type='text'
                  data-test='website'
                  placeholder='https://'
                  label={tr('FRONTEND.CUSTOMERS.EDIT.WEBSITE', 'Website URL')}
                  {...formValues.registerInput('website')}
                  autoComplete='off'
                />
              </>
            ) : (
              <>
                <CoreInput
                  type='phone'
                  data-test='phone'
                  label={tr('FRONTEND.CUSTOMERS.EDIT.PHONE', 'Phone')}
                  {...formValues.registerInput('phone')}
                />

                <CoreSelect
                  type='country'
                  menuPlacement='top'
                  options={countryOptions}
                  data-test='country-code'
                  label={tr('FRONTEND.CUSTOMERS.EDIT.COUNTRY', 'Country')}
                  {...formValues.registerInput('countryCode')}
                />

                <CoreInput
                  type='text'
                  data-test='address-line'
                  label={tr('FRONTEND.CUSTOMERS.EDIT.REG_ADDRESS', 'Registered Address')}
                  {...formValues.registerInput('addressLine1')}
                  autoComplete='off'
                />

                <div className='buttons-row'>
                  <CoreInput
                    type='text'
                    data-test='city'
                    label={tr('FRONTEND.CUSTOMERS.EDIT.CITY', 'City')}
                    {...formValues.registerInput('city')}
                    autoComplete='off'
                  />

                  <CoreInput
                    type='text'
                    data-test='post-code'
                    label={tr('FRONTEND.CUSTOMERS.EDIT.POSTAL_CODE', 'Postal Code')}
                    {...formValues.registerInput('postalCode')}
                    autoComplete='off'
                  />
                </div>
              </>
            )}

            <ErrorDisplay error={saveCustomerError} />
          </>
        )}
      </form>
      <CoreModalDialogFooter>
        {page === 1 ? (
          <div>
            <CoreButton
              type='button'
              text={tr('FRONTEND.CUSTOMERS.EDIT.BUTTON_NEXT', 'Next')}
              onClick={handleNext}
              size='large'
              data-test='button-next'
              fullWidth
            />
          </div>
        ) : (
          <div className='buttons-container'>
            <CoreButton
              type='button'
              variation='tertiary'
              className='back-btn'
              text={tr('FRONTEND.CUSTOMERS.EDIT.BUTTON_BACK', 'Back')}
              onClick={handleBack}
              size='large'
              disabled={saveCustomerFetching}
              data-test='button-prev'
            />

            <CoreButton
              type='button'
              text={
                payerId > 0
                  ? tr('FRONTEND.CUSTOMERS.EDIT.BUTTON_SAVE', 'Save')
                  : tr('FRONTEND.CUSTOMERS.EDIT.BUTTON_CREATE', 'Create')
              }
              onClick={handleSubmit}
              size='large'
              disabled={saveCustomerFetching}
              isLoading={saveCustomerFetching}
              fullWidth
              data-test='button-save'
            />
          </div>
        )}
      </CoreModalDialogFooter>
    </div>
  )
}
