import { useState } from 'react'

import { useDeleteLogoMutation, useGetLogoQuery, useUpdateLogoMutation } from 'mmfintech-backend-api'
import { checkImageUpload, UploadFileError } from 'mmfintech-commons'
import { ErrorDisplay, FileInput } from 'mmfintech-portal-commons'

import { CoreProfileAvatar, CoreLoader } from '@components'
import settings from '@settings'

import EditImage from '@images/icons/edit-profile-image.svg?react'
import DeleteImage from '@images/icons/delete.svg?react'

import './merchantLogo.scss'

export const MerchantLogo = ({ merchantName }: { merchantName?: string }) => {
  const [logoError, setLogoError] = useState(null)

  const { data: merchantLogo, isFetching: merchantLogoFetching, error: merchantLogoError } = useGetLogoQuery()
  const [updateLogo, { error: updateLogoError }] = useUpdateLogoMutation()
  const [deleteLogo, { error: deleteLogoError }] = useDeleteLogoMutation()

  const convertFileToBase64 = (file: Blob) => {
    const reader = new FileReader()
    return new Promise(resolve => {
      reader.addEventListener('load', () => resolve(String(reader.result)))
      reader.readAsDataURL(file)
    })
  }

  const handleChange = (file: Blob[]) => {
    setLogoError(null)
    convertFileToBase64(file[0]).then(fileData => {
      updateLogo(fileData as string)
    })
  }

  const handleError = (errors: UploadFileError) => setLogoError(checkImageUpload(errors))

  return (
    <>
      <div className='logo-wrapper'>
        {merchantLogoFetching ? (
          <div className='logo-loader'>
            <CoreLoader />
          </div>
        ) : (
          <div className='logo-inner'>
            <FileInput
              maxNumber={1}
              onChange={handleChange}
              onError={handleError}
              maxFileSize={settings.logoMaxFileSize}
              acceptType={settings.logoAcceptType}>
              {({ onFileUpload }) => (
                <div className='logo-button'>
                  <CoreProfileAvatar name={merchantName} image={merchantLogo} />
                  <EditImage className='edit' onClick={onFileUpload} />
                  {merchantLogo && <DeleteImage className='delete' onClick={() => deleteLogo()} />}
                </div>
              )}
            </FileInput>
          </div>
        )}
      </div>

      <ErrorDisplay error={[logoError, merchantLogoError, updateLogoError, deleteLogoError]} className='text-center' />
    </>
  )
}
