import { tr } from 'mmfintech-commons'

import { CoreInput, CoreSelect } from '@components'

export const SpecificField = ({ setup, ...rest }) => {
  const { type, fieldName, fieldNameKey, /*validationRegex,*/ permittedValues } = setup

  const prepareLabel = () => tr(fieldNameKey, fieldNameKey.replace(/[_.]/g, ' '))

  const prepareOptions = () => {
    if (Array.isArray(permittedValues)) {
      return permittedValues.map(({ key, value }) => ({ value, label: tr(key, value) }))
    }
    return []
  }

  switch (type) {
    case 'PHONE_INPUT':
      return (
        <CoreInput
          type='phone'
          className='mb-2'
          data-test={`specific-field-${fieldName}`}
          label={prepareLabel()}
          {...rest}
          autoComplete='off'
        />
      )

    case 'STRING_INPUT':
      return (
        <CoreInput
          type='text'
          className='mb-2'
          data-test={`specific-field-${fieldName}`}
          label={prepareLabel()}
          {...rest}
          autoComplete='off'
        />
      )

    case 'DROPDOWN':
      return (
        <CoreSelect
          type='default'
          className='mb-2'
          data-test={`specific-field-${fieldName}`}
          label={prepareLabel()}
          options={prepareOptions()}
          {...rest}
        />
      )

    default:
      return null
  }
}
