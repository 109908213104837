import { useState } from 'react'

import { tr } from 'mmfintech-commons'

import { ActivitiesSidebar } from '@views/reports/report/modals'
import { ReportActionBadge } from '@views/reports/components'
import { CoreButton, CoreSidebarRight } from '@components'

import ActivitiesIcon from '@images/expenses/report-activities.svg?react'

export const ActivitiesButton = ({ reportId }) => {
  const [showActivities, setShowActivities] = useState<boolean>(false)
  const handleOpenActivities = () => setShowActivities(true)

  return (
    <>
      <CoreButton
        variation='tertiary'
        text={tr('FRONTEND.REPORTS.HISTORY_LABEL.SHOW_HISTORY', 'Show Activity')}
        LeftIcon={<ActivitiesIcon />}
        data-test='button-history'
        onClick={handleOpenActivities}
        size='large'
      />
      <CoreSidebarRight
        header={<ReportActionBadge reportId={reportId} />}
        content={<ActivitiesSidebar reportId={reportId} />}
        visible={showActivities}
        onClose={() => setShowActivities(false)}
        options={{
          size: 'auto',
          closeOnClickOutside: true
        }}
      />
    </>
  )
}
